import React from 'react';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { Chart } from 'react-google-charts';
import styles from './styles.module.scss';

const CountryMap = ({ philosophyStore: store }) => {
  const { countries } = store.philosophy;

  const data = countries
    .filter((item) => item.checked)
    .map((item) => [item.name, parseInt(item.count)]);

  return (
    <div className={styles.wrapper}>
      <Chart
        chartVersion='51'
        width={'450px'}
        height={'260px'}
        chartType='GeoChart'
        data={[['Country', 'Companies'], ...data]}
        options={{
          colorAxis: { colors: ['#009b00', '#00ff00'] },
        }}
        mapsApiKey='AIzaSyB5qzl7NM0x-LwxWxQlSNsEEAQqTgolI6M'
        rootProps={{ 'data-testid': '1' }}
      />
    </div>
  );
};

export default compose(inject('philosophyStore'), observer)(CountryMap);
