import React from 'react';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { Modal, Button } from 'react-bootstrap';
import styles from './styles.module.scss';
import { useHistory } from "react-router-dom";

const MessageDialog = ({ generalStore: store }) => {
  const { title, message, actions = [] } = store.message || {};
  const history = useHistory();

  const redirectToPickPlanPage = () => {
    history.push('/pick-plan');
  };

  return (
    <Modal show={!!store.message} onHide={store.closeMessage}>
      <Modal.Header closeButton>
        {title && <Modal.Title>{title}</Modal.Title>}
      </Modal.Header>
      {message && <Modal.Body>{message}</Modal.Body>}
      {actions.length > 0 && (
        <Modal.Footer>
          {actions.map((item, index) => (
            <Button
              key={index}
              variant={item.variant}
              onClick={async () => {
                store.closeMessage();
                if (item.action) await item.action();
                if (item.redirect) redirectToPickPlanPage();
              }}
              className={styles.button}
            >
              {item.label}
            </Button>
          ))}
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default compose(inject('generalStore'), observer)(MessageDialog);
