import React from 'react';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { Modal, Form, FormCheck, Button } from 'react-bootstrap';
import styles from './styles.module.scss';

const Scuttlebut = ({ show, onCreate, onHide, authStore: store }) => {
  const [alias, setAlias] = React.useState('');
  const [agreed, setAgreed] = React.useState(false);
  const [error, setError] = React.useState('');

  React.useEffect(() => setError(''), [alias]);

  const handleCreateAlias = () => {
    store.setAlias(alias).then(({ apiError, ...data }) => {
      if (apiError) {
        setError(apiError);
      } else {
        onCreate({ alias });
      }
    });
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Body className={styles.aliasModal}>
        <h5>Create An Alias</h5>
        <Form.Group className={styles.alias}>
          <Form.Control
            size='sm'
            type='text'
            value={alias}
            onChange={(e) => setAlias(e.target.value)}
            isInvalid={!!error}
          />

          <Form.Control.Feedback type='invalid'>{error}</Form.Control.Feedback>
        </Form.Group>

        <p className='px-3'>
          If you participate in the chat rooms or later choose to share your
          notes, you will need to create an alias. Please read the terms and
          conditions before proceeding.
        </p>
        <div className={styles.description}>
          <p>
            Market Clue’s Scuttlebutt chat and shared Notes are intended to be
            informative sessions in which participants can find answers, inform,
            or partake in general discussions about a company. We kindly request
            that all participants using our online communication tools follow
            the guidelines laid out on this Chat Code of Conduct. Following
            these guidelines will help us to use Scuttlebutt and Notes as the
            tools they are meant to be.
          </p>
          <ul>
            <li>
              Please be polite and professional. Inappropriate conduct or
              harassment towards chat participants will not be tolerated and the
              session will be immediately terminated.
            </li>
            <li>
              No Vulgar language. Vulgar or abusive language, masked with
              symbols, characters or otherwise, will not be tolerated and the
              session will be immediately terminated.
            </li>
            <li>
              No racist remarks, sexually oriented remarks, or religious
              persecution will be tolerated; any such remarks will trigger the
              immediate termination of the chat session.
            </li>
            <li>
              Actions that seem specifically designed to test the limits of what
              is not offensive and to serve no other purpose will not be
              tolerated and such sessions will be immediately terminated.
            </li>
            <li>
              Any other action that shall, at the sole discretion of Market Clue
              employees, be deemed offensive will not be tolerated and shall
              trigger the immediate termination of the chat session.
            </li>
          </ul>
          <p>
            Market Clue reserves the right to immediately terminate any online
            chat session in which a participant insists on demonstrating
            inappropriate conduct as outlined, including but not limited to, any
            violation to the Code of Conduct above. If a participant is in
            violation of the Code of Conduct a Market Clue employee may warn the
            participant for such violation or may, at the Market Clue employee’s
            sole discretion, immediately terminate the chat session. Other
            actions may also be taken against participants found to be in
            violation of the Code that include, but are not limited to, the
            following:
          </p>
          <ul>
            <li>
              Receiving a written warning via chat and/or email regarding the
              misconduct incident.
            </li>
            <li>Immediate termination of the online chat session.</li>
            <li>
              Perpetual banning from one or any of the following Market Clue
              services or resources:
              <br />
              a) Online Chat.
              <br />
              b) Investment philosophy website. In the event that you prepaid
              the discontinued service, upon your written request you shall be
              reimbursed the pro-rata portion of the unused service.
              <br />
              c) Other services or resources deemed appropriate for banning by
              Market Clue, at its sole discretion. We strive to provide you with
              the best products and services in the industry, including our
              online chat service. We’d like to thank you in advance for
              following the guidelines outlined in this code of conduct when
              using Market Clue Online Chat!
            </li>
          </ul>
          <p>
            Market Clue reserves the right to change or amend the code of
            conduct at any time and without notice. Also, Market Clue reserves
            the right to continue to do or to refuse to do business with anybody
            that does not adhere to the guidelines outlined herein. You are
            encouraged to review these guidelines from time to time to ensure
            your adherence and compliance.
          </p>
        </div>
        <div className={styles.agree}>
          <FormCheck.Label htmlFor='agree'>
            I agree to the terms and conditions
          </FormCheck.Label>
          <FormCheck.Input
            id='agree'
            checked={agreed}
            onChange={(e) => setAgreed(e.target.checked)}
            style={{ marginLeft: '.5rem' }}
          />
        </div>
        <div className={styles.controls}>
          <Button
            variant='primary'
            onClick={handleCreateAlias}
            disabled={!alias.trim() || !agreed}
            className={'mr-2'}
          >
            OK
          </Button>
          <Button variant='secondary' onClick={onHide}>
            Cancel
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default compose(inject('authStore'), observer)(Scuttlebut);
