import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import styles from './styles.module.scss';
import APIService from '@/api';
import { useHistory } from 'react-router-dom';
import { compose } from "recompose";
import { inject, observer } from "mobx-react";

const DialogImportSharedKey = ({ open, handleClose, onImportSharedKey, sharedKeyValue, setSharedKeyValue, sharedKeyValueStatus, sharedKeyHelperText, authStore }) => {
  const history = useHistory();
  const [userAlias, setUserAlias] = React.useState('');
  const [showUserAlias, setShowUserAlias] = React.useState(false);
  const [showUserAliasError, setShowUserAliasError] = React.useState(false);
  const [userAliasErrorText, setUserAliasErrorText] = React.useState('For importing a shared key you need to provide an alias');

  React.useEffect(() => {
    const getAlias = async () => {
      try {
        const alias = await authStore.getAlias();
        if (alias.alias === '' || alias.alias === null) {
          setShowUserAlias(true);
        }
        // Avoid null validation error
        alias.alias = alias.alias ? alias.alias : '';
        setUserAlias(alias.alias);
      } catch (error) {
        console.error(error);
      }
    }

    getAlias();
  }, [authStore]);

  const handleChange = (event) => {
    setSharedKeyValue(event.target.value);
  };

  const handleChangeAlias = (event) => {
    setUserAlias(event.target.value);
  };

  const handleClick = () => {
    if (showUserAlias) {
      handleCreateAlias();
    } else {
      // The alias exists importing the philosophy now
      onImportSharedKey();
    }
  };

  const handleClickUpgrade = () => {
    history.push('/pick-plan');
  };

  const handleCreateAlias = async () => {
    const result = await APIService.setAlias(userAlias);
    if (result.error) {
      setShowUserAliasError(true);
      setUserAliasErrorText(result.error);
    } else {
      setShowUserAlias(false);
      // The alias was created importing the philosophy now
      onImportSharedKey();
    }
  };

  const handleCloseModal = () => {
    if (showUserAlias) {
      setUserAlias('');
    }
    setShowUserAliasError(false);
    setUserAliasErrorText('For importing a shared key you need to provide an alias')
    handleClose();
  };

  const showUpgradeButton = () => {
    return sharedKeyHelperText !== '' && sharedKeyHelperText.indexOf('Please upgrade your account and try again') !== -1;
  };

  return (
    <Dialog open={open} onClose={handleCloseModal} aria-labelledby="form-dialog-title" classes={{ root: styles.root }}>
      <DialogTitle id="form-dialog-title">Importing Shared Key</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Shared Key"
          type="text"
          fullWidth
          value={sharedKeyValue}
          onChange={handleChange}
          error={sharedKeyValueStatus}
          helperText={sharedKeyHelperText}
        />
        {
          showUserAlias &&
          <TextField
            value={userAlias}
            onChange={handleChangeAlias}
            label="Alias"
            type="text"
            fullWidth
            error={showUserAliasError}
            helperText={userAliasErrorText}
          />
        }
      </DialogContent>
      <DialogActions>
        {
          showUpgradeButton() &&
          <Button onClick={handleClickUpgrade} color="primary">
            Upgrade
          </Button>
        }
        <Button onClick={handleCloseModal} color="primary">
          Cancel
        </Button>
        {
          !showUpgradeButton() &&
          <Button onClick={handleClick} color="primary" disabled={!sharedKeyValue || userAlias === ''}>
            Ok
          </Button>
        }
      </DialogActions>
    </Dialog>
  )
}

export default compose(inject('authStore'), observer)(DialogImportSharedKey);
