import React from 'react';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import Table from '@/components/Table';
import styles from './styles.module.scss';

const tableInfo = [
  { key: 'cashAndCashEquivalents', label: 'Cash and Cash Equivalents' },
  { key: 'receivables', label: 'Receivables' },
  { key: 'inventory', label: 'Inventory' },
  { key: 'prepaidExpense', label: 'Prepaid Expense' },
  { key: 'otherCurrentAssets', label: 'Other Current Assets' },
  { key: 'totalCurrentAssets', label: 'Total Current Assets', color: 'darkblue' },
  { key: 'propertyPlantAndEquipment', label: 'Property Plant and Equipment' },
  { key: 'goodwill', label: 'Goodwill' },
  { key: 'intangibleAssets', label: 'Intangible Assets' },
  { key: 'otherLongTermAssets', label: 'Other Long Term Assets' },
  { key: 'totalLongTermAssets', label: 'Total Long Term Assets' },
  { key: 'totalAssets', label: 'Total Assets', color: 'darkblue' },
  { key: 'accountsPayable', label: 'Accounts Payable' },
  { key: 'shortTermDebt', label: 'Short Term Debt' },
  { key: 'accruedLiabilities', label: 'Accrued Liabilities' },
  { key: 'otherCurrentLiabilities', label: 'Other Current Liabilities' },
  { key: 'totalCurrentLiabilities', label: 'Total Current Liabilities', color: 'darkblue' },
  { key: 'longTermDebt', label: 'Long Term Debt' },
  { key: 'deferredLiabilities', label: 'Deferred Liabilities' },
  { key: 'otherLiabilities', label: 'Other Liabilities' },
  { key: 'totalLongTermLiabilities', label: 'Total Long Term Liabilities' },
  { key: 'totalLiabilities', label: 'Total Liabilities', color: 'darkblue' },
  { key: 'preferredStockValue', label: 'Preferred Stock Value' },
  { key: 'commonStockValue', label: 'Common Stock Value' },
  { key: 'treasuryStockValue', label: 'Treasury Stock Value' },
  { key: 'additionalPaidInCapital', label: 'Additional Paid In Capital' },
  { key: 'retainedEarningsAccumulatedDeficit', label: 'Retained Earnings Accumulated Deficit' },
  { key: 'accumulatedOtherComprehensiveIncomeLoss', label: 'Accumulated Other Comprehensive Income Loss' },
  { key: 'otherEquityRelatedItems', label: 'Other Equity Related Items' },
  { key: 'totalStockholdersEquity', label: 'Total Stockholders Equity', color: 'darkblue' },
  { key: 'minorityInterest', label: 'Minority Interest' },
  { key: 'liabilitiesAndStockholdersEquity', label: 'Liabilities And Stockholders Equity' },
];

const BalanceSheet = ({ stockStore }) => {
  const { stock } = stockStore;

  React.useEffect(() => {
    stock.getBSA();
  }, [stock]);

  if (!stock.bsa && !stock.cik) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <Table tableInfo={tableInfo} data={stock.bsa} />
    </div>
  );
};

export default compose(inject('stockStore'), observer)(BalanceSheet);
