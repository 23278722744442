import React from 'react';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { FaRegUserCircle } from 'react-icons/fa';
import { MdHelpOutline } from 'react-icons/md';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import { Button as MaterialButton } from '@material-ui/core';
import APIService from '@/api';
import MessageDialog from '../MessageDialog';
import DialogSupport from '@/components/DialogSupport';
import DialogUpdateFeature from '@/components/DialogUpdateFeature';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import TransparentLogo from "@/images/TransparentLogo.png";
import { getAliasFromEmail } from '@/utils';

import { Container, Navbar, Nav, Modal, Button } from 'react-bootstrap';
import styles from './styles.module.scss';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Header = ({ handleElementClick, authStore }) => {
  const history = useHistory();
  const [visibleSignOutModal, showSignOutModal] = React.useState(false);
  const [openPopper, setOpenPopper] = React.useState(false);
  const [userAlias, setUserAlias] = React.useState('');
  const [openDialogSupport, setOpenDialogSupport] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [messageSnackbar, setMessageSnackbar] = React.useState('');
  const [supportEnabled, setSupportEnabled] = React.useState(0);
  const [openDialogUpgrade, setOpenDialogUpgrade] = React.useState(false);
  const severitySnackbar = 'success';
  const anchorRef = React.useRef(null);
  const knowledgeBaseURL = process.env.REACT_APP_KNOWLEDGEBASE ? `${process.env.REACT_APP_KNOWLEDGEBASE}` : 'https://knowledgebase.marketclue.com/';

  const location = useLocation();

  React.useEffect(() => {
    const getAlias = async () => {
      try {
        const alias = await authStore.getAlias();
        alias.alias = alias.alias ? alias.alias : getAliasFromEmail(authStore.email);
        setUserAlias(alias.alias);
      } catch (error) {
        console.error(error);
        authStore.logout();
        history.push('/login');
      }
    };

    const getSupportEnable = async () => {
      const data = await authStore.rootStore.generalStore.getUserPreference();

      if (data && data[0]) {
        setSupportEnabled(data[0].supportEnabled)
      }
    }
    // Check if the user is authenticated
    if (authStore.isAuthenticated) {
      getAlias();
      getSupportEnable();
    }
  }, [authStore, history]);

  const handleClickLogout = () => {
    if (authStore.rootStore.philosophyStore.checkPhilosphy()) {
      showSignOutModal(true);
    }
  };

  const handleLogout = () => {
    authStore.logout();
    history.push('/login');
  };

  const handleClickSupport = () => {
    // Check if the user has enable support
    if (supportEnabled === 0) {
      setOpenDialogUpgrade(true);
    } else {
      setOpenDialogSupport(true);
    }
  };

  const handleToggle = () => {
    if (!authStore.isAuthenticated) return;
    setOpenPopper((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpenPopper(false);
  };

  const isWelcomePage = () => {
    return location.pathname === '/welcome';
  };

  const handleStripeCustomer = (email) => {
    APIService.createCustomerStripePortalSession(email).then(response => {
      if (response.redirect) {
        window.location = response.response;
      }
    }).catch(error => {
      console.error(error);
    });
  };

  const handleChangePlanClick = () => {
    // Check current planId
    if (authStore.planId > 1) {
      handleStripeCustomer(authStore.email);
      return;
    }

    history.push('/pick-plan', {currentPlan: authStore.planId});
  };

  const handleKnowledgeBaseClick = () => {
    window.open(`${knowledgeBaseURL}`, '_self');
  };

  const handleCloseDialogSupport = () => {
    setOpenDialogSupport(false);
  };

  const handleSentEmail = (response) => {
    setOpenDialogSupport(false);
    setMessageSnackbar(response.message);
    setOpenSnackbar(true);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleCloseDialogUpgrade = () => {
    setOpenDialogUpgrade(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenPopper(false);
    }
  }

  const handleTryForFreeBtn = () => {
    // Handle google conversion support
    window.gtag('event', 'conversion', {'send_to': 'AW-10857170496/VhQYCPr69qoDEMCMjbko'});
  };

  return (
    <Navbar bg='dark' onClick={handleElementClick}>
      <Container style={{ width: '100%', maxWidth: '100%', marginLeft: '30px', marginRight: '30px' }}>
        <Nav className='mr-auto'>
          <Link className={styles.menuItemLogo} to='/app'>
            <img style={{ maxWidth: "15rem", marginTop: "-30px", marginBottom: "-40px" }} src={TransparentLogo} alt="IPS" />
          </Link>
        </Nav>
        <div style={{ marginRight: '100px' }}>
          {
            !authStore.isAuthenticated &&
            <Link className={styles.menuItemLink} style={{ marginRight: '30px' }} to='/login'>
              Login
            </Link>
          }
          {
            isWelcomePage() &&
            <MaterialButton variant="contained" color="primary">
              <Link className={styles.menuItemLink} to='/pick-plan' onClick={handleTryForFreeBtn}>
                Try for Free
              </Link>
            </MaterialButton>
          }
        </div>
        <Nav style={{ alignItems: 'center', minWidth: '77px' }}>
          {
            authStore.isAuthenticated &&
            <>
              <Link className={styles.menuItemIcon} to='/template'>
                <MdHelpOutline size={29} className={styles.navIcon} />
              </Link>
              <div>
                <IconButton
                    component="span"
                    ref={anchorRef}
                    aria-controls={openPopper ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                >
                  <FaRegUserCircle className={styles.navIcon} />
                </IconButton>
                <Popper className={styles.popper} open={openPopper} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                  {({ TransitionProps, placement }) => (
                      <Grow
                          {...TransitionProps}
                          style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleClose}>
                            <MenuList autoFocusItem={openPopper} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                <FaRegUserCircle size={29} className={styles.navIcon} />
                                <MenuItem style={{ cursor: 'default' }}>{userAlias}</MenuItem>
                              </div>
                              <Divider style={{ backgroundColor: 'white' }} />
                              <Link className={styles.menuItemLink} to='/my-account'>
                                <MenuItem>Preferences</MenuItem>
                              </Link>
                              <div className={styles.menuItemLink}>
                                <MenuItem onClick={handleChangePlanClick}>Change Plan</MenuItem>
                              </div>
                              <div className={styles.menuItemLink}>
                                <MenuItem onClick={handleKnowledgeBaseClick}>KnowledgeBase</MenuItem>
                              </div>
                              <MenuItem onClick={handleClickSupport}>Support</MenuItem>
                              <MenuItem onClick={handleClickLogout}>Sign Out</MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                  )}
                </Popper>
              </div>
            </>
          }
        </Nav>
      </Container>

      <Modal show={visibleSignOutModal} onHide={() => showSignOutModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Sign Out?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to sign out?</Modal.Body>
        <Modal.Footer>
          <Button variant='danger' onClick={handleLogout}>
            Sign Out
          </Button>
          <Button variant='secondary' onClick={() => showSignOutModal(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <MessageDialog />

      <DialogSupport open={openDialogSupport} handleClose={handleCloseDialogSupport} email={authStore.email} handleSentEmail={handleSentEmail} />
      <DialogUpdateFeature
          sharedKeyEnabledValue={supportEnabled}
          openDialogUpgrade={openDialogUpgrade}
          handleCloseDialogUpgrade={handleCloseDialogUpgrade}
        />

      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={severitySnackbar}>
          {messageSnackbar}
        </Alert>
      </Snackbar>
    </Navbar>
  );
};

export default compose(inject('authStore'), observer)(Header);
