import axios from 'axios';

class _APIService {
  constructor() {
    this.instance = axios.create();
    this.instance.defaults.baseURL = window.location.href.includes('#alt') ? 'http://localhost:5001' : process.env.REACT_APP_API_URL;
    this.instance.defaults.headers.post['Content-Type'] = 'application/json';
  }

  setToken(token) {
    this.instance.defaults.headers['Authorization'] = token
      ? `Bearer ${token}`
      : '';
  }

  async getApiVersion() {
    try {
      const response = await this.instance.get('/version');
      return response.data;
    } catch (error) {
      console.error(
        'error getting version - ',
        error.response.data.message
      );
      throw new Error(error.response.data.message);
    }
  }

  async signInWithEmail(email, password) {
    try {
      const response = await this.instance.post('/login', {
        email,
        password,
      });
      return response.data;
    } catch (error) {
      console.log('sign in with email error - ', error);
      throw this.getErrorMessage(error);
    }
  }

  async registerUser(email, password, planSubscriptionId) {
    try {
      const response = await this.instance.post('/signup', {
        email,
        password,
        planSubscriptionId,
      });
      return response.data;
    } catch (error) {
      console.log('register error - ', error);
      throw this.getErrorMessage(error);
    }
  }

  async getPhilosophyPrivileges() {
    try {
      const response = await this.instance.get('/philosophy/privileges');
      return response.data;
    } catch (error) {
      console.log('get philosophy privilege error - ', error);
      return [];
    }
  }

  async getPhilosophyCriteriaCombinations() {
    try {
      const response = await this.instance.get(
        '/philosophy/criteria-combinations'
      );
      return response.data;
    } catch (error) {
      console.log('get philosophy criteria-combinations error - ', error);
      return [];
    }
  }

  async getPhilosophies() {
    try {
      const response = await this.instance.get('/philosophy');
      return response.data;
    } catch (error) {
      console.log('get philosophy error - ', error);
      return [];
    }
  }

  async createPhilosophy() {
    try {
      const response = await this.instance.post('/philosophy');
      return response.data;
    } catch (error) {
      console.log('create philosophy error - ', error);
      return null;
    }
  }

  async savePhilosophy(data) {
    try {
      const response = await this.instance.put('/philosophy', { data });
      return response.data;
    } catch (error) {
      console.log('save philosophy error - ', error);
      throw new Error(this.getErrorMessage(error));
    }
  }

  async removePhilosophy(id) {
    try {
      const response = await this.instance.delete('/philosophy', {
        params: { id },
      });
      return response.data;
    } catch (error) {
      console.log('delete philosophy error - ', error);
      return this.getErrorMessage(error);
    }
  }

  async importSharedPhilosophyKey(philosophyKey) {
    try {
      const response = await this.instance.post('/philosophyImport', { philosophyKey });
      return response.data;
    } catch (error) {
      console.log('create philosophy error - ', error);
      throw this.getErrorMessage(error);
    }
  }

  async clonePhilosophy(philosophyKey, keepOriginal) {
    try {
      const response = await this.instance.post('/philosophyClone', { philosophyKey, keepOriginal });
      return response.data;
    } catch (error) {
      console.log('clone philosophy error - ', error);
      return [];
    }
  }

  async kickUserFromPhilosophy(data) {
    try {
      const response = await this.instance.post('/philosophyKickUser', { data });
      return response.data;
    } catch (error) {
      console.log('kick user philosophy error - ', error);
      return [];
    }
  }

  getErrorMessage(error) {
    if (error.response && error.response.data && error.response.data.error && error.response.data.error.message) {
      return error.response.data.error.message;
    }
    return 'Unknow Error';
  }

  async getStocks(philosophyId, sortOrder = 'DESC', financialRatioId = null) {
    try {
      const response = await this.instance.get('/stocks', {
        params: { philosophyId, sortOrder, financialRatioId },
      });
      return response.data;
    } catch (error) {
      console.log('get stocks error - ', error);
      return [];
    }
  }

  async getStock(philosophyId, cik, stockIndex) {
    try {
      const response = await this.instance.get('/stock', {
        params: { philosophyId, cik, stockIndex },
      });
      return response.data;
    } catch (error) {
      console.log('get stock error - ', error);
      return [];
    }
  }

  async getStockTradeData(cik, stockIndex, endDate, format = 'byDay') {
    try {
      const response = await this.instance.get('/stock-trade-data', {
        params: { cik, stockIndex, endDate, format },
      });
      return response.data;
    } catch (error) {
      console.log('get stocks trade data error - ', error);
      return [];
    }
  }

  async getCompanyStockProfile(cik, stockIndex) {
    try {
      const response = await this.instance.get('/company-stock-profile', {
        params: { cik, stockIndex },
      });
      return response.data;
    } catch (error) {
      console.log('get company profile error - ', error);
      return [];
    }
  }

  async getCompanyStockISA(cik, stockIndex) {
    try {
      const response = await this.instance.get('/company-stock-isa', {
        params: { cik, stockIndex },
      });
      return response.data;
    } catch (error) {
      console.log('get company ISA error - ', error);
      return [];
    }
  }

  async getCompanyStockBSA(cik, stockIndex) {
    try {
      const response = await this.instance.get('/company-stock-bsa', {
        params: { cik, stockIndex },
      });
      return response.data;
    } catch (error) {
      console.log('get company BSA error - ', error);
      return [];
    }
  }

  async getCompanyStockCFA(cik, stockIndex) {
    try {
      const response = await this.instance.get('/company-stock-cfa', {
        params: { cik, stockIndex },
      });
      return response.data;
    } catch (error) {
      console.log('get company CFA error - ', error);
      return [];
    }
  }

  async getCompanySECFiling(cik) {
    try {
      const response = await this.instance.get('/company-sec-filing', {
        params: { cik },
      });
      return response.data;
    } catch (error) {
      console.log('get company SEC filing error - ', error);
      return [];
    }
  }

  handleErrorMessageCode = (error) => {
    if (error && error.message && typeof error.message === 'string' && error.message.indexOf('401') !== -1) {
      throw new Error('Unauthorized');
    }
  }

  async getAlias() {
    try {
      const { data } = await this.instance.get('/alias');
      return data;
    } catch (error) {
      console.log('get userinfo error - ', error);
      this.handleErrorMessageCode(error);
      return {};
    }
  }

  async setAlias(alias) {
    try {
      const response = await this.instance.put('/alias', { alias });
      return response.data;
    } catch (error) {
      console.log('save alias error - ', error);
      return null;
    }
  }

  async getScuttlebut(cik) {
    try {
      const response = await this.instance.get('/scuttlebut', {
        params: { cik },
      });
      return response.data;
    } catch (error) {
      console.log('gegt scuttlebut error - ', error);
      return null;
    }
  }

  async addScuttlebut(cik, message) {
    try {
      const response = await this.instance.post('/scuttlebut', {
        cik,
        message,
      });
      return response.data;
    } catch (error) {
      console.log('add scuttlebut error - ', error);
      return null;
    }
  }

  async removeScuttlebut(id) {
    try {
      const response = await this.instance.delete('/scuttlebut', {
        params: { id },
      });
      return response.data;
    } catch (error) {
      console.log('delete scuttlebut error - ', error);
      return null;
    }
  }

  async getNotes(philosophyKey, cik) {
    try {
      const response = await this.instance.get('/note', {
        params: { philosophyKey, cik },
      });
      return response.data;
    } catch (error) {
      console.log('gegt notes error - ', error);
      return null;
    }
  }

  async addNote(cik, philosophyKey, message, shareable) {
    try {
      const response = await this.instance.post('/note', {
        cik,
        philosophyKey,
        message,
        shareable,
      });
      return response.data;
    } catch (error) {
      console.log('add note error - ', error);
      return null;
    }
  }

  async removeNote(id) {
    try {
      const response = await this.instance.delete('/note', {
        params: { id },
      });
      return response.data;
    } catch (error) {
      console.log('delete note error - ', error);
      return null;
    }
  }

  async getExcludedTickers(philosophyId) {
    try {
      const response = await this.instance.get('/excludedtickers', {
        params: { philosophyId },
      });
      return response.data;
    } catch (error) {
      console.log('get excludedtickers error - ', error);
      return null;
    }
  }

  async addExcludedTicker(philosophyId, addedItems) {
    try {
      const response = await this.instance.put('/excludedtickers', {
        philosophyId,
        addedItems
      });
      return response.data;
    } catch (error) {
      console.log('add excluded ticker error - ', error);
      return null;
    }
  }

  async getSectors(philosophyId) {
    try {
      const response = await this.instance.get('/sector', {
        params: { philosophyId },
      });
      return response.data;
    } catch (error) {
      console.log('get sectors error - ', error);
      return null;
    }
  }

  async upateSectors(philosophyId, addedItems, removedItems) {
    try {
      const response = await this.instance.put('/sector', {
        philosophyId,
        addedItems,
        removedItems,
      });
      return response.data;
    } catch (error) {
      console.log('update sectors error - ', error);
      return null;
    }
  }

  async getIndustries(philosophyId) {
    try {
      const response = await this.instance.get('/industry', {
        params: { philosophyId },
      });
      return response.data;
    } catch (error) {
      console.log('get industries error - ', error);
      return null;
    }
  }

  async upateIndustries(philosophyId, addedItems, removedItems) {
    try {
      const response = await this.instance.put('/industry', {
        philosophyId,
        addedItems,
        removedItems,
      });
      return response.data;
    } catch (error) {
      console.log('update industry error - ', error);
      return null;
    }
  }

  async getCountries(philosophyId) {
    try {
      const response = await this.instance.get('/country', {
        params: { philosophyId },
      });
      return response.data;
    } catch (error) {
      console.log('get countries error - ', error);
      return null;
    }
  }

  async upateCountries(philosophyId, addedItems, removedItems) {
    try {
      const response = await this.instance.put('/country', {
        philosophyId,
        addedItems,
        removedItems,
      });
      return response.data;
    } catch (error) {
      console.log('update countries error - ', error);
      return null;
    }
  }

  /**
   * This method should get the user or app lang from the configuration
   * @param {*} lang
   * @returns
   */
  async getTooltipsData(lang = 'en') {
    try {
      const response = await this.instance.get('/tooltip', {
        params: { lang },
      });
      return response.data;
    } catch (error) {
      console.log('get TooltipsData error - ', error);
      return null;
    }
  }

  async getTooltipsPreferenceConfig() {
    try {
      const { data } = await this.instance.get('/tooltipPreference');
      return data;
    } catch (error) {
      console.log('get userinfo error - ', error);
      return {};
    }
  }

  async setTooltipsPreferenceConfig(toolTipEnabled) {
    try {
      const { data } = await this.instance.put('/tooltipPreference', { toolTipEnabled });
      return data;
    } catch (error) {
      console.log('put tooltipPreference error - ', error);
      return {};
    }
  }

  async getUserPreferenceConfig() {
    try {
      const { data } = await this.instance.get('/usercontrolinfo');
      return data;
    } catch (error) {
      console.log('get userinfo error - ', error);
      return {};
    }
  }

  async getPhilosophyCompanyStocks(philosophyId, dialogAdvancedSearchData) {
    let params = { philosophyId };
    const keys = Object.keys(dialogAdvancedSearchData);
    for (const key of keys) {
      if (dialogAdvancedSearchData[key] && dialogAdvancedSearchData[key] !== '') {
        params[`${key}Search`] = dialogAdvancedSearchData[key];
      }
    }
    try {
      const { data } = await this.instance.get('/philosophy-company-stock', {
        params: params,
      });
      return data;
    } catch (error) {
      console.log('get userinfo error - ', error);
      return {};
    }
  }

  async getUserCurrentCriteriaBalance() {
    try {
      const response = await this.instance.get('/philosophy/userCriteriaBalance');
      return response.data;
    } catch (error) {
      console.log('get User Criteria Balance error - ', error);
      return [];
    }
  }

  async reactivateUserAccount(email, planSubscriptionId) {
    try {
      const response = await this.instance.post('/reactivateUserAccount', {
        email,
        planSubscriptionId,
      });
      return response.data;
    } catch (error) {
      console.log('reactivateUserAccount - ', error);
      throw this.getErrorMessage(error);
    }
  }

  async createCustomerStripePortalSession(email) {
    try {
      const response = await this.instance.post('/createCustomerStripePortalSession', {
        email,
      });
      return response.data;
    } catch (error) {
      console.log('createCustomerStripePortalSession - ', error);
      throw this.getErrorMessage(error);
    }
  }

  async getFamousInvestorsPhilosophies() {
    try {
      const response = await this.instance.get('/philosophyFamousInvestors');
      return response.data;
    } catch (error) {
      console.log('get philosophyFamousInvestors error - ', error);
      return [];
    }
  }

  async getPhilosophiesPredefined() {
    try {
      const response = await this.instance.get('/philosophyPredefined');
      return response.data;
    } catch (error) {
      console.log('get philosophyPredefined error - ', error);
      return [];
    }
  }

  async forgotPassword(email) {
    try {
      const response = await this.instance.post('/forgot-password', {
        email
      });
      return response.data;
    } catch (error) {
      console.log('forgot-password - ', error);
      throw this.getErrorMessage(error);
    }
  }

  async changePassword(email, password, token = '') {
    try {
      const response = await this.instance.post('/change-password', {
        email,
        password,
        token,
      });
      return response.data;
    } catch (error) {
      console.log('change-password - ', error);
      throw this.getErrorMessage(error);
    }
  }

  async contactUs(email, subject, body) {
    try {
      const response = await this.instance.post('/contact-us', {
        email,
        subject,
        body,
      });
      return response.data;
    } catch (error) {
      console.log('contact-us - ', error);
      throw this.getErrorMessage(error);
    }
  }

  async getPhilosophyCriteriaCombinationPredefined(financialRatioIdValue, formulaTypeIdValue, numberOfYearsValue, noDataOptionIdValue) {
    try {
      const response = await this.instance.get('/philosophyCriteriaCombinationPredefined', {
        params: { financialRatioIdValue, formulaTypeIdValue, numberOfYearsValue, noDataOptionIdValue },
      });
      return response.data;
    } catch (error) {
      console.log('get philosophyCriteriaCombinationPredefined error - ', error);
      throw this.getErrorMessage(error);
    }
  }
}

const APIService = new _APIService();
export default APIService;
