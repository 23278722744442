import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";

import './styles.scss';
import { MenuItem, TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import { HiMail } from 'react-icons/hi';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { CgPassword } from 'react-icons/cg';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ReCAPTCHA from 'react-google-recaptcha';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import RegisterLogo from "@/images/RegisterLogo.png";
import SSLSecureConnection from "@/images/SSLSecureConnection.png";

import DialogFooterElement from "@/components/DialogFooterElement";
import DialogFooterContentTerms from "@/components/DialogFooterContentTerms";
import DialogFooterContentPrivacy from "@/components/DialogFooterContentPrivacy";

import APIService from '@/api';
import { EMAIL_REGEX, PASSWORD_REGEX, RECAPTCHA_KEY } from "@/utils";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Registration = ({ location }) => {
  const history = useHistory();
  const [planSubscriptionId, setPlanSubscriptionId] = useState(1);
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [openTooltip, setOpenTooltip] = useState(false);
  const [elementFocused, setElementFocused] = useState({ email: false, password: false, passwordConfirm: false });
  const [checkedCaptcha, setCheckedCaptcha] = useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [messageSnackbar, setMessageSnackbar] = React.useState('');
  const registrationText = 'Sign up';
  const [openDialogFooter, setOpenDialogFooter] = useState(false);
  const [dialogText, setDialogText] = useState('');
  const [dialogContentText, setDialogContentText] = useState(null);

  useEffect(() => {
    const getPlanSubscriptionId = () => {
      const { state } = location;
      if (state && state.planSubscriptionId !== '') {
        setPlanSubscriptionId(state.planSubscriptionId);
      }
    };

    getPlanSubscriptionId();
  }, [location]);

  const handleRegister = async (event) => {
    event.preventDefault();
    // Handle google conversion support
    window.gtag('event', 'conversion', {'send_to': 'AW-10857170496/0-JwCKKX96oDEMCMjbko'});
    APIService.registerUser(email, password, planSubscriptionId).then((response) => {
      if (response.redirect) {
        window.location = response.response;
      } else {
        // Check if the session to be passed to login page was returned
        if (response.sessionId) {
          history.push(`/login?session_id=${response.sessionId}`);
        } else {
          // So far redirect the user to login page
          history.push("/login");
        }
      }
    }).catch((errorRegister) => {
      setOpenSnackbar(true);
      setMessageSnackbar(errorRegister);
    });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const plans = [
    {
      value: 1,
      label: 'Basic',
      price: 'FREE',
      billingCycle: '',
    },
    {
      value: 2,
      label: 'Professional',
      price: '$239.88',
      billingCycle: '1 year subscription',
    },
    {
      value: 3,
      label: 'Premium',
      price: '$479.88',
      billingCycle: '1 year subscription',
    },
  ];

  const handlePlanChange = (event) => {
    setPlanSubscriptionId(event.target.value);
  };

  const showCaptcha = () => {
    return !registerDisabled();
  };

  const registerDisabled = () => {
    return !isEmailValid(email) || passwordConfirm === '' || password !== passwordConfirm;
  };

  const getRegisterBtnClassName = () => {
    return !checkedCaptcha ? "register-btn register-btn-disabled" : "register-btn register-btn-enabled";
  };

  const getPasswordTooltipText = () => {
    return (
      <div>
        <span style={{ fontSize: '14px' }}>Characteristics of strong password</span>
        <p style={{ fontSize: '11px', marginTop: '10px' }}>
          <span>
            • At least 8 characters - the more characters the better.
          </span>
          <br />
          <span>
            • A mixture of both uppercase and lowercase letters.
          </span>
          <br />
          <span>
            • A mixture of letters and numbers.
          </span>
          <br />
          <span>
            • Inclusion of at least one special character, e.g. ,!@#?]
          </span>
        </p>
        <p style={{ fontSize: '11px', marginTop: '10px' }}>
          <b>Note:</b> {'do not use < or > in your password, as both can cause problems in Web browsers'}
        </p>
      </div>
    );
  };

  const setOpenTooltipTrigger = () => {
    if (password === '') {
      setOpenTooltip(true);
    } else {
      setOpenTooltip(false);
    }
  };

  const isEmailValid = (email) => {
    return EMAIL_REGEX.test(email);
  };

  const isPasswordValid = (passwd) => {
    return PASSWORD_REGEX.test(passwd);
  };

  const handlePasswordChange = (event) => {
    const passwd = event.target.value;
    setPassword(passwd);

    if (isPasswordValid(passwd)) {
      setOpenTooltip(false);
    } else {
      setOpenTooltip(true);
    }
  };

  const validatePasswordConfirmationInput = () => {
    if (password !== '' && passwordConfirm !== '' && password !== passwordConfirm) {
      return true;
    }
    return elementFocused.passwordConfirm && passwordConfirm === '';
  };

  const validateInput = (inputType) => {
    switch (inputType) {
      case 'email':
        return elementFocused.email && !isEmailValid(email);
      case 'password':
        return elementFocused.password && !isPasswordValid(password);
      case 'passwordConfirm':
        return elementFocused.passwordConfirm && passwordConfirm === '';
      default:
        return false;
    }
  };

  const handleOnBlur = (inputType) => {
    if (inputType === 'password') {
      setOpenTooltip(false);
    }
    const elementFocusedTemp = elementFocused;
    elementFocusedTemp[`${inputType}`] = true;
    setElementFocused(elementFocusedTemp);
  };

  const comparePasswords = () => {
    if (password !== '' && passwordConfirm !== '' && password !== passwordConfirm) {
      return "Password and Password Confirmation must be the same"
    }
    return "";
  };

  const getPasswordConfirmationHelperText = () => {
    return validateInput('passwordConfirm') ? "Password Confirmation is required" : comparePasswords();
  };

  const onCaptchaChange = (value) => {
    if (value) {
      setCheckedCaptcha(true);
    } else {
      setCheckedCaptcha(false);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const getPlanLabel = (plan) => {
    const planWithBillingCycle = <div>{plan.label} {`(${plan.price}`} <small>{`${plan.billingCycle}`}</small>{`)`}</div>;
    const planWithoutBillingCycle = <div>{plan.label} {`(${plan.price})`}</div>;
    return plan.billingCycle !== '' ? planWithBillingCycle : planWithoutBillingCycle;
  };

  const onElementClick = (element) => {
    if (element === 'terms') {
      setDialogText('Terms and Conditions of Use');
      setDialogContentText(<DialogFooterContentTerms />);
    } else if (element === 'privacy') {
      setDialogText('Privacy Policy');
      setDialogContentText(<DialogFooterContentPrivacy />);
    }
    setOpenDialogFooter(true);
  };

  return (
    <div className="register-container">
      <div className="container">
        <div className="div-img-container">
          <img className="logo-img" src={RegisterLogo} alt="MarketClue" />
        </div>
        <h2>{registrationText}</h2>
        <div className="div-img-container">
          <h6 className="sign-disclaimer">
            By signing up, you agree to our <label className="sign-disclaimer-link" onClick={() => onElementClick('terms')}>Terms</label> &amp; <label className="sign-disclaimer-link" onClick={() => onElementClick('privacy')}>Privacy Policy</label>
          </h6>
        </div>
        <form className="register-form" onSubmit={handleRegister}>
          <TextField
            className="input-field"
            required
            label="Email"
            placeholder="Email"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            InputLabelProps={{ required: false }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <HiMail />
                </InputAdornment>
              ),
            }}
            error={validateInput('email')}
            helperText={validateInput('email') ? "Email is required and should match the requirements" : ""}
            onBlur={() => handleOnBlur('email')}
          />
          <Tooltip title={getPasswordTooltipText()} placement="right" arrow open={openTooltip}>
            <TextField
              className="input-field"
              required
              type={showPassword ? 'text' : 'password'}
              label="Password"
              variant="outlined"
              value={password}
              onChange={handlePasswordChange}
              InputLabelProps={{ required: false }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <MdVisibility /> : <MdVisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              onClick={setOpenTooltipTrigger}
              onBlur={() => handleOnBlur('password')}
              error={validateInput('password')}
              helperText={validateInput('password') ? "Password is required and should match the requirements" : ""}
            />
          </Tooltip>
          <TextField
            className="input-field"
            required
            type="password"
            label="Confirm Password"
            variant="outlined"
            value={passwordConfirm}
            onChange={(e) => setPasswordConfirm(e.target.value)}
            InputLabelProps={{ required: false }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <CgPassword />
                </InputAdornment>
              ),
            }}
            error={validatePasswordConfirmationInput()}
            helperText={getPasswordConfirmationHelperText()}
            onBlur={() => handleOnBlur('passwordConfirm')}
          />
          <TextField
            className="input-field"
            required
            select
            label="Account type"
            variant="outlined"
            value={planSubscriptionId}
            onChange={handlePlanChange}
            InputLabelProps={{ required: false }}
          >
            {plans.map((plan) => (
              <MenuItem key={plan.value} value={plan.value}>
                {getPlanLabel(plan)}
              </MenuItem>
            ))}
          </TextField>

          {
            showCaptcha() && <ReCAPTCHA className="input-field-captcha" sitekey={RECAPTCHA_KEY} onChange={onCaptchaChange} />
          }

          <button className={getRegisterBtnClassName()} type="submit" disabled={!checkedCaptcha}>
            Register
          </button>
        </form>
        <div className="div-ssl-img-container">
          <img className="ssl-img" src={SSLSecureConnection} alt="SSL Protected" />
        </div>
        <div className="div-img-container">
          <h6 className="sign-disclaimer">
            This site is protected by reCAPTCHA.
          </h6>
        </div>
      </div>

      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity='error'>
          {messageSnackbar}
        </Alert>
      </Snackbar>

      <DialogFooterElement open={openDialogFooter} handleClose={setOpenDialogFooter} dialogText={dialogText} dialogContentText={dialogContentText} />
    </div>
  )
}

export default Registration;
