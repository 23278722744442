import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

const DialogFooterElement = ({ open, handleClose, dialogText, dialogContentText }) => {
  const handleCloseDialog = () => {
    handleClose(false);
  };

  return (
    <Dialog
      open={open}
      scroll={'paper'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">{dialogText}</DialogTitle>
      <DialogContent>
      <span style={{ color: 'black' }}>
        {dialogContentText}
      </span>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogFooterElement;
