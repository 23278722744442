import React from 'react';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { checkSharedKeyEnabledValue } from '@/utils';

import Panel from '../Panel';

const SectorPanel = ({ philosophyStore: store }) => {
  const { sectors, changeSectors } = store.philosophy;
  const [sectorScreeningEnabled, setSectorScreeningEnabled] = React.useState(0);
  const [openDialogUpgrade, setOpenDialogUpgrade] = React.useState(false);

  React.useEffect(() => {
    const { userPreferences } = store.rootStore.generalStore;
    // Check if the user has granted sector Screening
    if (userPreferences && userPreferences[0]) {
      setSectorScreeningEnabled(userPreferences[0].sectorScreeningEnabled);
      if (!checkSharedKeyEnabledValue(userPreferences[0].sectorScreeningEnabled)) {
        setOpenDialogUpgrade(true);
      }
    }
  }, [store.rootStore.generalStore]);

  return (
    <Panel title='Sector' data={sectors} onChange={changeSectors} openDialogUpgrade={openDialogUpgrade} screeningEnabled={sectorScreeningEnabled} />
  );
};

export default compose(inject('philosophyStore'), observer)(SectorPanel);
