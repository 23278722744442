import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

const DialogNonOwnerCloningPhilosophy = ({ open, handleClose, keepOriginalClone }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <span style={{ color: 'black' }}>
            You are creating a cloned copy.  Would you like to keep the original?
          </span>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={() => keepOriginalClone(false)} color="primary">
          No
        </Button>
        <Button onClick={() => keepOriginalClone(true)} color="primary">
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogNonOwnerCloningPhilosophy;
