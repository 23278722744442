import React from 'react';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { BiSend } from 'react-icons/bi';
import styles from './styles.module.scss';
import CreateAliasModal from '../CreateAliasModal';
import DialogUpdateFeature from '@/components/DialogUpdateFeature';
import { checkSharedKeyEnabledValue } from '@/utils';

const Input = ({ authStore, stock }) => {
  const inputRef = React.useRef();
  const [msg, setMsg] = React.useState('');
  const [visibleAliasModal, showAliasModal] = React.useState(false);
  const [userAlias, setUserAlias] = React.useState('');
  const [sharedKeyEnabledValue, setSharedKeyEnabledValue] = React.useState(0);
  const [openDialogUpgrade, setOpenDialogUpgrade] = React.useState(false);

  React.useEffect(() => {
    const getAlias = async () => {
      try {
        const alias = await authStore.getAlias();
        // Avoid null validation error
        alias.alias = alias.alias ? alias.alias : '';
        setUserAlias(alias);
      } catch (error) {
        console.error(error);
      }
    }

    getAlias();
  }, [authStore]);

  React.useEffect(() => {
    inputRef.current.style.height = 0;
    inputRef.current.style.height = inputRef.current.scrollHeight + 2 + 'px';
  }, [msg]);

  const getAlias = (alias) => {
    switch (typeof alias) {
      case "object":
        return alias.alias;
      case "string":
      default:
        return alias;
    }
  };

  const checkUserConfigurationEnabled = () => {
    const { userPreferences } = authStore.rootStore.generalStore;
    // Check if the user has granted importing shared keys
    if (userPreferences && userPreferences[0]) {
      setSharedKeyEnabledValue(userPreferences[0].scuttlebuttEnabled);
      if (checkSharedKeyEnabledValue(userPreferences[0].scuttlebuttEnabled)) {
        return true;
      } else {
        setOpenDialogUpgrade(true);
        return false;
      }
    }
  };

  const sendMessage = async (createdAlias = {}) => {
    // Check if the user has the privilege to send messages in scuttlebutt
    if (!checkUserConfigurationEnabled()) return;
    let alias;
    if (createdAlias.hasOwnProperty('alias')) {
      alias = createdAlias;
    } else {
      alias = userAlias ? userAlias : await authStore.getAlias();
    }
    setUserAlias(alias);
    if (!alias || alias.alias === '' || !alias.alias) {
      showAliasModal(true);
      return;
    } else {
      showAliasModal(false);
    }

    const str = msg.trim();
    if (str) {
      await stock.addScuttlebut(str, getAlias(alias));
      setMsg('');
    }
  };

  const handleInput = (e) => {
    setMsg(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  };

  const handleCloseDialogUpgrade = () => {
    setOpenDialogUpgrade(false);
  };

  return (
    <>
      <div className={styles.input}>
        <textarea
          ref={inputRef}
          value={msg}
          onKeyDown={handleKeyDown}
          onChange={handleInput}
          disabled={authStore.alias === undefined}
          placeholder={`Chat about ${stock.companyName}`}
        />
        <BiSend
          className={msg.trim() ? '' : styles.disabled}
          onClick={sendMessage}
        />
      </div>
      <CreateAliasModal
        show={visibleAliasModal}
        onHide={() => showAliasModal(false)}
        onCreate={sendMessage}
      />
      <DialogUpdateFeature
        sharedKeyEnabledValue={sharedKeyEnabledValue}
        openDialogUpgrade={openDialogUpgrade}
        handleCloseDialogUpgrade={handleCloseDialogUpgrade}
      />
    </>
  );
};
export default compose(
  inject(({ authStore, stockStore }) => ({
    authStore,
    stock: stockStore.stock,
  })),
  observer
)(Input);
