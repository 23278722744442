import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ReactPlayer from "react-player";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    minWidth: '250px',
    maxWidth: 'fit-content',
    fontSize: theme.typography.pxToRem(10),
  },
}))(Tooltip);

const TooltipItem = ({ label, item, hideTooltips, classNames, styles }) => {
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [playerWidth, setPlayerWidth] = React.useState(19);
  const [playerHeight, setPlayerHeight] = React.useState(11.25);

  const setPlayerSize = (size) => {
    setPlayerWidth(playerWidth + size);
    setPlayerHeight(playerHeight + size);
  };

  const onStartHandle = () => {
    setIsPlaying(true);
    setPlayerSize(35);
  };

  const onEndHandle = () => {
    setPlayerWidth(19);
    setPlayerHeight(11.25);
    setIsPlaying(false);
  };

  const handleOnClose = () => {
    setTimeout(() => {
      setPlayerWidth(19);
      setPlayerHeight(11.25);
      setIsPlaying(false);
    }, 500);
  };

  const getClassNames = () => {
    return classNames ? classNames : '';
  };

  const getLabelStyles = () => {
    return styles ? styles : {};
  };

  return (
    <>
      {
        item && <HtmlTooltip
          title={
            <div style={{ width: '100%' }}>
              {!isPlaying && <Typography color="inherit">{item.description}</Typography>}
              {
                item.videoURL &&
                <ReactPlayer url={item.videoURL} onStart={onStartHandle} onEnded={onEndHandle} controls={true} width={`${playerWidth}rem`} height={`${playerHeight}rem`}/>
              }
              <div style={{minHeight: '15px'}}>
                <span style={{ float: 'right', color: 'white', marginTop: '3px', cursor: 'pointer' }} onClick={hideTooltips}>Turn Off Tooltips</span>
              </div>
            </div>
          }
          interactive
          enterDelay={1000}
          enterNextDelay={1000}
          leaveDelay={500}
          onClose={handleOnClose}
          arrow
        >
          <label className={getClassNames()} style={getLabelStyles()}>{label}</label>
        </HtmlTooltip>
      }
      {
        !item && <label>{label}</label>
      }
    </>
  )
};

export default TooltipItem;
