import React from 'react';
import Typography from '@material-ui/core/Typography';

const DialogFooterContentDisclaimer = () => {
  return (
    <>
      <Typography variant="body2" align='justify' paragraph={true}>The information provided by the Market Clue Corp. application and the marketclue.com website is for information purposes only and should not be solely relied upon for investment decisions. While Market Clue Corp. pays special attention to continually improving the quality and usefulness of estimates and the reliability of the data in its application, users should at all times independently verify all data through publicly-available sources and otherwise do independent research for all investment decisions. The Market Clue Corp. application and the marketclue.com website do not hold themselves out as providing any legal, financial or other advice. They also do not make any recommendation or endorsement as to any investment, advisor or other service or product. In addition, Market Clue Corp. and the marketclue.com website do not offer any advice regarding the nature, potential value or suitability of any particular investment, security or investment strategy. The information provided by the Market Clue Corp. application or the marketclue.com website is meant to be a starting point for you to do independent research and, as such, does not constitute advice. You should not rely on any information provided to make (or refrain from making) any decision or take (or refrain from taking) any action. Neither Market Clue Corp. nor the marketclue.com website make recommendations for buying or selling any securities or options. Likewise, the marketclue.com website offers various forums or opportunities for users to post information, opinions, etc. As such, certain content on the site does not originate from Market Clue Corp. and may or may not be true or useful. Market Clue Corp. does not guarantee the veracity, reliability or completeness of any information provided in our forum or in any hyperlink appearing on our site.</Typography>
    </>
  );
};

export default DialogFooterContentDisclaimer;
