import React from 'react';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import MainPage from '@/containers/MainPage';
import Welcome from '@/containers/Welcome';
import Terms from '@/containers/Terms';
import Privacy from '@/containers/Privacy';
import SignIn from '@/containers/SignIn';
import Registration from '@/containers/Registration';
import PickPlan from '@/containers/PickPlan';
import MyAccount from '@/containers/MyAccount';
import Template from '@/containers/MainPage/Template';
import StripePaymentSuccess from '@/containers/StripePaymentSuccess';
import StripePaymentCancel from '@/containers/StripePaymentCancel';
import ChangePassword from '@/containers/ChangePassword';

const Routes = ({ authStore }) => {
  const PublicRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        authStore.isAuthenticated === false ? (
          <Component {...props} />
        ) : (
          <Redirect to='/app' />
        )
      }
    />
  );

  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        authStore.isAuthenticated === true ? (
          <Component {...props} />
        ) : (
          <Redirect to='/login' />
        )
      }
    />
  );

  return (
    <Router>
      <Switch>
        <Route exact path='/' render={() => <Redirect to='/welcome' />} />
        <PublicRoute path='/login' component={SignIn} />
        <PublicRoute path='/register' component={Registration} />
        <Route path='/change-password' component={ChangePassword} />
        <Route path='/welcome' component={Welcome} />
        <Route path='/terms' component={Terms} />
        <Route path='/privacy' component={Privacy} />
        <Route path='/pick-plan' component={PickPlan} />
        <PrivateRoute path='/template' component={Template} />
        <PrivateRoute path='/my-account' component={MyAccount} />
        <PrivateRoute path='/app' component={MainPage} />
        <Route path='/stripe-payment-success' component={StripePaymentSuccess} />
        <Route path='/stripe-payment-cancel' component={StripePaymentCancel} />
      </Switch>
    </Router>
  );
};

export default compose(inject('authStore'), observer)(Routes);
