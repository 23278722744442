import React from "react";
import { Form } from "react-bootstrap";
import { BsFillCaretUpFill, BsFillCaretDownFill } from "react-icons/bs";
import styles from "./styles.module.scss";

const Navigation = ({ current, total, onChange }) => {
  const handleChange = (e) => {
    const index = Number(e.target.value);
    onChange(index - 1);
  };

  return (
    <div className={styles.navigation}>
      <div className={styles.arrows}>
        <BsFillCaretUpFill onClick={() => onChange(current - 1)} />
        <BsFillCaretDownFill onClick={() => onChange(current + 1)} />
      </div>
      <Form.Control
        className={styles.input}
        value={current + 1}
        onChange={handleChange}
		onFocus={({ target } = {}) => target && target.select && target.select()}
      />
      of {total}
    </div>
  );
};

export default React.memo(Navigation);
