import React, { useState } from 'react';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import TextField from "@material-ui/core/TextField";
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { CgPassword } from 'react-icons/cg';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { useHistory } from "react-router-dom";
import Header from '@/containers/layout/Header';

import "./styles.scss";

import APIService from '@/api';

import { PASSWORD_REGEX } from "@/utils";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ChangePassword = ({ location, authStore }) => {
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [elementFocused, setElementFocused] = useState({ password: false, passwordConfirm: false });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [messageSnackbar, setMessageSnackbar] = useState('');
  const [severitySnackbar, setSeveritySnackbar] = useState('success');

  const handleForgotPassword = (event) => {
    event.preventDefault();

    let email = '';
    let token = '';

    const { search } = location;
    if (search && search !== '') {
      const items = search.split('&');

      const emailArray = items[0].split('=');
      const tokenArray = items[1].split('=');

      email = emailArray[1];
      token = tokenArray[1];
    } else {
      // The user must be authenticated for reaching this route without search url params
      email = authStore.email;
      token = authStore.token;
    }

    APIService.changePassword(email, password, token).then((response) => {
      if (response.success) {
        // Show user message & redirect to login page
        setMessageSnackbar(response.message);
        setOpenSnackbar(true);
        setSeveritySnackbar('success');
      }
    }).catch((error) => {
      setMessageSnackbar(error);
      setOpenSnackbar(true);
      setSeveritySnackbar('error');
    });
  };

  const handlePasswordChange = (event) => {
    const passwd = event.target.value;
    setPassword(passwd);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const isPasswordValid = (passwd) => {
    return PASSWORD_REGEX.test(passwd);
  };

  const validateInput = (inputType) => {
    switch (inputType) {
      case 'password':
        return elementFocused.password && !isPasswordValid(password);
      case 'passwordConfirm':
        return elementFocused.passwordConfirm && passwordConfirm === '';
      default:
        return false;
    }
  };

  const validatePasswordConfirmationInput = () => {
    if (password !== '' && passwordConfirm !== '' && password !== passwordConfirm) {
      return true;
    }
    return elementFocused.passwordConfirm && passwordConfirm === '';
  };

  const comparePasswords = () => {
    if (password !== '' && passwordConfirm !== '' && password !== passwordConfirm) {
      return "Password and Password Confirmation must be the same"
    }
    return "";
  };

  const getPasswordConfirmationHelperText = () => {
    return validateInput('passwordConfirm') ? "Password Confirmation is required" : comparePasswords();
  };

  const handleOnBlur = (inputType) => {
    const elementFocusedTemp = elementFocused;
    elementFocusedTemp[`${inputType}`] = true;
    setElementFocused(elementFocusedTemp);
  };

  const forgotPasswordDisabled = () => {
    return !(passwordConfirm !== '' && password === passwordConfirm);
  };

  const getRegisterBtnClassName = () => {
    return forgotPasswordDisabled() ? "register-btn register-btn-disabled" : "register-btn register-btn-enabled";
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);

    // Try to logout the user for forcing login after
    authStore.logout();

    history.push("/login");
  };

  return (
    <>
      {
        authStore.isAuthenticated && <Header />
      }
      <div className="forgot-password-container">
        <div className="container">
          <h2>Change Password</h2>

          <form className="forgot-password-form" onSubmit={handleForgotPassword}>
            <TextField
              className="input-field"
              required
              type={showPassword ? 'text' : 'password'}
              label="Password"
              variant="outlined"
              value={password}
              onChange={handlePasswordChange}
              InputLabelProps={{ required: false }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? <MdVisibility /> : <MdVisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={validateInput('password')}
              helperText={validateInput('password') ? "Password is required and should match the requirements" : ""}
              onBlur={() => handleOnBlur('password')}
            />
            <TextField
              className="input-field"
              required
              type="password"
              label="Confirm Password"
              variant="outlined"
              value={passwordConfirm}
              onChange={(e) => setPasswordConfirm(e.target.value)}
              InputLabelProps={{ required: false }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CgPassword />
                  </InputAdornment>
                ),
              }}
              error={validatePasswordConfirmationInput()}
              helperText={getPasswordConfirmationHelperText()}
              onBlur={() => handleOnBlur('passwordConfirm')}
            />

            <button className={getRegisterBtnClassName()} type="submit" disabled={forgotPasswordDisabled()}>
              Submit
            </button>
          </form>
        </div>

        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
          <Alert onClose={handleSnackbarClose} severity={severitySnackbar}>
            {messageSnackbar}
          </Alert>
        </Snackbar>
      </div>
    </>
  )
}

export default compose(inject('authStore'), observer)(ChangePassword);
