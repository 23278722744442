import React, { useState } from 'react';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { Container, Tab, Nav } from 'react-bootstrap';
import Header from '@/containers/layout/Header';
import Footer from '@/containers/layout/Footer';
import StockNav from './StockNav';
import Philosophy from './InvestmentPhilosophy';
import Summary from './Summary';
import Company from './Company';
import IncomeStatement from './IncomeStatement';
import BalanceSheet from './BalanceSheet';
import CashFlow from './CashFlow';
import styles from './styles.module.scss';
import InfoNotAvailable from '@/components/InfoNotAvailable';
import DialogAdvancedStockSearch from '@/components/DialogAdvancedStockSearch';
import TooltipItem from '@/components/TooltipItem';

import APIService from '@/api';

const pages = [
  { key: 'investment', title: 'Investment Philosophy', component: Philosophy },
  { key: 'summary', title: 'Summary', component: Summary },
  { key: 'company', title: 'Company', component: Company },
  { key: 'statement', title: 'Income Statement', component: IncomeStatement },
  { key: 'sheet', title: 'Balance Sheet', component: BalanceSheet },
  { key: 'cashflow', title: 'Cash Flow', component: CashFlow },
];

const MainPage = ({ philosophyStore: store }) => {
  const [selectedKey, setSelectTab] = useState(
    store.rootStore.generalStore.loadData('tab', 'investment')
  );

  const [companyTabEnabled, setCompanyTabEnabled] = React.useState(0);
  const [companyQueryingEnabled, setCompanyQueryingEnabled] = React.useState(0);
  const [iSTabEnabled, setISTabEnabled] = React.useState(0);
  const [bSTabEnabled, setBSTabEnabled] = React.useState(0);
  const [cFTabEnabled, setCFTabEnabled] = React.useState(0);
  const [openDialogAdvancedSearch, setOpenDialogAdvancedSearch] = React.useState(false);
  const [dialogAdvancedSearchData, setDialogAdvancedSearchData] = React.useState({
    ticker: '', company: '', sector: '', industry: '', profile: '',
    stockExchange: '', marketCap: '', website: '', address: '', address2: '',
    city: '', state: '', zip: '', country: '', phone: ''
  });
  const [showTooltip, setShowTooltip] = React.useState(false);
  const [tooltips, setTooltips] = React.useState([]);
  const [firstLogin, setFirstLogin] = React.useState(true);
  const [firstLoginStep, setFirstLoginStep] = React.useState(1);

  React.useEffect(() => {
    store.getData();

    const getTooltipData = async () => {
      if (tooltips.length > 0) return;

      let tooltipData = await store.rootStore.generalStore.getTooltipData();
      if (tooltipData && tooltipData[0]) {
        setTooltips(tooltipData);
      }
    };

    // We need to wait until the toolTip Config is loaded
    setTimeout(() => {
      // Check if the user has the showTooltip option enable
      if (store && store.showTooltip && tooltips.length === 0) {
        setShowTooltip(true);
        getTooltipData();
      }
    }, 2000);

    const checkUserPreferences = async () => {
      // Get user preferences configuration
      const data = await store.rootStore.generalStore.getUserPreference();

      if (data && data[0]) {
        setCompanyTabEnabled(data[0].companyTabEnabled);
        setCompanyQueryingEnabled(data[0].companyQueryingEnabled);
        setISTabEnabled(data[0].iSTabEnabled);
        setBSTabEnabled(data[0].bSTabEnabled);
        setCFTabEnabled(data[0].cFTabEnabled);
      }
    };
    checkUserPreferences();

    const checkFirstLogin = () => {
      const firstLogin = localStorage.getItem('firstLogin');
      if (firstLogin != null) setFirstLogin(firstLogin === 'true');
    };
    checkFirstLogin();
  }, [store, tooltips]);

  const handleSelectTab = (key) => {
    if (store.checkPhilosphy()) {
      setSelectTab(key);
      store.rootStore.generalStore.saveData('tab', key);
    }
  };

  const handleFirstLoginStep = (currentStep) => {
    setFirstLoginStep(currentStep);
  };

  const getCompanyCondition = key => key === 'company' && companyTabEnabled !== 1;
  const getStatementCondition = key => key === 'statement' && iSTabEnabled !== 1;
  const getSheetCondition = key => key === 'sheet' && bSTabEnabled !== 1;
  const getCashCondition = key => key === 'cashflow' && cFTabEnabled !== 1;

  const getComponent = (key, Component) => {
    if (getCompanyCondition(key) || getStatementCondition(key) || getSheetCondition(key) || getCashCondition(key)) {
      return <InfoNotAvailable />;
    }
    return <Component firstLogin={firstLogin} firstLoginStep={firstLoginStep} handleFirstLoginStep={handleFirstLoginStep} exitOrientation={exitOrientation} />;
  };

  const getStockNav = (key) => {
    if (getCompanyCondition(key) || getStatementCondition(key) || getSheetCondition(key) || getCashCondition(key)) {
      return <></>;
    }
    return <>
      {/* Only show the search button for company tab  */}
      <StockNav showSearchButton={key === 'company' && companyQueryingEnabled === 1} openAdvancedSearchDialog={openAdvancedSearchDialog} />
      {stocks && stocks.length === 0 && (
        <div className={styles.noStocks}>
          No records match your screening.
          <br />
          Please modify your screening and try again.
        </div>
      )}
    </>;
  };

  const handleClose = () => {
    setOpenDialogAdvancedSearch(false);
  };
  
  const openAdvancedSearchDialog = () => {
    setOpenDialogAdvancedSearch(true);
  };

  const advancedSearch = async () => {
    setOpenDialogAdvancedSearch(false);

    // Get the stocks
    store.rootStore.stockStore.getPhilosophyCompanyStocks(dialogAdvancedSearchData);
  };

  const changeAdvancedSearchData = (data) => {
    setDialogAdvancedSearchData(data);
  };

  const clearAllAdvancedSearchData = () => {
    setDialogAdvancedSearchData({
      ticker: '', company: '', sector: '', industry: '', profile: '',
      stockExchange: '', marketCap: '', website: '', address: '', address2: '',
      city: '', state: '', zip: '', country: '', phone: ''
    });

    // Get the stocks
    store.rootStore.stockStore.getPhilosophyCompanyStocks({});
  };

  const { stocks } = store.rootStore.stockStore;

  const hideTooltips = async () => {
    await APIService.setTooltipsPreferenceConfig(false);
    store.changeTooltip(false);
    setShowTooltip(false);
  };

  const getTooltipItem = (component) => {
    const found = tooltips.filter(item => item.uITopicName === component);
    return found.length > 0 ? found[0] : null;
  };

  const getTabTitle = (tabTitle) => {
    const capitalizedTabTitle = tabTitle.charAt(0).toUpperCase() + tabTitle.slice(1);
    const capitalizedSelectedKey = selectedKey.charAt(0).toUpperCase() + selectedKey.slice(1);

    let tooltipItemName = '';
    let tabTitleToCompare = '';

    switch (tabTitle) {
      case 'Investment Philosophy': {
        tabTitleToCompare = 'Investment';
        tooltipItemName = 'InvestmentPhilosophy.InvestmentPhilosophyTab';
        break;
	    }
      case 'Income Statement': {
        tabTitleToCompare = 'Statement';
        tooltipItemName = 'Statement.StatementTab';
        break;
	    }
      case 'Balance Sheet': {
        tabTitleToCompare = 'Sheet';
        tooltipItemName = 'Sheet.SheetTab';
        break;
	    }
      case 'Cash Flow': {
        tabTitleToCompare = 'Cashflow';
        tooltipItemName = 'Cashflow.CashflowTab';
        break;
	    }
      case 'Company': {
        tabTitleToCompare = 'Company';
        tooltipItemName = 'Company.CompanyTab';
        break;
	    }
      case 'Summary': {
        tabTitleToCompare = 'Summary';
        tooltipItemName = 'Summary.SummaryTab';
        break;
	    }
      default: {
        tooltipItemName = tabTitle;
        tabTitleToCompare = tabTitle;
      }
	  }

    return tabTitleToCompare === capitalizedSelectedKey ? <TooltipItem styles={{ 'cursor': 'help' }} label={capitalizedTabTitle} item={showTooltip ? getTooltipItem(tooltipItemName) : null} hideTooltips={hideTooltips} /> : capitalizedTabTitle;
  };

  const handleElementClick = () => {
    if (firstLoginStep === 1) {
      setTimeout(() => {
        setFirstLoginStep(2);
      }, 500);
    }
  };

  const exitOrientation = () => {
    const firstLoginStorage = localStorage.getItem('firstLogin');
    if (firstLoginStorage === null || firstLoginStorage === 'true') {
      localStorage.setItem('firstLogin', false);
      setFirstLogin(false);
    }
  }

  const handleTabElementClick = (title) => {
    if (title === 'Summary') {
      exitOrientation();
    }
  };

  return (
    <>
      <Header handleElementClick={handleElementClick} />
      <Container fluid className={styles.root} onClick={handleElementClick}>
        <Tab.Container
          defaultActiveKey='investment'
          mountOnEnter
          unmountOnExit
          activeKey={selectedKey}
          onSelect={handleSelectTab}
        >
          <Nav className='nav-tabs'>
            {pages.map(({ key, title }) => (
              <Nav.Item key={key}>
                <Nav.Link eventKey={key} onClick={() => handleTabElementClick(title)}>{getTabTitle(title)}</Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
          <div className={styles.content}>
            <Tab.Content>
              {pages.map(({ key, component: Component }) => (
                <Tab.Pane key={key} eventKey={key} className='pb-4'>
                  {selectedKey !== 'investment' && getStockNav(key)}
                  {getComponent(key, Component)}
                </Tab.Pane>
              ))}
            </Tab.Content>
          </div>
        </Tab.Container>
        <DialogAdvancedStockSearch
          open={openDialogAdvancedSearch}
          handleClose={handleClose}
          advancedSearch={advancedSearch}
          dialogAdvancedSearchData={dialogAdvancedSearchData}
          changeAdvancedSearchData={changeAdvancedSearchData}
          clearAllAdvancedSearchData={clearAllAdvancedSearchData}
        />
      </Container>
      <Footer handleElementClick={handleElementClick} />
    </>
  );
};

export default compose(inject('philosophyStore'), observer)(MainPage);
