import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import styles from './styles.module.scss';

const DialogAdvancedStockSearch = ({ open, handleClose, advancedSearch, dialogAdvancedSearchData, changeAdvancedSearchData, clearAllAdvancedSearchData }) => {
  const handleChange = (event) => {
    const { id, value } = event.target;
    const newObj = {...dialogAdvancedSearchData, [id]: value};
    changeAdvancedSearchData(newObj);
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" classes={{ root: styles.root }}>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <TextField
              margin="dense"
              id="ticker"
              label="Ticker"
              type="text"
              fullWidth
              placeholder="Ticker"
              value={dialogAdvancedSearchData.ticker}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              margin="dense"
              id="company"
              label="Company"
              type="text"
              fullWidth
              placeholder="Company"
              value={dialogAdvancedSearchData.company}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              margin="dense"
              id="sector"
              label="Sector"
              type="text"
              fullWidth
              placeholder="Sector"
              value={dialogAdvancedSearchData.sector}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <TextField
              margin="dense"
              id="industry"
              label="Industry"
              type="text"
              fullWidth
              placeholder="Industry"
              value={dialogAdvancedSearchData.industry}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              margin="dense"
              id="profile"
              label="Profile"
              type="text"
              fullWidth
              placeholder="Profile"
              value={dialogAdvancedSearchData.profile}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              margin="dense"
              id="stockExchange"
              label="Stock Exchange"
              type="text"
              fullWidth
              placeholder="Stock Exchange"
              value={dialogAdvancedSearchData.stockExchange}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <TextField
              margin="dense"
              id="marketCap"
              label="Market Cap"
              type="text"
              fullWidth
              placeholder="Market Cap"
              value={dialogAdvancedSearchData.marketCap}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              margin="dense"
              id="website"
              label="Website"
              type="text"
              fullWidth
              placeholder="Website"
              value={dialogAdvancedSearchData.website}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              margin="dense"
              id="address"
              label="Address"
              type="text"
              fullWidth
              placeholder="Address"
              value={dialogAdvancedSearchData.address}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <TextField
              margin="dense"
              id="address2"
              label="Address 2"
              type="text"
              fullWidth
              placeholder="Address 2"
              value={dialogAdvancedSearchData.address2}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              margin="dense"
              id="city"
              label="City"
              type="text"
              fullWidth
              placeholder="City"
              value={dialogAdvancedSearchData.city}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              margin="dense"
              id="state"
              label="State"
              type="text"
              fullWidth
              placeholder="State"
              value={dialogAdvancedSearchData.state}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <TextField
              margin="dense"
              id="zip"
              label="Zip"
              type="text"
              fullWidth
              placeholder="Zip"
              value={dialogAdvancedSearchData.zip}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              margin="dense"
              id="country"
              label="Country"
              type="text"
              fullWidth
              placeholder="Country"
              value={dialogAdvancedSearchData.country}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              margin="dense"
              id="phone"
              label="Phone"
              type="text"
              fullWidth
              placeholder="Phone"
              value={dialogAdvancedSearchData.phone}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={advancedSearch}>
          Search
        </Button>
        <Button color="primary" onClick={clearAllAdvancedSearchData}>
          Clear All
        </Button>
        <Button color="primary" onClick={handleClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogAdvancedStockSearch;
