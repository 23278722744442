import React from 'react';

import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';

import Header from '@/containers/layout/Header';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Button from "@material-ui/core//Button";
import { CgPassword } from 'react-icons/cg';
import { makeStyles } from '@material-ui/core/styles';
import { checkSharedKeyEnabledValue } from '@/utils';
import APIService from '@/api';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
}));

const MyAccount = ({ generalStore: store }) => {
  const history = useHistory();
  const classes = useStyles();
  const [tooltipEnable, setTooltipEnable] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);

  const handleChange = () => {
    updateTooltip();
  };

  const updateTooltip = async () => {
    await APIService.setTooltipsPreferenceConfig(!tooltipEnable);
    store.rootStore.philosophyStore.changeTooltip(!tooltipEnable);
    setTooltipEnable(!tooltipEnable);
  }

  React.useEffect(() => {
    const getUserPreference = async () => {
      const userPreferences = await store.getUserPreference();
      if (userPreferences && userPreferences[0]) {
        const { toolTipEnabled } = userPreferences[0];
        if (checkSharedKeyEnabledValue(toolTipEnabled)) {
          setTooltipEnable(true);
        }
      }
      setIsLoading(false);
    };
    getUserPreference();
  }, [store]);

  const handleChangePasswordClick = () => {
    history.push("/change-password");
  };

  return (
    <>
      <Header />
      <Container fixed className={classes.root}>
        <Paper className={classes.control}>
          <FormControl component="fieldset">
            <FormLabel component="legend">User Preferences</FormLabel>
            {
              !isLoading &&
              <FormGroup>
                <FormControlLabel
                  label="Tooltips"
                  control={
                    <Switch
                      checked={tooltipEnable}
                      onChange={handleChange}
                      name="tooltipEnable"
                      color="primary"
                    />
                  }
                />

                <FormControlLabel
                  control={
                    <Button variant="contained" color="primary" startIcon={<CgPassword />} onClick={handleChangePasswordClick}>
                      Change Password
                    </Button>
                  }
                />
              </FormGroup>
            }
          </FormControl>
        </Paper>
      </Container>
    </>
  )
}

export default compose(inject('generalStore'), observer)(MyAccount);
