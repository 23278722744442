import React from 'react';
import Header from '@/containers/layout/Header';

const Template = () => {
  return (
    <>
      <Header />
      <div style={{ padding: '2rem' }}>
        <h1>Default Page</h1>
        <div>Under Construction</div>
      </div>
    </>
  )
}

export default Template;
