import React from 'react';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import Table from '@/components/Table';
import styles from './styles.module.scss';

const tableInfo = [
  { key: 'totalRevenue', label: 'Total Revenue', color: 'darkblue' },
  { key: 'costOfRevenue', label: 'Cost of Revenue' },
  { key: 'grossProfit', label: 'Gross Profit' },
  {
    key: 'sellingGeneralAndAdministrative',
    label: 'Selling General and Administrative',
  },
  { key: 'researchAndDevelopment', label: 'Research and Development' },
  { key: 'depreciationDepletionAndAmortization', label: 'Depreciation, Depletion, and Amortization' },
  { key: 'otherOperatingItems', label: 'Other Operating Items' },
  { key: 'totalOperatingExpenses', label: 'Total Operating Expenses' },
  { key: 'operatingIncome', label: 'Operating Income', color: 'darkblue' },
  { key: 'interest', label: 'Interest' },
  { key: 'nonOperating', label: 'Non Operating' },
  { key: 'incomeBeforeTax', label: 'Income Before Tax' },
  { key: 'incomeTax', label: 'Income Tax' },
  { key: 'discontinuedOperationsOtherIncome', label: 'Discontinued Operations Other Income' },
  { key: 'netIncome', label: 'Net Income', color: 'darkblue' },
  { key: 'minorityInterestIncome', label: 'Minority Interest' },
  { key: 'otherEquityRelatedItems', label: 'Other Equity Related Items' },
  {
    key: 'netIncomeApplicableToCommonShareholders',
    label: 'Net Income Applicable To Common Shareholders',
  },
  { key: 'basicWeightedAverageShare', label: 'Basic Weighted Average Share' },
  {
    key: 'dilutedWeightedAverageShare',
    label: 'Diluted Weighted Average Share',
  },
  { key: 'ePSBasic', label: 'EPS Basic', color: 'darkblue' },
  { key: 'ePSDiluted', label: 'EPS Diluted' },
  { key: 'dividendsCommonStock', label: 'Dividends Common Stock' },
];

const IncomeStatement = ({ stockStore }) => {
  const { stock } = stockStore;

  React.useEffect(() => {
    stock.getISA();
  }, [stock]);

  if (!stock.isa && !stock.cik) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <Table tableInfo={tableInfo} data={stock.isa} />
    </div>
  );
};

export default compose(inject('stockStore'), observer)(IncomeStatement);
