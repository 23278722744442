import React from 'react';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import Navigation from '@/components/Navigation';
import Loading from '@/components/Loading';
import styles from './styles.module.scss';

import AutocompleteInput from '@/components/Autocomplete';
import Button from '@material-ui/core/Button';

const TICKER = 'ticker';
const COMPANY_NAME = 'companyName';

const StockNav = ({ showSearchButton, openAdvancedSearchDialog, stockStore: store }) => {
  const { philosophy } = store.rootStore.philosophyStore;
  const { stocks, stock } = store;

  React.useEffect(() => {
    if (philosophy) {
      // Only get the stocks if not loaded
      if (!stocks) {
        store.getStocks();
      }
    }
  }, [philosophy, stocks, store]);

  if (!stocks) {
    return <Loading />;
  }

  return (
    <Container fluid>
      <Row className='p-4' xl={2} lg={1} md={1} sm={1} xs={1}>
        <Col xl={9} className={styles.row}>
          <Row lg={3} md={1} sm={1} xs={1} style={{ width: '100%' }}>
            <Col lg={2} md={2} sm={12} xs={12} className={styles.row}>
              <Navigation
                total={stocks.length}
                current={store.stockIndex}
                onChange={store.selectStock}
              />
            </Col>
            <Col lg={4} md={4} sm={12} xs={12} className={styles.row}>
              <Row xl={2} lg={2} md={1} sm={1} xs={1} style={{ width: '100%' }}>
                <Col xl={3} lg={3} md={4}>
                  <Form.Label className='mr-2' style={{ justifyContent: 'flex-start' }}>Ticker</Form.Label>
                </Col>
                <Col xl={9} lg={9} md={8}>
                  {
                    stocks.length > 0 &&
                    <AutocompleteInput
                      stocks={stocks}
                      stock={stock}
                      current={store.stockIndex}
                      onChange={store.selectStock}
                      elementToShow={TICKER}
                      showAutocompleteInput={true}
                    />
                  }
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12} className={styles.row}>
              <Row xl={2} lg={2} md={1} sm={1} xs={1} style={{ width: '100%' }}>
                <Col xl={4} lg={4} md={6}>
                  <Form.Label className='mr-2' style={{ justifyContent: 'flex-start' }}>Company Name</Form.Label>
                </Col>
                <Col xl={8} lg={8} md={6}>
                  {
                    stocks.length > 0 &&
                    <AutocompleteInput
                      stocks={stocks}
                      stock={stock}
                      current={store.stockIndex}
                      onChange={store.selectStock}
                      elementToShow={COMPANY_NAME}
                      showAutocompleteInput={false}
                    />
                  }
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xl={3} className={styles.row} style={{ justifyContent: 'center' }}>
          {
            philosophy && <Form.Label>{philosophy.name}</Form.Label>
          }
          {
            showSearchButton && <Button onClick={openAdvancedSearchDialog} style={{marginLeft: '20px'}} variant="contained" color="primary">Search</Button>
          }
        </Col>
      </Row>
    </Container>
  );
};

export default compose(inject('stockStore'), observer)(StockNav);
