import React from 'react';
import { Spinner } from 'react-bootstrap';
import styles from './styles.module.scss';

const Loading = (props) => {
  return (
    <div className={styles.loading}>
      <Spinner animation='border' {...props} />
    </div>
  );
};

export default Loading;
