import { observable, computed, action, runInAction, toJS } from 'mobx';
import APIService from '@/api';

class AuthStore {
  @observable id;
  @observable token;
  @observable alias;

  constructor(rootStore) {
    this.rootStore = rootStore;

    this.id = localStorage.getItem('id');
    this.token = localStorage.getItem('token');
    this.email = localStorage.getItem('email');
    this.planId = localStorage.getItem('planId');
    if (this.id && this.token) {
      APIService.setToken(this.token);
    } else {
      this.logout();
    }
  }

  @computed get isAuthenticated() {
    return !!this.token;
  }

  login = async (email, password) => {
    try {
      const data = await APIService.signInWithEmail(email, password);
      runInAction(() => {
        this.id = data.id;
        this.token = data.token;
        this.email = data.email;
        this.planId = data.planId;
        localStorage.setItem('id', this.id);
        localStorage.setItem('token', this.token);
        localStorage.setItem('email', this.email);
        localStorage.setItem('planId', this.planId);
        APIService.setToken(this.token);
      });
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  logout = () => {
    this.id = undefined;
    this.token = undefined;
    this.alias = undefined;
    localStorage.removeItem('id');
    localStorage.removeItem('token');
    localStorage.removeItem('email');
    localStorage.removeItem('planId');
    if (this.rootStore && this.rootStore.philosophyStore) {
      this.rootStore.philosophyStore.resetAllData();
    }
    APIService.setToken();

    // Set default page title
    document.title = "Market Clue";
  };

  processAlias = (aliasProxy) => {
    return toJS(aliasProxy);
  };

  @action getAlias = async () => {
    if (this.alias !== undefined) return this.processAlias(this.alias);

    this.loadingAlias = true;
    this.alias = await APIService.getAlias();
    this.loadingAlias = false;

    return this.processAlias(this.alias);
  };

  @action setAlias = async (alias) => {
    const result = await APIService.setAlias(alias);
    if (!result.error) {
      this.alias = alias;
    }
    return result;
  };

  forgotPassword = async (email) => {
    try {
      return await APIService.forgotPassword(email);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
}

export default AuthStore;
