import React, { useEffect } from 'react';

import './styles.scss';
import { makeStyles } from '@material-ui/core/styles';

import Header from '@/containers/layout/Header';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import MarketClueIntro from "@/images/MarketClueIntro.mp4";

const useStyles = makeStyles({
  gridContainer: {
    padding: '2rem',
    maxWidth: '100%',
    height: '90vh',
    marginTop: '20px',
  },
  image: {
    width: '100%',
  },
  typographyTextCenter: {
    textAlign: 'center',
  },
  divider: {
    height: '3px',
    marginBottom: '10px',
  },
  typographyTextLeft: {
    textAlign: 'left',
  },
  br: {
    marginBottom: '5px',
  },
  menuItemLink: {
    textDecoration: 'none !important',
    color: 'white',
    cursor: 'pointer',
    '&:hover': {
      color: 'white',
    },
  },
  divBtnContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },
});

const Welcome = () => {
  useEffect(() => {
    // Handle google conversion support
    window.gtag('event', 'conversion', {'send_to': 'AW-10857170496/_h6UCILh9aoDEMCMjbko'});
  }, []);

  const classes = useStyles();
  const welcomeOptions = [
    {
      key: 1,
      text: 'Perform Fundamental Analysis on NYSE, NASDAQ, and OTC stocks'
    },
    {
      key: 2,
      text: 'Calculate the Intrinsic Value of over 5000+ publicly traded companies'
    },
    {
      key: 3,
      text: 'Select from among 50+ financial ratios, assign grades, and see the results'
    },
    {
      key: 4,
      text: 'Powerful stat generator that crawls through forty thousand 10Ks'
    },
    {
      key: 5,
      text: 'Research over 120,000 Income Statements, Balance Sheets, and Cash Flow'
    },
    {
      key: 6,
      text: 'Screen by Sector, Industry, and/or Country'
    },
    {
      key: 7,
      text: 'Query company Profile, City, State, and/or Exchange'
    },
    {
      key: 8,
      text: 'Share your Investment Philosophy (configurations) with other users'
    },
    {
      key: 9,
      text: 'Track company financial progress'
    },
    {
      key: 10,
      text: 'Videos, tooltips, and more'
    }
  ];

  const handleVideoPlay = () => {
    // Handle google conversion support
    window.gtag('event', 'conversion', {'send_to': 'AW-10857170496/84B4CITywKoDEMCMjbko'});
  };

  const handleTryForFreeBtn = () => {
    // Handle google conversion support
    window.gtag('event', 'conversion', {'send_to': 'AW-10857170496/VhQYCPr69qoDEMCMjbko'});
  };

  return (
    <>
      <Header />
      <Grid className={classes.gridContainer} container spacing={3}>
        <Grid item xs={12} sm={8}>
          <video
            onPlay={handleVideoPlay}
            autoPlay
            controls
            style={{
              width: "100%",
              height: "100%"
            }}>
            <source src={MarketClueIntro} type='video/mp4' />
          </video>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography className={classes.typographyTextCenter} gutterBottom variant="h4">
            Implement your investment philosophy.
          </Typography>
          <Divider className={classes.divider} />
          <List dense={false}>
            {
              welcomeOptions.map((item) =>
                <ListItem key={item.key}>
                  <ListItemIcon>
                    <ul><li></li></ul>
                  </ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItem>
              )
            }
          </List>
          <div className={classes.divBtnContainer}>
            <Button variant="contained" color="primary">
              <Link className={classes.menuItemLink} to='/pick-plan' onClick={handleTryForFreeBtn}>
                Try for Free
              </Link>
            </Button>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default Welcome;
