import React from 'react';

import Header from '@/containers/layout/Header';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import DialogFooterContentTerms from "@/components/DialogFooterContentTerms";

const useStyles = makeStyles({
  gridContainer: {
    padding: '2rem',
    maxWidth: '100%',
    height: '90vh',
    marginTop: '20px',
  },
  paperContainer: {
    padding: '2rem',
  },
  typographyContainer: {
    width: '100%',
  },
});

const Terms = () => {
  const classes = useStyles();

  return (
    <>
      <Header />
      <Grid className={classes.gridContainer} container spacing={3}>
        <div className={classes.typographyContainer}>
          <Typography align='center' variant="h5" gutterBottom component="div">
            Terms and Conditions of Use
          </Typography>
        </div>
        <Paper className={classes.paperContainer} elevation={3}>
          <DialogFooterContentTerms />
        </Paper>
      </Grid>
    </>
  )
}

export default Terms;