import React from 'react'
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import styles from './styles.module.scss';
import APIService from '@/api';

const DialogSupport = ({ open, handleClose, email, handleSentEmail }) => {
  const [subject, setSubject] = React.useState('');
  const [body, setBody] = React.useState('');

  const handleClickSend = async () => {
    APIService.contactUs(email, subject, body).then(response => {
      handleSentEmail(response);
      setSubject('');
      setBody('');
    }).catch(error => {
      console.error(error);
    });
  };

  const isDisableSendButton = () => {
    return subject === '' || body === '';
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" classes={{ root: styles.root }}>
      <DialogTitle id="form-dialog-title">Contact Us</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label={`Subject - chars left: ${50 - subject.length}`}
          type="text"
          variant="outlined"
          inputProps={{ maxLength: 50 }}
          fullWidth
          value={subject}
          onChange={(event) => setSubject(event.target.value)}
        />
        <TextField
          id="standard-multiline-static"
          label="Body"
          multiline
          rows={5}
          variant="outlined"
          fullWidth
          value={body}
          onChange={(event) => setBody(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleClickSend} color="primary" disabled={isDisableSendButton()}>
          Send
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DialogSupport.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  email: PropTypes.string,
  handleSentEmail: PropTypes.func,
}

export default DialogSupport;
