import React from 'react';
import Header from '@/containers/layout/Header';
import { Link } from 'react-router-dom';

const StripePaymentCancel = () => {
  return (
    <>
      <Header />
      <div style={{ padding: '2rem' }}>
        <h5>Your account registration was not successful.  Please login to subscribe to a plan or contact customer support for assistance.</h5>
        <div>
          <Link to='/login'>
            Login
          </Link>
        </div>
      </div>
    </>
  );
};

export default StripePaymentCancel;
