import React from 'react';
import styles from './styles.module.scss';

const Checkbox = ({ color, label, checked, middle, onChange, ...props }) => {
  const getCheckedStyle = () => {
    return middle ? styles.middle : styles.checked;
  }
  return (
    <div className={styles.root} onClick={() => onChange(!checked)} {...props}>
      <span
        className={`${styles.checkbox} ${checked ? getCheckedStyle() : ''}`}
        style={{ color }}
      />
      <span className={styles.label}>{label}</span>
    </div>
  );
};

export default Checkbox;
