import React from 'react';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { Modal, Spinner } from 'react-bootstrap';

import styles from './styles.module.scss';

const LoadingDialog = ({ generalStore: store }) => {
  return (
    <Modal
      show={!!store.loading}
      onHide={() => {}}
      centered
      dialogClassName={styles.loadingModal}
    >
      <Spinner animation='border' variant='light' />
    </Modal>
  );
};

export default compose(inject('generalStore'), observer)(LoadingDialog);
