import { observable, action, computed } from 'mobx';
import _unionBy from 'lodash/unionBy';
import APIService from '@/api';

class CompanyStock {
  @observable cik;
  @observable stockIndex;
  @observable.shallow summary;
  @observable.shallow secFiling;
  @observable.shallow scuttlebut;
  @observable.shallow notes;
  @observable shareableNote;
  @observable.shallow profile;
  @observable.shallow isa;
  @observable.shallow bsa;
  @observable.shallow cfa;
  @observable tickerTest;
  @observable companyNameTest;

  constructor(philosophyId, cik, stockIndex, ticker, companyName) {
    this.philosophyId = philosophyId;
    this.cik = cik;
    this.stockIndex = stockIndex;
    this.shareableNote = localStorage.getItem('shareableNote') === 'true';
    this.tickerTest = ticker;
    this.companyNameTest = companyName;
  }

  @computed get ticker() {
    return (
      (this.summary ||
        this.profile ||
        (this.isa && this.isa[0]) ||
        (this.bsa && this.bsa[0]) ||
        (this.cfa && this.cfa[0]) ||
        {})['ticker'] || ''
    );
  }

  @computed get companyName() {
    return (
      (this.summary ||
        this.profile ||
        (this.isa && this.isa[0]) ||
        (this.bsa && this.bsa[0]) ||
        (this.cfa && this.cfa[0]) ||
        {})['companyName'] || ''
    );
  }

  /* get stock */

  @action getSummary = async () => {
    if (!this.cik || this.loadingSummary) return;
    this.loadingSummary = true;

    this.summary = await APIService.getStock(
      this.philosophyId,
      this.cik,
      this.stockIndex
    );
    this.loadingSummary = false;
  };

  /* get sec filing */

  @action getSECFiling = async () => {
    if (!this.cik || this.loadingSECFiling) return;
    this.loadingSECFiling = true;

    this.secFiling = await APIService.getCompanySECFiling(this.cik);
    this.secFiling = this.secFiling.slice().sort((a, b) => new Date(b.filingDate) - new Date(a.filingDate));
    this.loadingSECFiling = false;
  };

  /* scuttlebut */

  @action setShareNote = (shareableNote) => {
    localStorage.setItem('shareableNote', shareableNote);
    this.shareableNote = shareableNote;
  };

  @action getScuttlebut = async () => {
    const res = await APIService.getScuttlebut(this.cik);
    const pendingItems = (this.scuttlebut || []).filter(
      (item) => item.deleted || item.sending
    );
    const scuttlebut = _unionBy(pendingItems, res, 'id');
    scuttlebut.sort((a, b) => a.id - b.id);
    this.scuttlebut = scuttlebut;
  };

  @action addScuttlebut = async (msg, alias) => {
    const newItem = {
      id: new Date().getTime(),
      date: new Date().toString(),
      message: msg,
      sending: true,
      alias,
    };
    this.scuttlebut = [...this.scuttlebut, newItem];

    const res = await APIService.addScuttlebut(this.cik, msg);
    newItem.id = res.id;
    newItem.sending = false;
    this.scuttlebut = [...this.scuttlebut];
  };

  @action removeScuttlebut = async (id) => {
    this.scuttlebut = this.scuttlebut.map((item) =>
      item.id === id ? { ...item, deleted: true } : item
    );

    await APIService.removeScuttlebut(id);
    this.scuttlebut = this.scuttlebut.filter((item) => item.id !== id);
  };

  /* notes */

  @action getNotes = async () => {
    const res = (await APIService.getNotes(this.philosophyId, this.cik))
      .map((item) => {
        item.id = item.iPSUserCompanyNotesId;
        item.date = item.createdDateTime;
        return item;
      });

    const pendingItems = (this.notes || []).filter((item) => item.deleted || item.sending);
    const notes = _unionBy(pendingItems, res, 'id');
    notes.sort((a, b) => a.id - b.id);
    this.notes = notes;
  };

  @action addNote = async (msg, alias) => {
    const newItem = {
      iPSUserCompanyNotesId: new Date().getTime(),
      date: new Date().toString(),
      message: msg,
      shareable: this.shareableNote,
      sending: true,
      alias,
    };
    this.notes = [...this.notes, newItem];

    const res = await APIService.addNote(
      this.cik,
      this.philosophyId,
      msg,
      this.shareableNote
    );
    newItem.id = res.id;
    newItem.sending = false;
    this.notes = [...this.notes];
  };

  @action removeNote = async (id) => {
    this.notes = this.notes.map((item) =>
      item.id === id ? { ...item, deleted: true } : item
    );

    await APIService.removeNote(id);
    this.notes = this.notes.filter((item) => item.id !== id);
  };

  /* get company profile */

  @action getProfile = async () => {
    if (!this.cik || this.loadingProfile) return;
    this.loadingProfile = true;

    this.profile = await APIService.getCompanyStockProfile(
      this.cik,
      this.stockIndex
    );
    this.loadingProfile = false;
  };

  /* get company income statement */

  @action getISA = async () => {
    if (!this.cik || this.loadingISA) return;
    this.loadingISA = true;

    const isa = await APIService.getCompanyStockISA(this.cik, this.stockIndex);
    isa.sort((a, b) => b.fiscalYear - a.fiscalYear);
    this.isa = isa;
    this.loadingISA = false;
  };

  /* get company balance sheet */

  @action getBSA = async () => {
    if (!this.cik || this.loadingBSA) return;
    this.loadingBSA = true;

    const bsa = await APIService.getCompanyStockBSA(this.cik, this.stockIndex);
    bsa.sort((a, b) => b.fiscalYear - a.fiscalYear);
    this.bsa = bsa;
    this.loadingBSA = false;
  };

  /* get company cash flow */

  @action getCFA = async () => {
    if (!this.cik || this.loadingCFA) return;
    this.loadingCFA = true;

    const cfa = await APIService.getCompanyStockCFA(this.cik, this.stockIndex);
    cfa.sort((a, b) => b.fiscalYear - a.fiscalYear);
    this.cfa = cfa;
    this.loadingCFA = false;
  };
}

export default CompanyStock;
