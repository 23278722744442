import React, { useEffect, useState } from 'react';
import Header from '@/containers/layout/Header';

import './styles.scss';
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Card, CardActions, CardContent, CardHeader } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { useHistory } from 'react-router-dom';
import APIService from '@/api';
import { compose } from "recompose";
import { inject, observer } from "mobx-react";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: '2rem',
    maxWidth: '100%'
  },
  gridContainer: {
    justifyContent: 'center',
  },
  card: {
    height: '48em',
    width: '20em',
  },
  cardContent: {
    height: '32em',
    overflowY: 'auto',
  },
  control: {
    padding: theme.spacing(2),
  },
}));

const PickPlan = ({ location, authStore }) => {
  const classes = useStyles();
  const [plans, setPlans] = useState([]);
  const [currentPlan, setCurrentPlan] = useState(-1);
  const history = useHistory();

  const populatePlans = () => {
    const plansArray = [];
    plansArray.push({
      id: 1,
      planSubscriptionId: 1,
      name: 'Basic',
      subheader: 'For users looking for a powerful financial sorting tool',
      headerColor: 'rgb(40, 56, 144)',
      price: 'FREE',
      priceBilling: '',
      priceBillingAnnually: '',
      body: [
        {
          text: '• Ability to sort companies:', style: null, child: [
            {
              text: '• Create 1 criterion', style: { marginLeft: 10 }, child: [
                { text: '- Access to 50+ ratios', style: { marginLeft: 20 } },
                { text: '- Apply a formula', style: { marginLeft: 20 } },
                { text: '- Set criteria date range', style: { marginLeft: 20 } },
                { text: '- Grade results', style: { marginLeft: 20 } },
              ]
            },
            { text: '• Import presets', style: { marginLeft: 10 }, child: null }
          ]
        },
        {
          text: '• Summary page with company:', style: { marginTop: 10 }, child: [
            { text: '- Criterion grade results', style: { marginLeft: 10 }, child: null },
            { text: '- Trade data', style: { marginLeft: 10 }, child: null },
            { text: '- SEC filings', style: { marginLeft: 10 }, child: null },
            { text: '- Personal notes', style: { marginLeft: 10 }, child: null },
          ]
        },
        {
          text: '• Access to company:', style: { marginTop: 20 }, child: [
            { text: '• Company Profile', style: { marginLeft: 10 }, child: null },
            { text: '• Income Statement', style: { marginLeft: 10 }, child: null },
            { text: '• Balance Sheet', style: { marginLeft: 10 }, child: null },
            { text: '• Cash Flow', style: { marginLeft: 10 }, child: null },
          ]
        },
        {
          text: '• Videos that:', style: { marginTop: 10 }, child: [
            { text: '- Describe each ratio', style: { marginLeft: 10 }, child: null },
            { text: '- Give you tool tips and tricks', style: { marginLeft: 10 }, child: null },
          ]
        }
      ]
    });
    plansArray.push({
      id: 2,
      planSubscriptionId: 2,
      name: 'Professional',
      subheader: 'For investors seeking to implement investment philosophy',
      headerColor: 'rgb(12, 102, 56)',
      price: '$19.99',
      priceBilling: 'Per user, per month billed annually',
      priceBillingAnnually: 'Total $239.88/year',
      body: [
        {
          text: '• Create investment philosophy:', style: null, child: [
            {
              text: '• Stack up to 10 criterias', style: { marginLeft: 10 }, child: [
                { text: '- Access to 50+ ratios', style: { marginLeft: 20 } },
                { text: '- Apply a formula', style: { marginLeft: 20 } },
                { text: '- Set criteria date range', style: { marginLeft: 20 } },
                { text: '- Grade results', style: { marginLeft: 20 } },
                { text: '- Set criteria weight', style: { marginLeft: 20 } },
              ]
            },
            { text: '• Create multiple philosophies', style: { marginLeft: 10 }, child: null },
            { text: '• Collaborate/Share philosophies', style: { marginLeft: 10 }, child: null },
            { text: '• Import presets', style: { marginLeft: 10 }, child: null },
            { text: '• Import philosophy of famous investors', style: { marginLeft: 10 }, child: null },
            { text: '• Ability to screen by Country, Sector, and industry', style: { marginLeft: 10 }, child: null },
            { text: '• Ability to include/exclude tickers', style: { marginLeft: 10 }, child: null },
          ]
        },
        {
          text: '• Summary page with:', style: { marginTop: 10 }, child: [
            { text: '- Philosophy grade results', style: { marginLeft: 10 }, child: null },
            { text: '- Scuttlebutt per company', style: { marginLeft: 10 }, child: null },
            { text: '- Collaborate/Share notes per philosophy', style: { marginLeft: 10 }, child: null },
          ]
        },
        {
          text: '• Access to company:', style: { marginTop: 10 }, child: [
            {
              text: '• Company Profile', style: { marginLeft: 10 }, child: [
                { text: '- Ability to query company info', style: { marginLeft: 20 } }
              ]
            },
            { text: '• Income Statement', style: { marginLeft: 10 }, child: null },
            { text: '• Balance Sheet', style: { marginLeft: 10 }, child: null },
            { text: '• Cash Flow', style: { marginLeft: 10 }, child: null },
          ]
        },
        {
          text: '• Videos that:', style: { marginTop: 20 }, child: [
            { text: '- Describe each ratio', style: { marginLeft: 10 }, child: null },
            { text: '- Give you tool tips and tricks', style: { marginLeft: 10 }, child: null },
          ]
        },
        {
          text: '• Support', style: { marginTop: 10 }, child: null
        },
      ]
    });
    plansArray.push({
      id: 3,
      planSubscriptionId: 3,
      name: 'Premium',
      subheader: 'For investors seeking to implement complex or multiple investment philosophies',
      headerColor: 'rgb(4, 5, 3)',
      price: '$39.99',
      priceBilling: 'Per user, per month billed annually',
      priceBillingAnnually: 'Total $479.88/year',
      body: [
        { text: 'Everything from Professional plus...', style: { fontWeight: 'bold' }, child: null },
        { text: '• Create investment philosophy:', style: { marginTop: 10 }, child: [{ text: '• Stack up to 30 criterias', style: { marginLeft: 10 } }] }
      ]
    });
    setPlans(plansArray);
  };

  useEffect(() => {
    populatePlans();

    const checkPlanId = () => {
      // Check if the user has reach pick-up plan page for the first time and need to see all the initial plans
      const { state } = location;
      if (!state) {
        return;
      }
      // in other case, the user already exists, apply special styles for current plan
      const { currentPlan } = state;
      if (currentPlan) {
        setCurrentPlan(+currentPlan);
      }
    };
    checkPlanId();
  }, [location]);

  const logout = () => {
    // Logout the user in case is logged in
    if (authStore.isAuthenticated) {
      authStore.logout();
    }
  };

  const reactivateAccount = (email, planSubscriptionId) => {
    APIService.reactivateUserAccount(email, planSubscriptionId).then(response => {
      if (response.redirect) {
        logout();
        window.location = response.response;
      } else {
        // Check if the session to be passed to login page was returned
        if (response.sessionId) {
          logout();
          history.push(`/login?session_id=${response.sessionId}`);
        } else {
          // So far redirect the user to login page
          history.push("/login");
        }
      }
    }).catch(error => {
      console.error(error);
    });
  };

  const handleGoogleConvertionTrackingPlan = (planSubscriptionId) => {
    let sentTo;
    switch (planSubscriptionId) {
      case 1:
        sentTo = 'sGomCKqkwaoDEMCMjbko';
        break;
      case 2:
        sentTo = 'n97GCLCqwaoDEMCMjbko';
        break;
      case 3:
        sentTo = '2XqkCJCR96oDEMCMjbko';
        break;
      default:
        sentTo = '';
    }
    if (sentTo && sentTo !== '') window.gtag('event', 'conversion', {'send_to': `AW-10857170496/${sentTo}`});
  }

  const handleSelectPlanClick = (planSubscriptionId) => {
    // Handle google conversion support
    handleGoogleConvertionTrackingPlan(planSubscriptionId);
    // Check if the user has reach pick-up plan page for the first time and need to register
    const { state } = location;
    if (!state) {
      history.push('/register', {planSubscriptionId});
      return;
    }

    // in other case, the user already exists, avoid creating IPS user and stripe customer
    const { email } = state;
    if (email) {
      reactivateAccount(email, planSubscriptionId);
    }

    if (authStore.isAuthenticated) {
      reactivateAccount(authStore.email, planSubscriptionId);
    }
  };

  const subheaderElement = (plan) => <>
    {plan.subheader}
    <Typography variant="h4">{plan.price}</Typography>
    <Typography variant="subtitle2">{plan.priceBilling}</Typography>
    <Typography variant="subtitle2">{plan.priceBillingAnnually}</Typography>
  </>;

  const getPlanBody = (planBody, index) => {
    return (
      <div key={index}>
        <Typography style={planBody.style} variant="subtitle2">{planBody.text}</Typography>
        {planBody.child && planBody.child.map((child, ind) => (
          <div key={ind}>
            <Typography style={child.style} variant="subtitle2">{child.text}</Typography>
            {child.child && child.child.map((item, i) => (
              <Typography key={i} style={item.style} variant="subtitle2">{item.text}</Typography>
            ))}
          </div>
        ))}
      </div>
    )
  };

  return (
    <>
      <Header />
      <Grid container className={classes.root} spacing={4}>
        <Grid item xs={12}>
          <Grid container className={classes.gridContainer} spacing={4}>
            {plans.map((plan) => (
              <Grid key={plan.id} item>
                <Card className={classes.card} style={{ border: `1px solid ${plan.headerColor}`, opacity: `${plan.id === currentPlan ? '0.6' : '1'}` }}>
                  <CardHeader
                    style={{ height: 200, backgroundColor: plan.headerColor, cursor: 'pointer' }}
                    title={plan.name}
                    subheader={subheaderElement(plan)}
                    onClick={() => handleSelectPlanClick(plan.planSubscriptionId)}
                  />
                  <CardContent className={classes.cardContent}>
                    {plan && plan.body && plan.body.map((item, index) => (
                      getPlanBody(item, index)
                    ))}
                  </CardContent>
                  <CardActions style={{ justifyContent: 'center' }}>
                    {
                      plan.id === currentPlan &&
                      <Button style={{ backgroundColor: plan.headerColor, color: 'white', cursor: 'not-allowed' }} variant="contained">
                        {'Current Plan'}
                      </Button>
                    }
                    {
                      plan.id !== currentPlan &&
                      <Button style={{ backgroundColor: plan.headerColor, color: 'white' }} variant="contained" onClick={() => handleSelectPlanClick(plan.planSubscriptionId)}>
                        {'Select Plan'}
                      </Button>
                    }
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default compose(inject('authStore'), observer)(PickPlan);
