import React from 'react';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { Row, Col, Form } from 'react-bootstrap';
import Loading from '@/components/Loading';
import CriteriaList from './CriteriaList';
import SummaryChart from './SummaryChart';
import SummarySECList from './SummarySECList';
import Scuttlebut from './Scuttlebut';
import Notes from './Notes';
import styles from './styles.module.scss';

const Summary = ({ stockStore }) => {
  const { stock } = stockStore;

  React.useEffect(() => {
    stock.getSummary();
  }, [stock]);

  if (!stock.summary) {
    return stock.cik ? <Loading /> : null;
  }

  return (
    <div className='px-4'>
      <Row className='mb-3'>
        <Col xs={3}>
          <CriteriaList
            criterion={stock.summary.criterion}
            finalGrade={stock.summary.finalGrade}
          />
        </Col>
        <Col xs={9}>
          <SummaryChart stock={stock} />

          <Form.Label>
            SEC Filings
          </Form.Label>
          <SummarySECList />

          <Form.Label className='mt-2'>
            Scuttlebutt
          </Form.Label>
          <Scuttlebut />
        </Col>
      </Row>
      
      <Row>
        <Col className={styles.row}>
          <Form.Label
            className={`mr-2 ${styles.notesLabel}`}
          >
            Notes
            <div title='Check this box if you would like to share your notes with other users in this philosophy. Deselect when you no longer wish to share.'>
              <Form.Check
                type='checkbox'
                checked={stock.shareableNote}
                onChange={(e) => stock.setShareNote(e.target.checked)}
              />
            </div>
          </Form.Label>
          <Notes />
        </Col>
      </Row>
      
    </div>
  );
};

export default compose(inject('stockStore'), observer)(Summary);
