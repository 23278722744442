import React, { useState } from "react";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";
import Criteria from "./Criteria";
import styles from "./styles.module.scss";
import APIService from '@/api';
import DialogUpdateFeature from '@/components/DialogUpdateFeature';
import { Button } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';

const tooltipText = { fontSize: '12px' };

const Philosophy = ({ handleAddCriteriaClick, handleSelectRatioClick, philosophyStore }) => {
  const { philosophy } = philosophyStore;
  const [openDialogUpgrade, setOpenDialogUpgrade] = useState(false);

  const handleAddPhilosophyButtonClick = () => {
    handleAddCriteriaClick();
    APIService.getUserCurrentCriteriaBalance().then(result => {
      if (result && result[0]) {
        const { totalCriteriaBalance } = result[0];
        if (totalCriteriaBalance > 0) {
          philosophy.addCriteria();
        } else {
          setOpenDialogUpgrade(true);
        }
      }
    }).catch(error => {
      console.log(`error: ${error}`);
    });
  };

  const handleCloseDialogUpgrade = () => {
    setOpenDialogUpgrade(false);
  };

  return (
    <>
      {philosophy.criterion?.map((item, index) => (
        <Criteria key={index} data={item} index={index} handleSelectRatioClick={handleSelectRatioClick} />
      ))}
      <div className={styles.footer}>
        <Tooltip
          className={styles.tooltip}
          title={<div style={tooltipText}>Click to add a criterion into your philosophy.</div>}
          arrow
          placement="right"
        >
          <Button variant="contained" color="primary" onClick={handleAddPhilosophyButtonClick}>
            Add Criteria
          </Button>
        </Tooltip>
      </div>

      <DialogUpdateFeature
        openDialogUpgrade={openDialogUpgrade}
        handleCloseDialogUpgrade={handleCloseDialogUpgrade}
        insufficientSpace={true}
      />
    </>
  );
};

export default compose(inject("philosophyStore"), observer)(Philosophy);
