import React, { useState, useEffect } from 'react';
import './styles.scss';
import moment from 'moment';

import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer
} from 'recharts';
import { ButtonGroup, ToggleButton } from 'react-bootstrap';

import APIService from '@/api';

const SummaryChart = ({ stock }) => {
  const [filter, setFilter] = useState('1d');
  const [data, setData] = useState([]);
  const [xInterval, setXInterval] = useState(30);
  const [chartFillColor, setChartFillColor] = useState('#00873C');
  const [dataMin, setDataMin] = useState("dataMin");
  const [dataMax, setDataMax] = useState("dataMax");

  const filters = ['1d', '1mo', '3mo', '6mo', '1yr', '5yr'];
  const cik = stock ? stock.cik : '';
  const stockIndex = stock ? stock.stockIndex : '';
  const endDate = moment().format('YYYY-MM-DD');

  const formatYAxis = tickItem => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });

    return formatter.format(tickItem);
  };

  const setFilterData = (data) => {
    setFilter(data);

    switch (data) {
      case '1d': {
        setXInterval(30);
        break;
      }
      case '1mo': {
        setXInterval(30);
        break;
      }
      case '3mo': {
        setXInterval(30);
        break;
      }
      case '6mo': {
        setXInterval(5);
        break;
      }
      default: {
        setXInterval('preserveEnd');
        break;
      }
    }
  };

  useEffect(() => {
    const setChartColor = (data) => {
      if (data && data.length > 0) {
        const fillColor = Number(data[0].price) > Number(data[data.length - 1].price) ? '#FF0000' : '#00873C';
        setChartFillColor(fillColor);
      }
    };

    const setDataMapRangesValues = (response) => {
      // We need to control the dataMin to displayed in the map
      const prices = response.map(item => item.price);
      if (prices.length <= 0) return;
      const min = Math.min(...prices);
      const max = Math.max(...prices);

      const minPercent = ((min / 100) * 1).toFixed(2);
      const maxPercent = ((max / 100) * 1).toFixed(2);
      
      const valueMin = `dataMin - ${minPercent}`;
      setDataMin(valueMin);

      const valueMax = `dataMax + ${maxPercent}`;
      setDataMax(valueMax);
    };

    const getStockTradeData = async () => {
      const response = await APIService.getStockTradeData(cik, stockIndex, endDate, filter);
      if (response.length > 0) {
        setDataMapRangesValues(response);
        setChartColor(response);
        setData(response);
      } else {
        setData([]);
      }
    };
    getStockTradeData();
  }, [cik, stockIndex, endDate, filter]);

  return (
    <div className="summary-chart">
      <div className="chart-container">
        <ResponsiveContainer>
          <AreaChart
            width={730}
            height={250}
            data={data}
            margin={{
              top: 10, right: 30, left: 80, bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis interval={xInterval} dataKey={"name"} />
            <YAxis tickFormatter={formatYAxis} domain={[dataMin, dataMax]} />
            <Tooltip />
            <Area type="monotone" dataKey="price" stroke={chartFillColor} fill={chartFillColor} />
          </AreaChart>
        </ResponsiveContainer>
      </div>
      <ButtonGroup toggle className="date-toggle">
        {filters.map((f, idx) => (
          <ToggleButton
            key={idx}
            type="radio"
            variant="secondary"
            name="radio"
            value={f}
            checked={filter === f}
            onChange={(e) => setFilterData(e.currentTarget.value)}
          >
            {f}
          </ToggleButton>
        ))}
      </ButtonGroup>
    </div>
  )
}

export default SummaryChart;
