import React from 'react';
import styles from './styles.module.scss';
import { GoPlus } from "react-icons/go";

const CheckboxWithBtn = ({ color, label, checked, middle, openModal, ...props }) => {
  return (
    <div className={styles.root} onClick={() => openModal()} {...props}>
      <span
        className={`${styles.checkbox}`} style={{ color }}
      ><GoPlus className={styles.plusIcon} /></span>
      <span className={styles.label}>{label}</span>
    </div>
  );
};

export default CheckboxWithBtn;
