import React from 'react';
import { Table } from 'react-bootstrap';
import Loading from '@/components/Loading';
import styles from './styles.module.scss';

const CustomizedTable = ({ tableInfo, data }) => {
  if (!data) {
    return <Loading />;
  }

  const getTableStyle = (key) => {
    let style = {};
    switch (key) {
      case 'totalRevenue':
      case 'operatingIncome':
      case 'netIncome':
      case 'ePSBasic':
      case 'totalCurrentAssets':
      case 'totalAssets':
      case 'totalCurrentLiabilities':
      case 'totalLiabilities':
      case 'totalStockholdersEquity':
      case 'cashFromOperatingActivities':
      case 'cashFromInvestingActivities':
      case 'cashFromFinancingActivities':
        style = { color: 'darkblue', fontWeight: 'bold' };
        break;
      default:
        style = { color: '' };
        break;
    }
    return style;
  }

  return (
    <Table striped hover className={styles.table}>
      <thead>
        <tr>
          <th>Value In {(data[0] || {}).currency}</th>
          {data.map((item) => (
            <th key={item.fiscalYear}>{item.fiscalYear}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tableInfo.map((row) => (
          <tr key={row.key}>
            <td style={{ color: row.color || '', fontWeight: row.color ? 'bold' : '' }}>{row.label}</td>
            {data.map((item) => (
              <td key={item.fiscalYear} style={getTableStyle(row.key)}>
                {Number.isNaN(parseFloat(item[row.key])) ? item[row.key] : Number(item[row.key]).toLocaleString()}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default CustomizedTable;
