import { observable, computed, action, reaction } from "mobx";

class Criteria {
  @observable financialRatio;
  @observable formulaType;
  @observable noDataOption;
  @observable numberOfYear = 1;
  @observable percent0Value = "";
  @observable percent60Value = "";
  @observable percent70Value = "";
  @observable percent80Value = "";
  @observable percent90Value = "";
  @observable percent100Value = "";
  @observable weigh = 1;

  constructor(philosophy, info) {
    this.philosophy = philosophy;
    this.store = philosophy.store;
    if (info) {
      this.id = info.id;
      this.financialRatio = info.financialRatio;
      this.formulaType = info.formulaType;
      this.noDataOption = info.noDataOption;
      this.numberOfYear = info.numberOfYear;
      this.percent0Value = info.percent0Value;
      this.percent60Value = info.percent60Value;
      this.percent70Value = info.percent70Value;
      this.percent80Value = info.percent80Value;
      this.percent90Value = info.percent90Value;
      this.percent100Value = info.percent100Value;
      this.weigh = info.weigh;
    } else {
      this.financialRatio = '';
    }

    reaction(
      () => this.formulaTypes,
      () => {
        const formulaType = this.formulaTypes.find(
          (item) => item.id === this.formulaType
        );
        if (!formulaType) {
          if (this.formulaTypes[0]) {
            this.formulaType = this.formulaTypes[0].id;
          } else {
            console.warn("No this.formulaTypes[0]", this.formulaTypes);
          }
        }
      },
      {
        fireImmediately: true,
      }
    );

    reaction(
      () => this.noDataOptions,
      () => {
        const noDataOption = this.noDataOptions.find(
          (item) => item.id === this.noDataOption
        );
        if (!noDataOption) {
          if (this.noDataOptions[0]) {
            this.noDataOption = this.noDataOptions[0].id;
          } else {
            console.warn(`No this.noDataOptions[0]`, this.noDataOptions[0]);
          }
        }
      },
      {
        fireImmediately: true,
      }
    );
  }

  @computed get formulaTypes() {
    if (this.store && this.store.financialRatios) {
      const item = this.store.financialRatios.find(
        (v) => v.id === this.financialRatio
      );
      return item ? item.formulaTypes : [];
    } else {
      return [];
    }
  }

  @computed get noDataOptions() {
    const item = this.formulaTypes.find((v) => v.id === this.formulaType);
    return item ? item.noDataOptions : [];
  }

  @action update = (name, value) => {
    this[name] = value;
    this.philosophy.isModified = true;
  };
}

export default Criteria;
