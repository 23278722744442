import { observable, action, runInAction, reaction } from 'mobx';
import APIService from '@/api';
import CompanyStock from './CompanyStock';

class SummaryStore {
  @observable.shallow stocks;
  @observable stockIndex = -1;
  @observable stock;
  @observable filteredByOrder = 'DESC';
  @observable filteredBy = 'Final Grade';
  stocksFinancialRatioId = null;

  constructor(rootStore) {
    this.rootStore = rootStore;
    reaction(
      () => this.stockIndex,
      () => {
        const stock = this.stocks && this.stocks[this.stockIndex];
        this.stock = stock || new CompanyStock();

        if (stock) {
          this.rootStore.generalStore.saveData(
            `${this.rootStore.philosophyStore.philosophy.id}_stock`,
            `${stock.cik}_${stock.stockIndex}`
          );
        }
      },
      { fireImmediately: true }
    );

    this.getStocksOrder();
  }

  getStocksOrder = () => {
    const stocksFilteredByOrder = localStorage.getItem('StocksFilteredByOrder');
    const stocksFilteredBy = localStorage.getItem('StocksFilteredBy');
    const stocksFinancialRatioId = localStorage.getItem('StocksFinancialRatioId');

    if (stocksFilteredByOrder) this.filteredByOrder = stocksFilteredByOrder;
    if (stocksFilteredBy) this.filteredBy = stocksFilteredBy;
    if (stocksFinancialRatioId) this.stocksFinancialRatioId = stocksFinancialRatioId;
  };

  getFixedStocks = (stocks) => {
    return stocks.map((stock) => {
      let finalGrade = 0;
      if (stock.hasOwnProperty('finalGrade')) {
        finalGrade = stock.finalGrade;
      } else if (stock.hasOwnProperty('FinalGrade')) {
        finalGrade = stock.FinalGrade;
      }

      return {
        'cik': stock.cik ? stock.cik : stock.CIK,
        'stockIndex': stock.stockIndex ? stock.stockIndex : stock.StockIndex,
        'ticker': stock.ticker ? stock.ticker : stock.Ticker,
        'companyName': stock.companyName ? stock.companyName : stock.CompanyName,
        'philosophyKey': stock.philosophyKey ? stock.philosophyKey : stock.PhilosophyKey,
        'finalGrade': finalGrade
      }
    });
  };

  processStocks = (philosophy, stocks) => {
    const fixedStocks = this.getFixedStocks(stocks);

    runInAction(() => {
      this.stocks = fixedStocks.map(
        (s) => new CompanyStock(philosophy.id, s.cik, s.stockIndex, s.ticker, s.companyName)
      );
      if (this.stocks.length) {
        const lastCik = this.rootStore.generalStore.loadData(
          `${this.rootStore.philosophyStore.philosophy.id}_stock`
        );
        const index = this.stocks.findIndex(
          (s) => `${s.cik}_${s.stockIndex}` === lastCik
        );
        if (this.stocks.length < index) {
          this.stockIndex = 0;
        } else {
          this.stockIndex = index !== -1 ? index : 0;
        }
      } else {
        this.stockIndex = -1;
      }
    });
  };

  @action getStocks = async (financialRatioId = null) => {
    const { philosophy } = this.rootStore.philosophyStore;
    if (!philosophy) {
      this.stocks = [];
      this.stockIndex = -1;
      return;
    }

    let currentFinancialRatioId = financialRatioId;
    if (financialRatioId && financialRatioId > 0) {
      localStorage.setItem('StocksFinancialRatioId', financialRatioId);
    }

    if (financialRatioId === null) {
      currentFinancialRatioId = this.stocksFinancialRatioId;
    } else if (financialRatioId === -1) {
      currentFinancialRatioId = null;
      localStorage.setItem('StocksFinancialRatioId', null);
    }

    const stocks = await APIService.getStocks(philosophy.id, this.filteredByOrder, currentFinancialRatioId);
    this.processStocks(philosophy, stocks);
  };

  @action selectStock = (index) => {
    if (index >= 0 && index < this.stocks.length) {
      this.stockIndex = index;
    }
  };

  @action getPhilosophyCompanyStocks = async (dialogAdvancedSearchData) => {
    const { philosophy } = this.rootStore.philosophyStore;
    if (!philosophy) {
      this.stocks = [];
      this.stockIndex = -1;
      return;
    }

    const stocks = await APIService.getPhilosophyCompanyStocks(philosophy.id, dialogAdvancedSearchData);
    this.processStocks(philosophy, stocks);
  };

  @action setFilteredByOrder = (filteredByOrder) => {
    this.filteredByOrder = filteredByOrder;
    localStorage.setItem('StocksFilteredByOrder', this.filteredByOrder);
  };

  @action setFilteredBy = (filteredBy) => {
    this.filteredBy = filteredBy;
    localStorage.setItem('StocksFilteredBy', this.filteredBy);
  };
}

export default SummaryStore;
