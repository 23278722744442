import { observable, computed, action, reaction, runInAction, toJS } from 'mobx';
import _uniqBy from 'lodash/uniqBy';
import APIService from '@/api';
import Philosophy from './Philosophy';
import _ from 'lodash';

class PhilosophyStore {
  @observable.shallow privileges;
  @observable.shallow financialRatios;
  @observable.shallow philosophies;
  @observable currentIndex = -1;
  @observable showTooltip;

  famousInvestorsPhilosophies = [];
  @observable philosophiesPredefined = [];

  constructor(rootStore) {
    this.rootStore = rootStore;

    reaction(
      () => this.philosophy,
      () => {
        this.resetStockStore();

        if (this.philosophy) {
          this.rootStore.generalStore.saveData(
            'philosophy',
            this.philosophy && this.philosophy.id
          );
        }
      }
    );
  }

  @computed get philosophy() {
    return this.philosophies && this.philosophies.length > 0 ? this.philosophies[this.currentIndex] : null;
  }

  @action resetAllData = () => {
    this.privileges = undefined;
    this.financialRatios = undefined;
    this.philosophies = undefined;
    this.currentIndex = -1;
  }

  @action changeTooltip = (status) => {
    this.showTooltip = status;
  }

  checkTooltipConfiguration = async (data) => {
    if (data && data.length > 0 && data[0].toolTipEnabled === 1) {
      this.showTooltip = true;
    }
  }

  @action getData = () => {
    if (this.privileges && this.privileges.length > 0) return;

    Promise.all([
      APIService.getPhilosophyPrivileges(),
      APIService.getPhilosophyCriteriaCombinations(),
      APIService.getPhilosophies(),
      APIService.getTooltipsPreferenceConfig(),
      APIService.getFamousInvestorsPhilosophies(),
      APIService.getPhilosophiesPredefined(),
    ]).then((data) => {
      runInAction(() => {
        this.privileges = data[0];
        const financialRatios = _uniqBy(data[1], 'financialRatioId').map((v) => {
          const arr1 = data[1].filter(
            (v1) => v1.financialRatioId === v.financialRatioId
          );
          const formulaTypes = _uniqBy(arr1, 'formulaTypeId').map((v1) => {
            const arr2 = arr1.filter(
              (v2) => v2.formulaTypeId === v1.formulaTypeId
            );
            return {
              id: v1.formulaTypeId,
              description: v1.formulaTypeDescription,
              noDataOptions: _uniqBy(arr2, 'noDataOptionId').map((v2) => ({
                id: v2.noDataOptionId,
                description: v2.noDataOptionDescription,
              })),
            };
          });
          return {
            id: v.financialRatioId,
            name: v.name,
            description: v.description,
            videoLink: v.videoLink,
            valueDescription: v.valueDescription,
            formulaTypes,
          };
        });
        // Sorting finantialRatios alphabetically by name
        this.financialRatios = _.sortBy(financialRatios, ['name'], ['asc']);

        this.philosophies = data[2].map(
          (philosophy) => new Philosophy(this, philosophy)
        );
        if (this.philosophies && this.philosophies.length > 0) {
          const lastId = this.rootStore.generalStore.loadData('philosophy');
          const index = this.philosophies.findIndex((p) => p && p.id === lastId);
          this.currentIndex = index !== -1 ? index : 0;
        }
        // tooltip configuration
        this.checkTooltipConfiguration(data[3]);
        // Famous Investors Philosophies
        this.famousInvestorsPhilosophies = data[4];
        // Predefined Philosophies
        this.philosophiesPredefined = data[5];
      });
    });
  };

  @action checkPhilosphy = () => {
    if (
      this.philosophy &&
      (this.philosophy.isModified)// || this.philosophy.criterion.length === 0)
    ) {
      this.rootStore.generalStore.showMessage({
        title: 'Error Message',
        message: `Would you like to save "${this.philosophy.name}" before continuing?`,
        actions: [
          {
            label: 'Save',
            action: async () => {
              this.philosophy.isModified = false;
              await this.philosophy.save();
            },
          },
          {
            label: 'Undo',
            variant: 'danger',
            action: async () => {
              // Reload the philosophies list keeping the current index
              this.getPhilosophies(this.philosophy.id);
            },
          },
          {
            label: 'Cancel',
            action: () => {
              // This action only close the modal
            },
          },
        ],
      });
      return false;
    }
    return true;
  };

  @action createPhilosophy = async () => {
    if (
      this.philosophy &&
      (this.philosophy.isModified || this.philosophy.criterion.length === 0)
    ) {
      this.rootStore.generalStore.showMessage({
        title: 'Error Message',
        message: `You need to complete "${this.philosophy.name}" before creating a new philosophy.`,
        actions: [{ label: 'Ok' }],
      });
      return;
    }

    this.rootStore.generalStore.showLoading(true);
    const philosophy = await APIService.createPhilosophy();
    this.rootStore.generalStore.showLoading(false);

    // Fill ownerAlias at the philosophy creation time
    await this.rootStore.authStore.getAlias();
    const { alias } = this.rootStore.authStore;
    let ownerAlias;
    if (alias) {
      ownerAlias = toJS(alias);
      if (ownerAlias.alias && ownerAlias.alias !== '') {
        philosophy.ownerAlias = ownerAlias.alias;
      }
    }

    if (philosophy) {
      this.philosophies.push(new Philosophy(this, philosophy));
      this.currentIndex = this.philosophies.length - 1;
    }
  };

  /**
   * This method will be called once the philosophy is changed, this will allow to reset the sorting for using Final Grade DESC by default
   */
  setDefaultStocksSorting = () => {
    this.rootStore.stockStore.setFilteredByOrder('DESC');
    this.rootStore.stockStore.setFilteredBy('Final Grade');
    localStorage.setItem('StocksFinancialRatioId', null);
  };

  @action selectPhilosophy = (index) => {
    if (this.checkPhilosphy()) {
      if (index >= 0 && index < this.philosophies.length) {
        this.currentIndex = index;

        this.setDefaultStocksSorting();
      }
    }
  };

  @action removePhilosophy = async (id) => {
    this.rootStore.generalStore.showLoading(true);
    const response = await APIService.removePhilosophy(id);
    this.rootStore.generalStore.showLoading(false);

    // Check result errors
    if (response === 'You have successfully deleted the Philosophy' || response.success) {
      this.philosophies = this.philosophies.filter((p) => p && p.id !== id);
      this.currentIndex = Math.min(
          this.currentIndex,
          this.philosophies.length - 1
      );
    }

    return response;
  };

  @action resetStockStore = () => {
    this.rootStore.stockStore.stocks = undefined;
    this.rootStore.stockStore.stockIndex = -1;
  };

  /**
   * Method for getting the philosophies, if sharedKeyValue param is passed we will selecting this philosophy
   * @param {string} sharedKeyValue
   */
  @action getPhilosophies = async (sharedKeyValue = '') => {
    const philosophies = await APIService.getPhilosophies();
    this.philosophies = philosophies.map(
      (philosophy) => new Philosophy(this, philosophy)
    );

    // Check if this call was triggered by importing philosophy
    if (sharedKeyValue !== '') {
      const index = this.philosophies.findIndex((p) => p && p.id === sharedKeyValue);
      this.currentIndex = index !== -1 ? index : 0;
    }

    this.getPhilosophiesPredefined();
  };

  @action getPhilosophiesPredefined = async () => {
    this.philosophiesPredefined = await APIService.getPhilosophiesPredefined();
  };
}

export default PhilosophyStore;
