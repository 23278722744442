import AuthStore from "./AuthStore";
import PhilosophyStore from "./PhilosophyStore";
import StockStore from "./StockStore";
import GeneralStore from "./GeneralStore";

class RootStore {
  constructor() {
    this.authStore = new AuthStore(this);
    this.philosophyStore = new PhilosophyStore(this);
    this.stockStore = new StockStore(this);
    this.generalStore = new GeneralStore(this);
  }
}

export default RootStore;
