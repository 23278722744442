import React from 'react';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { Dropdown, Spinner } from 'react-bootstrap';
import { BsThreeDotsVertical } from 'react-icons/bs';
import Loading from '@/components/Loading';
import styles from './styles.module.scss';

const getDateLabel = (date) => moment(date).format('YYYY-MM-DD');

const MsgList = ({ alias, stock }) => {
  const listRef = React.useRef();
  const [preScrollHeight, setPreScrollHeight] = React.useState();
  const data = (stock.scuttlebut || []).filter((item) => !item.deleted);

  React.useEffect(() => {
    stock.getScuttlebut();
    const timer = setInterval(() => {
      stock.getScuttlebut();
    }, 60000);
    return () => {
      clearInterval(timer);
    };
  }, [stock]);

  React.useEffect(() => {
    if (listRef.current && listRef.current.scrollHeight !== preScrollHeight) {
      const { scrollTop, clientHeight, scrollHeight } = listRef.current;
      if ((preScrollHeight || 0) - 40 < scrollTop + clientHeight) {
        listRef.current.scrollTop = scrollHeight - clientHeight;
      }
      setPreScrollHeight(scrollHeight);
    }
  }, [preScrollHeight, setPreScrollHeight, stock.scuttlebut]);

  return (
    <div ref={listRef} className={styles.list}>
      {stock.scuttlebut ? (
        data.map((item, index) => {
          let dateLabel = getDateLabel(item.date);
          if (index === 0 || dateLabel !== getDateLabel(data[index - 1].date)) {
            const today = new Date();
            if (dateLabel === getDateLabel(today)) {
              dateLabel = 'Today';
            } else {
              const yesterday = today.setDate(today.getDate() - 1);
              if (dateLabel === getDateLabel(yesterday)) {
                dateLabel = 'Yesterday';
              }
            }
          } else {
            dateLabel = '';
          }

          const itemAlias = item.shareable || item.alias;

          return (
            <div key={item.id} className={styles.msg}>
              {dateLabel && (
                <div className={styles.date}>
                  <span>{dateLabel}</span>
                </div>
              )}
              <div className={styles.content}>
                <div className={styles.title}>
                  {itemAlias && <strong>{itemAlias}</strong>}
                  <span>{moment(item.date).format('h:mm A')}</span>
                  {!item.sending && item.alias === alias && (
                    <Dropdown>
                      <Dropdown.Toggle>
                        <BsThreeDotsVertical />
                      </Dropdown.Toggle>
                      <Dropdown.Menu align='right'>
                        <Dropdown.Item
                          onClick={() => stock.removeScuttlebut(item.id)}
                        >
                          Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                  {item.sending && <Spinner animation='border' size='sm' />}
                </div>
                <div className={styles.message}>{item.message}</div>
              </div>
            </div>
          );
        })
      ) : (
        <Loading size='sm' />
      )}
    </div>
  );
};
export default compose(
  inject(({ authStore, stockStore }) => ({
    alias: authStore.alias,
    stock: stockStore.stock,
  })),
  observer
)(MsgList);
