import React, { useState, useEffect } from 'react';
import APIService from '@/api';

const Version = () => {
  const [currentVersion, setCurrentVersion] = useState(`UI v${process.env.REACT_APP_VERSION}`);
  const [apiVersion, setApiVersion] = useState('');
  const [showApiVersion, setShowApiVersion] = useState(false);

  useEffect(() => {
    const fetchAPIVersion = () => {
      if (!apiVersion) {
        APIService.getApiVersion().then(({ version }) => {
          if (version) {
            setApiVersion(version);
            const fullVersion = `${currentVersion} / API v${version}`;
            console.log(`APP version information: ${fullVersion}`);
            setCurrentVersion(fullVersion);
          } else {
            console.log(`The API version can't be retrieve at this moment`);
          }

          if (process.env.REACT_APP_SHOW_VERSION === 'Y') {
            setShowApiVersion(true);
          }
        });
      }
    };
    fetchAPIVersion();
  }, [apiVersion, currentVersion]);

  const getVersionComponent = () => {
    return showApiVersion ? <div style={{ position: 'absolute', right: '5px', top: '65px', fontSize: '12px', }}> {currentVersion} </div> : null;
  };

  return getVersionComponent();
};

export default Version;
