import React from 'react';
import { AutoSizer, List } from 'react-virtualized';
import { BiSearch } from 'react-icons/bi';
import { IoMdClose } from 'react-icons/io';
import _ from 'lodash';
import Checkbox from '@/components/Checkbox';
import styles from './styles.module.scss';

import PropTypes from 'prop-types';
import DialogUpdateFeature from '@/components/DialogUpdateFeature';

const Panel = ({ title, data, onChange, openDialogUpgrade, screeningEnabled }) => {
  const [filterText, setFilterText] = React.useState('');
  const str = filterText.toLowerCase();
  const filteredData = str
    ? data.filter((item) => item.name.toLowerCase().includes(str))
    : data;
  const selectedCount = data.filter((item) => item.checked).length;
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCheckAll = () => {
    if (openDialogUpgrade) {
      setOpenDialog(true);
      return;
    }
    if (selectedCount === data.length) {
      onChange(data.map((item) => item.name));
    } else {
      onChange([]);
    }
  };

  const handleCheckItem = (name, checked) => {
    if (openDialogUpgrade) {
      setOpenDialog(true);
      return;
    }
    const excludeItems = data
      .filter((item) => !item.checked)
      .map((item) => item.name);
    if (checked) {
      _.pull(excludeItems, name);
    } else {
      excludeItems.push(name);
    }
    onChange(excludeItems);
  };

  const handleOneCheck = (name) => {
    if (openDialogUpgrade) {
      setOpenDialog(true);
      return;
    }
    const excludeItems = data
      .filter((item) => item.name !== name)
      .map((item) => item.name);
    onChange(excludeItems);
  };

  const rowRenderer = ({ key, index, style }) => {
    const { name, checked, count } = filteredData[index];
    const getItemName = (itemName) => {
      return itemName.length > 25 ? itemName.substring(0, 25) + '...' : itemName;
    }
    return (
      <div key={key} className={styles.item} style={style}>
        <Checkbox
          color='#ff8f00'
          label={getItemName(name)}
          checked={checked}
          onChange={(c) => handleCheckItem(name, c)}
        />
        <span className={styles.count}>{Number(count).toLocaleString()}</span>
        <div className={styles.onlyButton} onClick={() => handleOneCheck(name)}>
          ONLY
        </div>
      </div>
    );
  };

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <Checkbox
          color='#f0e5d6'
          label={`${title}(${selectedCount})`}
          checked={selectedCount !== 0}
          middle={selectedCount !== data.length}
          onChange={handleCheckAll}
        />
        <span>Companies</span>
      </div>
      <div className={styles.searchBar}>
        <input
          placeholder='Type to search'
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
        />
        <BiSearch className='search' />
        {filterText && (
          <IoMdClose className='clear' onClick={() => setFilterText('')} />
        )}
      </div>
      <div className={styles.list}>
        <AutoSizer>
          {({ width, height }) => (
            <List
              width={width}
              height={height}
              rowHeight={38}
              rowCount={filteredData.length}
              rowRenderer={rowRenderer}
            />
          )}
        </AutoSizer>
      </div>
      <DialogUpdateFeature
        sharedKeyEnabledValue={screeningEnabled}
        openDialogUpgrade={openDialog}
        handleCloseDialogUpgrade={handleCloseDialog}
      />
    </div>
  );
};

Panel.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  onChange: PropTypes.func,
  openDialogUpgrade: PropTypes.bool,
  screeningEnabled: PropTypes.number
};

// Specifies the default values for props:
Panel.defaultProps = {
  openDialogUpgrade: false
};

export default Panel;
