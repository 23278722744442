import React from 'react';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { checkSharedKeyEnabledValue } from '@/utils';

import Panel from '../Panel';

const IndustryPanel = ({ philosophyStore: store }) => {
  const { industries, changeIndustries } = store.philosophy;
  const [industryScreeningEnabled, setIndustryScreeningEnabled] = React.useState(0);
  const [openDialogUpgrade, setOpenDialogUpgrade] = React.useState(false);

  React.useEffect(() => {
    const { userPreferences } = store.rootStore.generalStore;
    // Check if the user has granted industry Screening
    if (userPreferences && userPreferences[0]) {
      setIndustryScreeningEnabled(userPreferences[0].industryScreeningEnabled);
      if (!checkSharedKeyEnabledValue(userPreferences[0].industryScreeningEnabled)) {
        setOpenDialogUpgrade(true);
      }
    }
  }, [store.rootStore.generalStore]);

  return (
    <Panel title='Industry' data={industries} onChange={changeIndustries} openDialogUpgrade={openDialogUpgrade} screeningEnabled={industryScreeningEnabled} />
  );
};

export default compose(inject('philosophyStore'), observer)(IndustryPanel);
