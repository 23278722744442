import React from 'react';
import PhilosophyTabGuide from "@/images/1-Philosophy-tab-watch-video-guide.png";
import PhilosophyButtonGuide from "@/images/2-Click-New-Philosophy-Button.png";
import PhilosophyAddCriteriaGuide from "@/images/3-Add-Criteria.png";
import PhilosophySelectRatioGuide from "@/images/4-Select-Ratio.png";
import PhilosophySaveGuide from "@/images/5-Save.png";
import PhilosophySummaryTabGuide from "@/images/6-Click-Summary-Tab.png";
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';

const FirstTimeLoginWelcomeDiv = ({ show, firstLoginStep, exitOrientation }) => {
  const getImage = () => {
    switch (firstLoginStep) {
      case 1:
        return <img style={{ maxWidth: "40rem" }} src={PhilosophyTabGuide} alt="IPS" />;
      case 2:
        return <img style={{ maxWidth: "40rem" }} src={PhilosophyButtonGuide} alt="IPS" />;
      case 3:
        return <img style={{ maxWidth: "40rem" }} src={PhilosophyAddCriteriaGuide} alt="IPS" />;
      case 4:
        return <img style={{ maxWidth: "40rem" }} src={PhilosophySelectRatioGuide} alt="IPS" />;
      case 5:
        return <img style={{ maxWidth: "40rem" }} src={PhilosophySaveGuide} alt="IPS" />;
      case 6:
        return <img style={{ maxWidth: "40rem" }} src={PhilosophySummaryTabGuide} alt="IPS" />;
      default:
        return <></>
    }
  };

  const getTop = () => {
    switch (firstLoginStep) {
      case 1:
        return '-1em';
      case 2:
        return '-5em';
      case 3:
        return '1em';
      case 4:
        return '-1em';
      case 5:
        return '-5em';
      case 6:
        return '-1em';
      default:
        return '';
    }
  };

  const getLeft = () => {
    switch (firstLoginStep) {
      case 1:
        return '5em';
      case 2:
        return '7em';
      case 3:
        return '14em';
      case 4:
        return '48em';
      case 5:
        return '13em';
      case 6:
        return '15em';
      default:
        return '';
    }
  };

  const handleClose = () => {
    exitOrientation();
  };

  return (
    <>
      {
        show &&
        <div style={{ width: 'fit-content', position: 'absolute', top: getTop(), left: getLeft(), zIndex: '1' }}>
          {getImage()}
        </div>
      }
      {
        show &&
        <Snackbar
          open={true}
          message={`Item ${firstLoginStep} of 6`}
          action={<><Button color="secondary" size="small" onClick={handleClose}>Exit Orientation</Button></>}
        >
        </Snackbar>
      }
    </>
  );
}

export default FirstTimeLoginWelcomeDiv;
