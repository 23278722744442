import React from 'react';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { checkSharedKeyEnabledValue } from '@/utils';

import Panel from '../Panel';

const CountryPanel = ({ philosophyStore: store }) => {
  const { countries, changeCountries } = store.philosophy;
  const [countryScreeningEnabled, setCountryScreeningEnabled] = React.useState(0);
  const [openDialogUpgrade, setOpenDialogUpgrade] = React.useState(false);

  React.useEffect(() => {
    const { userPreferences } = store.rootStore.generalStore;
    // Check if the user has granted country Screening
    if (userPreferences && userPreferences[0]) {
      setCountryScreeningEnabled(userPreferences[0].countryScreeningEnabled);
      if (!checkSharedKeyEnabledValue(userPreferences[0].countryScreeningEnabled)) {
        setOpenDialogUpgrade(true);
      }
    }
  }, [store.rootStore.generalStore]);

  return <Panel title='Country' data={countries} onChange={changeCountries} openDialogUpgrade={openDialogUpgrade} screeningEnabled={countryScreeningEnabled} />;
};

export default compose(inject('philosophyStore'), observer)(CountryPanel);
