import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

const DialogNonOwnerUpdatingPhilosophy = ({ open, handleClose, keepOriginalUpdate }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <span style={{ color: 'black' }}>
            You are attempting to modify a read only philosophy. Would you like to also keep a copy of the original version?
          </span>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={() => keepOriginalUpdate(false)} color="primary">
          No
        </Button>
        <Button onClick={() => keepOriginalUpdate(true)} color="primary">
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogNonOwnerUpdatingPhilosophy;
