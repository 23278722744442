import React, { useState, useEffect } from 'react';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import {
  Form,
  Tabs,
  Tab,
  Button,
  Spinner,
  Modal,
} from 'react-bootstrap';
import { BsFillXSquareFill } from 'react-icons/bs';

import Navigation from '@/components/Navigation';
import Philosophy from './Philosophy';
import Screening from './Screening';
import styles from './styles.module.scss';

import DialogImportSharedKey from '@/components/DialogImportSharedKey';
import DialogUpdateFeature from '@/components/DialogUpdateFeature';
import DialogNonOwnerCloningPhilosophy from "@/components/DialogNonOwnerCloningPhilosophy";
import DialogNonOwnerUpdatingPhilosophy from "@/components/DialogNonOwnerUpdatingPhilosophy";
import NestedMenu from "@/components/NestedMenu";
import FirstTimeLoginWelcomeDiv from "@/components/FirstTimeLoginWelcomeDiv";
import APIService from '@/api';
import { getAliasFromEmail } from '@/utils';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { checkSharedKeyEnabledValue, TYPE_IMPORT, TYPE_OWNER } from '@/utils';

import TooltipItem from '@/components/TooltipItem';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const InvestmentPhilosophy = ({ firstLogin, firstLoginStep, handleFirstLoginStep, exitOrientation, philosophyStore: store }) => {
  const [visibleDeleteModal, showDeleteModal] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogUpgrade, setOpenDialogUpgrade] = useState(false);
  const [openDialogClone, setOpenDialogClone] = useState(false);
  const [openDialogUpdate, setOpenDialogUpdate] = useState(false);
  const [sharedKeyValue, setSharedKeyValue] = useState('');
  const [sharedKeyValueStatus, setSharedKeyValueStatus] = useState(false);
  const [sharedKeyHelperText, setSharedKeyHelperText] = useState('');
  const [sharedKeyEnabledValue, setSharedKeyEnabledValue] = useState(0);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [messageSnackbar, setMessageSnackbar] = useState('');
  const [severitySnackbar, setSeveritySnackbar] = useState('success');
  const [activeKeyTab, setActiveKeyTab] = useState('philosophy');
  const [showTooltip, setShowTooltip] = React.useState(false);
  const [insufficientSpace, setInsufficientSpace] = React.useState(false);
  const [tooltips, setTooltips] = React.useState([]);

  const { privileges, philosophies, currentIndex, philosophy, getPhilosophies, famousInvestorsPhilosophies, philosophiesPredefined } = store;

  React.useEffect(() => {
    let pageTitle = "Market Clue";
    // This will run when the page first loads
    if (philosophy && philosophy.name) {
      pageTitle = `${philosophy.name} – ${pageTitle}`;
    }

    document.title = pageTitle;
  });

  useEffect(() => {
    const getTooltipData = async () => {
      if (tooltips.length > 0) return;

      let tooltipData = await store.rootStore.generalStore.getTooltipData();
      if (tooltipData && tooltipData[0]) {
        setTooltips(tooltipData);
      }
    };

    // We need to wait until the toolTip Config is loaded
    setTimeout(() => {
      // Check if the user has the showTooltip option enable
      if (store && store.showTooltip && tooltips.length === 0) {
        setShowTooltip(true);
        getTooltipData();
      }
    }, 2000);
  }, [store, tooltips]);

  const handleChangeValue = (e) => {
    const { name, value } = e.target;
    philosophy.update(name, name === 'name' ? value : Number(value));
  };

  const handleDelete = async () => {
    showDeleteModal(false);
    const result = await store.removePhilosophy(philosophy.id);
    // Check result errors
    if (result === 'You have successfully deleted the Philosophy') {
      setSeveritySnackbar('success');
      store.getPhilosophiesPredefined();
    } else {
      setSeveritySnackbar('error');
    }

    // Check response to display the Snackbar
    if (typeof result === 'string') {
      setMessageSnackbar(result);
      setOpenSnackbar(true);
    }
  };

  const handleClickOpen = () => {
    const { userPreferences } = store.rootStore.generalStore;
    // Check if the user has granted importing shared keys
    if (userPreferences && userPreferences[0]) {
      setSharedKeyEnabledValue(userPreferences[0].sharedKeyEnabled);
      if (checkSharedKeyEnabledValue(userPreferences[0].sharedKeyEnabled)) {
        setOpenDialog(true);
      } else {
        setOpenDialogUpgrade(true);
      }
    }
  };

  const handleFamousInvestorPhilosophyImporting = (philosophyId) => {
    const { userPreferences } = store.rootStore.generalStore;
    // Check if the user has granted importing shared keys
    if (userPreferences && userPreferences[0]) {
      setSharedKeyEnabledValue(userPreferences[0].sharedKeyEnabled);
      if (checkSharedKeyEnabledValue(userPreferences[0].sharedKeyEnabled)) {
        // Call directly philosophy import method
        importSharedPhilosopyKey(philosophyId, true);
      } else {
        setOpenDialogUpgrade(true);
      }
    }
  };

  const handlePredefinedPhilosophiesImporting = (philosophyId) => {
    // Call directly philosophy import method
    importSharedPhilosopyKey(philosophyId, true);
  };

  const handleClose = () => {
    setOpenDialog(false);
    setSharedKeyValue('');
    setSharedKeyValueStatus(false);
    setSharedKeyHelperText('');
  };

  const handleCloseDialogUpgrade = () => {
    setOpenDialogUpgrade(false);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const undoPhilosophyChanges = () => {
    // Refresh the philosophies list keeping current philosophy index
    getPhilosophies(philosophy.id);
  };

  const importSharedPhilosopyKey = (philosophyId = '', importingFamous = false) => {
    philosophyId = philosophyId && philosophyId !== '' ? philosophyId : sharedKeyValue;
    APIService.importSharedPhilosophyKey(philosophyId).then(result => {
      // Refresh the philosophies list
      getPhilosophies(philosophyId);
      // Close the dialog and refresh the values
      handleClose();
    }).catch(error => {
      console.log(`error: ${error}`);

      if (importingFamous) {
        // Check response to display the Snackbar
        if (typeof error === 'string' && error.indexOf('Please upgrade your account and try again') !== -1) {
          // Check if the user has maxed up his criteria count
          if (error.indexOf('You do not have enough space in your account to load this philosophy') !== -1) {
            setInsufficientSpace(true);
          }
          setOpenDialogUpgrade(true);
        } else {
          setMessageSnackbar(error);
          setSeveritySnackbar('error');
          setOpenSnackbar(true);
        }
      } else {
        // In case of a invalid shared key
        setSharedKeyValueStatus(true);
        setSharedKeyHelperText(error);
      }
    });
  };

  const handleFamousInvestorsClickOpen = () => {
    const { userPreferences } = store.rootStore.generalStore;
    // Check if the user has granted importing shared keys
    if (userPreferences && userPreferences[0]) {
      setSharedKeyEnabledValue(userPreferences[0].famousInvestorsEnabled);
      if (checkSharedKeyEnabledValue(userPreferences[0].famousInvestorsEnabled)) {
        // Do something when the famousInvestorsEnabled configuration option is enable, for now, do nothing
        return true;
      } else {
        setOpenDialogUpgrade(true);
      }
    }
    return false;
  };

  const handleCloneClickOpen = () => {
    const { philosophyType } = philosophy;

    // If the user has imported the philosophy shows the dialog for confirming
    if (philosophyType === TYPE_IMPORT) {
      setOpenDialogClone(true);
    } else {
      clonePhilosophy();
    }
  };

  const handleSavePhilosophy = () => {
    handleFirstLoginStep(6);
    // If the user has imported the philosophy and the philosophy privilege is Read Only
    if (philosophy.philosophyType === TYPE_IMPORT && philosophy.privilege === 2) {
      return setOpenDialogUpdate(true);
    }
    philosophy.save();
  };

  const clonePhilosophy = (keepOriginal = true) => {
    APIService.clonePhilosophy(philosophy.id, keepOriginal).then(result => {
      if (result && result[0]) {
        const { NewPhilosophyKey } = result[0];
        // Refresh the philosophies list
        getPhilosophies(NewPhilosophyKey);
      }
    }).catch(error => {
      console.error(error);
    });
  };

  const handleCloseClone = () => {
    setOpenDialogClone(false);
  };

  const handleCloseUpdate = () => {
    setOpenDialogUpdate(false);
  };

  const handleKeepOriginalClone = (value) => {
    handleCloseClone();
    clonePhilosophy(value);
  };

  const handleKeepOriginalUpdate = (value) => {
    handleCloseUpdate();
    philosophy.save(value);
  };

  const isPhilosophyOwner = () => {
    if (philosophy && philosophy.philosophyType === undefined) return true;
    return philosophy?.philosophyType === TYPE_OWNER;
  };

  if (!philosophies) {
    return (
      <div className={styles.loading}>
        <Spinner animation='border' />
      </div>
    );
  }

  const _philosophy = philosophy || {};

  // By default the philosophy privilege is "Do Not Share"
  if (!_philosophy.privilege) {
    _philosophy.privilege = 1;
  }

  const getTooltipItem = (component) => {
    const found = tooltips.filter(item => item.uITopicName === component);
    return found.length > 0 ? found[0] : null;
  };

  const hideTooltips = async () => {
    await APIService.setTooltipsPreferenceConfig(false);
    store.changeTooltip(false);
    setShowTooltip(false);
  };

  const getTabTitle = (tabTitle) => {
    const capitalizedTabTitle = tabTitle.charAt(0).toUpperCase() + tabTitle.slice(1);
    const tooltipItemName = tabTitle === 'philosophy' ? 'Philosophy.PhilosophyTab' : 'Screening.ScreeningTab';
    return tabTitle === activeKeyTab ? <TooltipItem styles={{ 'cursor': 'help' }} label={capitalizedTabTitle} item={showTooltip ? getTooltipItem(tooltipItemName) : null} hideTooltips={hideTooltips} /> : capitalizedTabTitle;
  };

  const getOwnerAlias = () => {
    const { email } = store.rootStore.authStore;
    return philosophy.ownerAlias ? philosophy.ownerAlias : getAliasFromEmail(email);
  };

  const handleCreatePhilosophyClick = () => {
    // Handle google conversion support
    window.gtag('event', 'conversion', {'send_to': 'AW-10857170496/_lYZCJC9waoDEMCMjbko'});
    handleFirstLoginStep(3);
    store.createPhilosophy();
  };

  const handleAddCriteriaClick = () => {
    handleFirstLoginStep(4);
  };

  const handleSelectRatioClick = () => {
    handleFirstLoginStep(5);
  };

  return (
    <>
      <div className={styles.header}>
        <FirstTimeLoginWelcomeDiv show={firstLogin} firstLoginStep={firstLoginStep} exitOrientation={exitOrientation} />
        <div className={styles.buttons}>
          <NestedMenu
            famousInvestorsPhilosophies={famousInvestorsPhilosophies}
            philosophiesPredefined={philosophiesPredefined}
            createPhilosophyItem={handleCreatePhilosophyClick}
            sharedKeyItem={handleClickOpen}
            famousInvestorsItem={handleFamousInvestorsClickOpen}
            cloneItem={handleCloneClickOpen}
            handleFamousInvestorPhilosophyImporting={handleFamousInvestorPhilosophyImporting}
            handlePredefinedPhilosophiesImporting={handlePredefinedPhilosophiesImporting}
          />
          {
            philosophy &&
            (<Button disabled={!philosophy.isModified} onClick={handleSavePhilosophy}>Save</Button>)
          }
          {
            philosophy &&
            (philosophy.isModified && <Button variant="danger" onClick={undoPhilosophyChanges}>Undo</Button>)
          }
        </div>
        <Form
          inline
          noValidate
          className={styles.form}
          style={{
            pointerEvents: philosophy ? 'initial' : 'none',
          }}
        >
          <Navigation
            current={currentIndex}
            total={philosophies.length}
            onChange={store.selectPhilosophy}
          />
          <Form.Label className='mr-2'>Philosophy Key</Form.Label>
          <Form.Control
            className='mr-sm-4'
            readOnly
            value={_philosophy && _philosophy.id ? _philosophy.id : ''}
            style={{ width: '21rem', fontSize: '.87rem', fontFamily: 'Monospace' }}
            onClick={({ target } = {}) => target && target.select && target.select()}
          />
          <Form.Label className='mr-2'>Philosophy Name</Form.Label>
          <Form.Control
            className='mr-sm-4 flex-fill'
            name='name'
            value={_philosophy && _philosophy.name ? _philosophy.name : ''}
            onChange={handleChangeValue}
          />
          <Form.Label className='mr-2'>Owner</Form.Label>
          <Form.Control
            className='mr-sm-4'
            name='owner'
            value={philosophy && getOwnerAlias()}
            readOnly
          />
          <Form.Label className='mr-2'>
            <TooltipItem styles={{ 'cursor': 'help' }} label={'Sharing Privileges'} item={showTooltip ? getTooltipItem('Philosophy.SharingPrivileges') : null} hideTooltips={hideTooltips} />
          </Form.Label>
          <Form.Control
            as='select'
            className='mr-sm-4'
            name='privilege'
            value={_philosophy.privilege}
            onChange={handleChangeValue}
            disabled={!isPhilosophyOwner()}
            style={{ cursor: !isPhilosophyOwner() ? 'not-allowed' : 'default' }}
          >
            {privileges.map((privilege) => (
              <option key={privilege.id} value={privilege.id}>
                {privilege.description}
              </option>
            ))}
          </Form.Control>
        </Form>
        {philosophy && (
          <BsFillXSquareFill
            className={styles.closeIcon}
            onClick={() => showDeleteModal(true)}
            title='Delete philosophy'
          />
        )}

        <DialogImportSharedKey
          open={openDialog}
          handleClose={handleClose}
          onImportSharedKey={importSharedPhilosopyKey}
          sharedKeyValue={sharedKeyValue}
          setSharedKeyValue={setSharedKeyValue}
          sharedKeyValueStatus={sharedKeyValueStatus}
          sharedKeyHelperText={sharedKeyHelperText}
        />

        <DialogUpdateFeature
          sharedKeyEnabledValue={sharedKeyEnabledValue}
          openDialogUpgrade={openDialogUpgrade}
          handleCloseDialogUpgrade={handleCloseDialogUpgrade}
          insufficientSpace={insufficientSpace}
        />

        <DialogNonOwnerCloningPhilosophy open={openDialogClone} handleClose={handleCloseClone} keepOriginalClone={handleKeepOriginalClone} />

        <DialogNonOwnerUpdatingPhilosophy open={openDialogUpdate} handleClose={handleCloseUpdate} keepOriginalUpdate={handleKeepOriginalUpdate} />
      </div>

      <div className={styles.contents}>
        <Tabs
          activeKey={activeKeyTab}
          onSelect={setActiveKeyTab}
          defaultActiveKey='philosophy'
          transition={false}
          mountOnEnter
          unmountOnExit
        >
          <Tab eventKey='philosophy' title={getTabTitle('philosophy')}>
            {philosophy && <Philosophy handleAddCriteriaClick={handleAddCriteriaClick} handleSelectRatioClick={handleSelectRatioClick} />}
          </Tab>
          <Tab eventKey='screening' title={getTabTitle('screening')}>
            <Screening />
          </Tab>
        </Tabs>
      </div>

      <Modal show={visibleDeleteModal} onHide={() => showDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete philosophy?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete "{_philosophy.name}"?
        </Modal.Body>
        <Modal.Footer>
          <Button variant='danger' onClick={handleDelete}>
            Delete
          </Button>
          <Button variant='secondary' onClick={() => showDeleteModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={severitySnackbar}>
          {messageSnackbar}
        </Alert>
      </Snackbar>
    </>
  );
};

export default compose(
  inject('philosophyStore'),
  observer
)(InvestmentPhilosophy);
