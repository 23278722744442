import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import DialogGoodFaithDisclaimer from "@/components/DialogGoodFaithDisclaimer";
import './styles.scss';

const NestedMenu = ({
    famousInvestorsPhilosophies,
    philosophiesPredefined,
    createPhilosophyItem,
    sharedKeyItem,
    famousInvestorsItem,
    cloneItem,
    handleFamousInvestorPhilosophyImporting,
    handlePredefinedPhilosophiesImporting,
  }) => {
  const [open, setOpen] = useState(false);
  const [openFamousInvestors, setOpenFamousInvestors] = useState(false);
  const [openPredefinedPhilosophies, setOpenPredefinedPhilosophies] = useState(false);
  const [openDialogDisclaimer, setOpenDialogDisclaimer] = useState(false);
  const [selectedPhilosophyId, setSelectedPhilosophyId] = useState('');

  const handleMenuToogle = (isOpen, event, metadata) => {
    if (metadata && metadata.source === "click") setOpen(true);
    if (metadata && !metadata.source) setOpen(false);
  };

  const handleFamousInvestorsClick = () => {
    if (!openFamousInvestors) {
      const result = famousInvestorsItem();
      setOpenFamousInvestors(result);
    } else {
      setOpenFamousInvestors(false);
    }
  };

  const handlePhilosophyClick = () => {
    setOpen(false);
    createPhilosophyItem();
  };

  const handleSharedKeyClick = () => {
    setOpen(false);
    sharedKeyItem();
  };

  const handleCloneClick = () => {
    setOpen(false);
    cloneItem();
  };

  const handleFamousInvestorClick = (philosophyId) => {
    setOpen(false);

    // Check if the disclaimer agreement was accepted by the user
    const disclaimer = localStorage.getItem('goodfaithdisclaimer');
    if (!disclaimer) {
      setSelectedPhilosophyId(philosophyId);
      setOpenDialogDisclaimer(true);
    } else {
      handleFamousInvestorPhilosophyImporting(philosophyId);
    }
  };

  const handleAcceptDisclaimer = () => {
    setOpenDialogDisclaimer(false);
    localStorage.setItem('goodfaithdisclaimer', true);
    handleFamousInvestorPhilosophyImporting(selectedPhilosophyId);
  };

  const handlePredefinedPhilosophiesClick = () => {
    if (!openPredefinedPhilosophies) {
      setOpenPredefinedPhilosophies(true);
    } else {
      setOpenPredefinedPhilosophies(false);
    }
  };

  const handlePredefinedPhilosophyClick = (philosophyId) => {
    setOpen(false);
    handlePredefinedPhilosophiesImporting(philosophyId);
  };

  const getCriterionHeader = (item) => {
    return item.criteriaCount === 1 ? '=======> 1 Criterion <=======' : `=======> ${item.criteriaCount} Criteria <=======`;
  };

  const getPhilosophiesPredefinedItems = () => {
    if (philosophiesPredefined.length === 0) return null;

    return philosophiesPredefined.map((item, index) => {
      if (index === 0) return <div key={`${index}-${index}`}><Dropdown.Header key={index}>{getCriterionHeader(item)}</Dropdown.Header><Dropdown.Item key={item.philosophyKey} disabled={!item.enable} onClick={() => handlePredefinedPhilosophyClick(item.philosophyKey)}>{item.name}</Dropdown.Item></div>;
      // Check if a new criteria item is being returned
      if (item.criteriaCount !== philosophiesPredefined[index - 1].criteriaCount) {
        return <div key={`${index}-${index}`}><Dropdown.Divider /><Dropdown.Header key={index}>{getCriterionHeader(item)}</Dropdown.Header><Dropdown.Item key={item.philosophyKey} disabled={!item.enable} onClick={() => handlePredefinedPhilosophyClick(item.philosophyKey)}>{item.name}</Dropdown.Item></div>;
      } else {
        return <Dropdown.Item key={item.philosophyKey} disabled={!item.enable} onClick={() => handlePredefinedPhilosophyClick(item.philosophyKey)}>{item.name}</Dropdown.Item>;
      }
    });
  };

  return (
    <>
      <Dropdown className="d-inline mx-2" show={open} onToggle={handleMenuToogle}>
        <Dropdown.Toggle id="dropdown-autoclose-false">
          New
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={handlePhilosophyClick}>Philosophy</Dropdown.Item>
          <Dropdown.Item onClick={handleSharedKeyClick}>Shared Key</Dropdown.Item>
          <Dropdown className="split-button" onClick={handleFamousInvestorsClick} show={openFamousInvestors} drop={'right'}>
            <Dropdown.Toggle id="dropdown-autoclose-false-famous">
              Famous Investors
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {
                famousInvestorsPhilosophies && famousInvestorsPhilosophies.map(item => <Dropdown.Item key={item.id} onClick={() => handleFamousInvestorClick(item.id)}>{item.name}</Dropdown.Item>)
              }
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown className="split-button" onClick={handlePredefinedPhilosophiesClick} show={openPredefinedPhilosophies} drop={'right'}>
            <Dropdown.Toggle id="dropdown-autoclose-false-famous">
              Predefined Philosophy
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {
                philosophiesPredefined && getPhilosophiesPredefinedItems()
              }
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown.Item onClick={handleCloneClick}>Clone</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <DialogGoodFaithDisclaimer open={openDialogDisclaimer} handleClose={setOpenDialogDisclaimer} handleAcceptDisclaimer={handleAcceptDisclaimer} />
    </>
  )
}

export default NestedMenu;
