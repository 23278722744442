import React from 'react';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { Row, Col, Form } from 'react-bootstrap';
import Loading from '@/components/Loading';
import styles from './styles.module.scss';

const Company = ({ stockStore }) => {
  const { stock } = stockStore;

  React.useEffect(() => {
    stock.getProfile();
  }, [stock]);

  if (!stock.profile) {
    return stock.cik ? <Loading /> : null;
  }

  return (
    <div className='px-4'>
      <Row className='mb-3'>
        <Col xs={8} className={styles.row}>
          <Form.Label className='mr-2'>Sector</Form.Label>
          <Form.Control
            className='mr-4'
            disabled
            value={stock.profile.sector ? stock.profile.sector : ''}
            style={{ width: '200px' }}
          />
          <Form.Label className='mr-2'>Industry</Form.Label>
          <Form.Control
            disabled
            value={stock.profile.industry ? stock.profile.industry : ''}
            style={{ flex: 1 }}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={8} className={styles.row}>
          <Form.Label className='mr-2'>Profile</Form.Label>
          <Form.Control
            disabled
            as='textarea'
            style={{ resize: 'none' }}
            value={stock.profile.profile ? stock.profile.profile : ''}
            className='flex-fill'
          />
        </Col>
        <Col xs={4} className={styles.rightColumn}>
          <div className={styles.row}>
            <Form.Label className='mr-2'>Stock Exchange</Form.Label>
            <Form.Control
              disabled
              value={stock.profile.stockExchange ? stock.profile.stockExchange : ''}
              style={{ width: '100px' }}
            />
          </div>
          <div className={styles.row}>
            <Form.Label className='mr-2'>Market Cap</Form.Label>
            <Form.Control
              disabled
              value={stock.profile.marketCap ? stock.profile.marketCap : ''}
              style={{ width: '100px' }}
            />
          </div>
          <div className={styles.row}>
            <Form.Label className='mr-2'>Company Website</Form.Label>
            <Form.Control disabled value={stock.profile.companyWebsite ? stock.profile.companyWebsite : ''} />
          </div>
          <div className={styles.row}>
            <Form.Label className='mr-2'>Address</Form.Label>
            <Form.Control disabled value={stock.profile.address ? stock.profile.address : ''} />
          </div>
          <div className={styles.row}>
            <Form.Label className='mr-2' />
            <Form.Control disabled value={stock.profile.address2 ? stock.profile.address2 : ''} />
          </div>
          <div className={styles.row}>
            <Form.Label className='mr-2'>City</Form.Label>
            <Form.Control disabled value={stock.profile.city ? stock.profile.city : ''} />
          </div>
          <div className={styles.row}>
            <Form.Label className='mr-2'>State</Form.Label>
            <Form.Control disabled value={stock.profile.state ? stock.profile.state : ''} />
          </div>
          <div className={styles.row}>
            <Form.Label className='mr-2'>Zip</Form.Label>
            <Form.Control disabled value={stock.profile.zip ? stock.profile.zip : ''} />
          </div>
          <div className={styles.row}>
            <Form.Label className='mr-2'>Country</Form.Label>
            <Form.Control disabled value={stock.profile.country ? stock.profile.country : ''} />
          </div>
          <div className={styles.row}>
            <Form.Label className='mr-2'>Phone</Form.Label>
            <Form.Control disabled value={stock.profile.phone ? stock.profile.phone : ''} />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default compose(inject('stockStore'), observer)(Company);
