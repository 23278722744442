import React, { useState, useEffect, useRef } from 'react';
import { toJS } from 'mobx';
import _ from 'lodash';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import styles from './styles.module.scss';
import { Form } from 'react-bootstrap';

import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const TICKER = 'ticker';
const COMPANY_NAME = 'companyName';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#BFDEFF'
    }
  }
});

const AutocompleteInput = ({ stocks, stock, current, onChange, elementToShow, showAutocompleteInput }) => {
  const [autocompleteItems, setAutocompleteItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState({ title: '' });
  const [inputValue, setInputValue] = useState('');
  const [displayInput, setDisplayInput] = useState(true);
  const [displayAutocomplete, setDisplayAutocomplete] = useState('none');
  const [open, setOpen] = useState(false);
  const [currentDisplayValue, setCurrentDisplayValue] = useState('');
  const textInput = useRef();

  const onAutocompleteChange = (event, value) => {
    onChange(getStockIndex(value));
  }

  const getStockIndex = (stockName) => {
    if (elementToShow === COMPANY_NAME) {
      const splitedName = stockName.split(' - ');
      stockName = splitedName.length > 0 ? splitedName[0] : stockName;
    }

    // Getting array value from observable
    const clonedStocks = toJS(stocks)
    return clonedStocks.findIndex(item => item.tickerTest === stockName);
  }

  useEffect(() => {
    const getAutocompleteItems = () => {
      // Getting array value from observable
      const clonedStocks = toJS(stocks);

      // Order stocks by ticker
      const sortedClonedStocks = _.orderBy(clonedStocks, ['tickerTest'], ['asc']);

      // Current item index after sorting
      const itemIndex = sortedClonedStocks.findIndex(item => item.cik === clonedStocks[current].cik && item.tickerTest === clonedStocks[current].tickerTest);

      // Comparing the autocomplete elements with the ones being filtered
      const elementsLength = autocompleteItems.length === sortedClonedStocks.length;

      const items = (autocompleteItems.length > 0 && elementsLength) ? autocompleteItems : sortedClonedStocks.map((item) => {
        let title = item[elementToShow] ? item[elementToShow] : item[`${elementToShow}Test`];
        if (elementToShow === COMPANY_NAME) {
          title = `${item.tickerTest} - ${title}`;
        }
        return { title: title }
      });
      setAutocompleteItems(items);
      setSelectedItem(items[itemIndex]);
      setCurrentDisplayValue(items[itemIndex].title)
    };

    getAutocompleteItems();
  }, [autocompleteItems, current, elementToShow, stocks]);

  const getOptionLabel = (option) => {
    switch (typeof option) {
      case 'string':
        return option;
      case 'object':
        return option.title ? option.title : '';
      default:
        return '';
    }
  }

  // Select all when user click the input
  const handleOnClick = (e) => {
    e.target.select();
  }

  // On FocusOut
  const handleOnBlur = (e) => {
    // Check if an invalid value has been selected
    if (e.target.value !== selectedItem.title) {
      setSelectedItem({ title: currentDisplayValue });
    }

    if (elementToShow === COMPANY_NAME) {
      setDisplayInput(true);
      setDisplayAutocomplete('none');
      setOpen(false);
    }
  }

  // On key press, this will handle enter key pressed
  const handleKeyPress = (e) => {
    // User press enter key
    if (e.charCode === 13) {
      return handleOnBlur(e);
    }
  }

  // Custom filter for matching the Tickers that start with the entered term
  const filterOptions = createFilterOptions({
    matchFrom: elementToShow === TICKER ? 'start' : 'any',
    stringify: option => getOptionLabel(option)
  });

  const handleInputDisabledClick = () => {
    setDisplayInput(false);
    setDisplayAutocomplete('block');

    setTimeout(() => {
      textInput.current.focus();
      textInput.current.click();

      setOpen(true);
    }, 1);
  };

  const onInputChangeHandler = (event, newInputValue) => {
    if (elementToShow === TICKER) {
      newInputValue = newInputValue.toUpperCase();
    }
    setInputValue(newInputValue);
  };

  return (
    <ThemeProvider theme={theme}>
      <>
        {
          showAutocompleteInput &&
          <Autocomplete
            classes={{ root: styles.root, fullWidth: false }}
            freeSolo
            disableClearable
            blurOnSelect
            options={autocompleteItems.map((option) => option.title)}
            renderInput={(params) => (
              <TextField
                style={{ width: '100%' }}
                {...params}
                margin="normal"
                variant="outlined"
                onClick={handleOnClick}
                onBlur={handleOnBlur}
                onKeyPress={handleKeyPress}
              />
            )}
            value={selectedItem}
            getOptionLabel={(option) => getOptionLabel(option)}
            onChange={onAutocompleteChange}
            filterOptions={filterOptions}
            inputValue={inputValue}
            onInputChange={onInputChangeHandler}
          />
        }
        {
          !showAutocompleteInput && displayInput && elementToShow === COMPANY_NAME &&
          <Form.Control
            className='flex-fill'
            style={{ width: '100%' }}
            value={stock.companyNameTest}
            readOnly
            onClick={() => { handleInputDisabledClick() }}
          />
        }
        {
          !showAutocompleteInput && elementToShow === COMPANY_NAME &&
          <Autocomplete
            open={open}
            classes={{ root: styles.root, fullWidth: false }}
            style={{ display: displayAutocomplete }}
            freeSolo
            disableClearable
            blurOnSelect
            options={autocompleteItems.map((option) => option.title)}
            renderInput={(params) => (
              <TextField
                inputRef={textInput}
                style={{ width: '100%' }}
                {...params}
                margin="normal"
                variant="outlined"
                onClick={handleOnClick}
                onBlur={handleOnBlur}
                onKeyPress={handleKeyPress}
              />
            )}
            value={selectedItem}
            getOptionLabel={(option) => getOptionLabel(option)}
            onChange={onAutocompleteChange}
            filterOptions={filterOptions}
            inputValue={inputValue}
            onInputChange={onInputChangeHandler}
          />
        }
      </>
    </ThemeProvider>
  )
}

export default AutocompleteInput;
