import React from 'react';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { Spinner } from 'react-bootstrap';

import SectorPanel from './SectorPanel';
import IndustryPanel from './IndustryPanel';
import CountryPanel from './CountryPanel';
import ExcludedTickersPanel from './ExcludedTickersPanel';
import CountryMap from './CountryMap';
import styles from './styles.module.scss';

const Screening = ({ philosophyStore: store }) => {
  const { philosophy } = store;

  React.useEffect(() => {
    philosophy && philosophy.getScreenData();
  }, [philosophy]);

  if (philosophy && !philosophy.sectors) {
    return (
      <div className={styles.loading}>
        <Spinner animation='border' />
      </div>
    );
  }

  return (
    <>
      <div className={styles.root}>
        <div className={styles.group}>
          {
            philosophy?.sectors && philosophy?.sectors.length > 0 &&
            <SectorPanel />
          }
          {
            philosophy?.industries && philosophy?.industries.length > 0 &&
            <IndustryPanel />
          }
        </div>
        <div className={styles.group}>
          {
            philosophy?.countries && philosophy?.countries.length > 0 &&
            <>
              <CountryPanel />
              <CountryMap />
            </>
          }
        </div>
      </div>
      {
        philosophy?.excludedTickers && philosophy?.excludedTickers.length > 0 &&
        <div className={styles.root}>
          <div className={styles.group}>
            <ExcludedTickersPanel />
          </div>
        </div>
      }
    </>
  );
};

export default compose(inject('philosophyStore'), observer)(Screening);
