import React from 'react';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import Table from '@/components/Table';
import styles from './styles.module.scss';

const tableInfo = [
  { key: 'netIncome', label: 'Net Income', color: 'darkblue' },
  {
    key: 'depreciationDepletionAndAmortization',
    label: 'Depreciation Depletion and Amortization',
  },
  { key: 'assetsGainLoss', label: 'Assets Gain Loss' },
  { key: 'shareBasedCompensation', label: 'Share Based Compensation' },
  { key: 'deferredIncomeTax', label: 'Deferred Income Tax' },
  { key: 'otherNoncashAdjustments', label: 'Other Non Cash Adjustments' },
  { key: 'accountsReceivable', label: 'Accounts Receivable' },
  { key: 'inventory', label: 'Inventory' },
  { key: 'prepaid', label: 'Prepaid' },
  { key: 'accountsPayable', label: 'Accounts Payable' },
  { key: 'otherChangesInWorkingCapital', label: 'Other Changes In Working Capital' },
  {
    key: 'cashFromOperatingActivities',
    label: 'Cash From Operating Activities',
    color: 'darkblue'
  },
  { key: 'capitalExpenditures', label: 'Capital Expenditures' },
  { key: 'acquisitions', label: 'Acquisitions' },
  { key: 'investments', label: 'Investments' },
  { key: 'otherInvestingActivities', label: 'Other Investing Activities' },
  {
    key: 'cashFromInvestingActivities',
    label: 'Cash From Investing Activities',
    color: 'darkblue'
  },
  { key: 'dividendsPaid', label: 'Dividends Paid' },
  { key: 'issuanceRetirementOfStock', label: 'Issuance Retirement of Stock' },
  { key: 'issuanceRetirementOfDebt', label: 'Issuance Retirement of Debt' },
  { key: 'otherFinancingActivities', label: 'Other Financing Activities' },
  {
    key: 'cashFromFinancingActivities',
    label: 'Cash From Financing Activities',
    color: 'darkblue'
  },
  { key: 'netChangeInCash', label: 'Net Change in Cash' },
  { key: 'cashAtTheBeginning', label: 'Cash at The Beginning' },
  { key: 'cashAtTheEnd', label: 'Cash at The End' },
];

const CashFlow = ({ stockStore }) => {
  const { stock } = stockStore;

  React.useEffect(() => {
    stock.getCFA();
  }, [stock]);

  if (!stock.cfa && !stock.cik) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <Table tableInfo={tableInfo} data={stock.cfa} />
    </div>
  );
};

export default compose(inject('stockStore'), observer)(CashFlow);
