import { observable, action, reaction, runInAction } from 'mobx';
import APIService from '@/api';
import _ from 'lodash';
import Criteria from './Criteria';

class Philosophy {
  @observable name;
  @observable privilege;
  @observable.shallow criterion = [];
  @observable isModified = false;

  @observable excludedTickers;
  @observable sectors;
  @observable industries;
  @observable countries;

  philosophyType;
  ownerAlias = '';

  constructor(store, info) {
    this.store = store;
    this.id = info.id;
    this.name = info.name;
    this.privilege = info.philosophyPrivilegeId;
    this.philosophyType = info.philosophyType;
    this.ownerAlias = info.ownerAlias;
    if (info.criterion) {
      this.criterion = info.criterion.map((item) => new Criteria(this, item));
    }

    reaction(
      () => [this.isModified],
      () => {
        this.store.resetStockStore();
      }
    );
  }

  @action update = (name, value) => {
    this[name] = value;
    this.isModified = true;
  };

  @action addCriteria = () => {
    this.criterion.push(new Criteria(this));
    this.isModified = true;
  };

  @action removeCriteriaByIndex = (index) => {
    const deletedItem = this.criterion.splice(index, 1);

    const criterionList = this.getCriterionMap();
    // Check if in the criterion list remains any new item
    const newItems = criterionList.some((item) => !item.id);
    this.isModified = deletedItem[0].id !== undefined || newItems || criterionList.length === 0;
  };

  @action removeCriteria = (criteria) => {
    this.criterion = this.criterion.filter((c) => c.id !== criteria.id);
    this.isModified = true;
  };

  getCriterionMap = () => {
    return this.criterion.map((c) => ({
      id: c.id,
      financialRatio: c.financialRatio,
      formulaType: c.formulaType,
      noDataOption: c.noDataOption,
      numberOfYear: c.numberOfYear,
      percent0Value: c.percent0Value,
      percent60Value: c.percent60Value,
      percent70Value: c.percent70Value,
      percent80Value: c.percent80Value,
      percent90Value: c.percent90Value,
      percent100Value: c.percent100Value,
      weigh: c.weigh,
    }));
  };

  @action kickActionItem = async () => {
    const data = {
      id: this.id,
      name: this.name,
      privilege: this.privilege,
      criterion: this.getCriterionMap(),
    }
    await APIService.kickUserFromPhilosophy(data);
    this.isModified = false;
  };

  checkGradingItems = (items) => {
    // Check current order, compare first item vs last one
    let order = items[0] > items[5] ? 'DESC' : 'ASC';
    for (let j = 1; j < items.length; j++) {
      if (order === 'DESC') {
        if (items[j] > items[j - 1]) {
          return false;
        }
      } else {
        if (items[j] < items[j - 1]) {
          return false;
        }
      }
    }
    return true;
  };

  validateCriteriaGrading = () => {
    const gradings = this.criterion.map((c) => {
      return [Number(c.percent100Value), Number(c.percent90Value), Number(c.percent80Value), Number(c.percent70Value), Number(c.percent60Value), Number(c.percent0Value)];
    });

    // In order to be valid grading it should follow ascending or descending order for all the items
    // and no repeated items should exists
    let error = false;
    for (let i = 0; i < gradings.length; i++) {
      // First check if any value is duplicated
      const uniques = [...new Set(gradings[i])];
      if (gradings[i].length !== uniques.length) {
        error = true;
        break;
      }

      if (!this.checkGradingItems(gradings[i])) {
        error = true;
        break;
      }
    }

    return error;
  };

  @action save = async (keepOriginal = true) => {
    if (
      !this.name.trim() ||
      this.criterion.find(
        (c) =>
          c.percent0Value === '' ||
          c.percent60Value === '' ||
          c.percent70Value === '' ||
          c.percent80Value === '' ||
          c.percent90Value === '' ||
          c.percent100Value === ''
      )
    ) {
      this.store.rootStore.generalStore.showMessage({
        title: 'Error Message',
        message: `You can not save a philosophy that has a criterion with empty fields. Complete the criterion or delete it before saving.`,
        actions: [{ label: 'Ok' }],
      });
      return;
    }

    if (
      this.criterion.find((c) => c.financialRatio === '')
    ) {
      this.store.rootStore.generalStore.showMessage({
        title: 'Error Message',
        message: `You can not save a philosophy without selecting the financialRatio. Please complete the selection or delete it before saving.`,
        actions: [{ label: 'Ok' }],
      });
      return;
    }

    if (this.validateCriteriaGrading()) {
      this.store.rootStore.generalStore.showMessage({
        title: 'Error Message',
        message: `Criteria’s need to be graded in an ascending or descending order. Please make your corrections and save again.`,
        actions: [{ label: 'Ok' }],
      });
      return;
    }

    const data = {
      id: this.id,
      name: this.name,
      privilege: this.privilege,
      criterion: this.getCriterionMap(),
      keepOriginal,
    };

    this.store.rootStore.generalStore.showLoading(true);
    APIService.savePhilosophy(data).then(philosophy => {
      // Reload philosophies
      this.store.rootStore.philosophyStore.getPhilosophies(philosophy.id);
      this.store.rootStore.generalStore.showLoading(false);

      this.criterion = philosophy.criterion?.map(
          (item) => new Criteria(this, item)
      );
      this.isModified = false;
    }).catch(error => {
      this.store.rootStore.generalStore.showLoading(false);

      const message = error.message;
      let actions = [];
      // Check the error type for handling philosophy owner custom message
      // We need to be aware, in case this error message change on the API side we need to fix this matching
      const errorKickMessage = 'Would you like to kick them out of the philosophy or would you like to cancel';
      let dialogTitle = 'Attention';
      if (message.indexOf(errorKickMessage) !== -1) {
        actions.push({ label: 'Cancel' });
        actions.push({ label: 'Kick', action: this.kickActionItem });
      } else {
        actions.push({ label: 'Ok' });
      }

      const errorInsufficientSpace = 'You do not have enough space in your account to modify this philosophy. Please upgrade your account and try again.';
      if (message.indexOf(errorInsufficientSpace) !== -1) {
        dialogTitle = 'Insufficient Space';
        actions = [];
        actions.push({ label: 'Upgrade', redirect: true });
        actions.push({ label: 'Cancel' });
      }

      this.store.rootStore.generalStore.showMessage({
        title: dialogTitle !== '' ? dialogTitle : 'Error Message',
        message: `${message}`,
        actions: actions,
      });
    });
  };

  filterExcludedTickersData = (data) => {
    let filteredData = [];

    data.forEach(item => {
      const it = filteredData.filter(i => {
        return i.cik === item.cik && i.ticker === item.ticker;
      });
      if (it.length === 0) {
        filteredData.push(item);
      } else {
        const pos = _.indexOf(filteredData, it[0]);
        if (pos !== -1) {
          filteredData[pos].reasonTwo = item.reason;
        }
      }
    });

    return filteredData;
  }

  /**
   * This method fill excludedTickers
   * @param {*} data
   */
  populateExcludedTickers = (data) => {
    let filteredData = this.filterExcludedTickersData(data);
    // Sort tickers alphabetically
    filteredData = _.orderBy(filteredData, ['ticker'], ['asc']);
    this.excludedTickers = filteredData;
  }

  @action getScreenData = () => {
    if (this.loadingScreenData) return;

    this.loadingScreenData = true;
    Promise.all([
      APIService.getExcludedTickers(this.id),
      APIService.getSectors(this.id),
      APIService.getIndustries(this.id),
      APIService.getCountries(this.id),
    ]).then((data) => {
      this.loadingScreenData = false;
      runInAction(() => {
        this.populateExcludedTickers(data[0]);
        this.sectors = data[1].map((item) => ({
          ...item,
          checked: item.checked === 1,
        }));
        this.industries = data[2].map((item) => ({
          ...item,
          checked: item.checked === 1,
        }));
        this.countries = data[3].map((item) => ({
          ...item,
          checked: item.checked === 1,
        }));
      });
    });
  };

  changedFilter = (oldData, excludedItems) => {
    const oldItems = oldData
      .filter((item) => !item.checked)
      .map((item) => item.name);
    const addedItems = _.difference(excludedItems, oldItems);
    const removedItems = _.difference(oldItems, excludedItems);
    const data = oldData.map((item) => ({
      ...item,
      checked: !excludedItems.includes(item.name),
    }));
    this.store.resetStockStore();
    return { data, addedItems, removedItems };
  };

  getExcludedTickersFromList = (excludedItems, data) => {
    let exludedTickers = [];
    excludedItems.forEach(item => {
      const found = data.filter(it => { return it.cik === item });
      if (found.length > 0) {
        exludedTickers.push({ CIK: found[0].cik, StockIndex: found[0].stockIndex });
      }
    });
    return exludedTickers;
  }

  @action changeExcludedTickers = async (excludedItems) => {
    const { data, addedItems, removedItems } = this.changedFilter(
      this.excludedTickers,
      excludedItems
    );
    this.excludedTickers = data;
    console.log(`changeExcludedTickers: `, { excludedItems, addedItems, removedItems });
    if (excludedItems || excludedItems.length > 0) {
      const items = this.getExcludedTickersFromList(excludedItems, data);
      await APIService.addExcludedTicker(this.id, items);

      // If excluded tickers were added we need to refresh the excluded tickers list
      const excludedTickers = await APIService.getExcludedTickers(this.id);
      this.populateExcludedTickers(excludedTickers);
    }
  };

  @action changeSectors = async (excludedItems) => {
    const { data, addedItems, removedItems } = this.changedFilter(
      this.sectors,
      excludedItems
    );
    this.sectors = data;
    console.log(`changeSectors: `, { excludedItems, addedItems, removedItems });
    await APIService.upateSectors(this.id, addedItems, removedItems);

    // If sectors are changed we need to refresh the excluded tickers
    const excludedTickers = await APIService.getExcludedTickers(this.id);
    this.populateExcludedTickers(excludedTickers);
  };

  @action changeIndustries = async (excludedItems) => {
    const { data, addedItems, removedItems } = this.changedFilter(
      this.industries,
      excludedItems
    );
    this.industries = data;
    console.log(`changeIndustries: `, { excludedItems, addedItems, removedItems });
    await APIService.upateIndustries(this.id, addedItems, removedItems);

    // If industries are changed we need to refresh the excluded tickers
    const excludedTickers = await APIService.getExcludedTickers(this.id);
    this.populateExcludedTickers(excludedTickers);
  };

  @action changeCountries = async (excludedItems) => {
    const { data, addedItems, removedItems } = this.changedFilter(
      this.countries,
      excludedItems
    );
    this.countries = data;
    await APIService.upateCountries(this.id, addedItems, removedItems);

    // If countries are changed we need to refresh the excluded tickers
    const excludedTickers = await APIService.getExcludedTickers(this.id);
    this.populateExcludedTickers(excludedTickers);
  };
}

export default Philosophy;
