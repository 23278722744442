import React from 'react';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { AutoSizer, List } from 'react-virtualized';
import moment from 'moment';
import Loading from '@/components/Loading';
import styles from './styles.module.scss';

const SummarySECList = ({ stockStore }) => {
  const { stock } = stockStore;

  React.useEffect(() => {
    stock.getSECFiling();
  }, [stock]);

  const rowRenderer = ({ index, key, style }) => {
    const item = stock.secFiling[index];
    return (
      <a
        key={key}
        style={style}
        href={item.documentURL}
        className={styles.row}
        target='_blank'
        rel='noopener noreferrer'
        title={item.description}
      >
        <span className={styles.date}>{moment(item.filingDate).format('YYYY-MM-DD')}</span>
        <span className={styles.type}>{item.type}</span>
        <span className={styles.diz}>{item.description}</span>
      </a>
    );
  };

  return (
    <div className={styles.list}>
      {stock.secFiling ? (
        <AutoSizer>
          {({ width, height }) => (
            <List
              width={width}
              height={height}
              rowCount={stock.secFiling.length}
              rowHeight={30}
              rowRenderer={rowRenderer}
            />
          )}
        </AutoSizer>
      ) : (
        <Loading size='sm' />
      )}
    </div>
  );
};
export default compose(inject('stockStore'), observer)(SummarySECList);
