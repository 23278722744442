import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

const DialogGoodFaithDisclaimer = ({ open, handleClose, handleAcceptDisclaimer }) => {
  const disclaimerText = 'The information provided here is for information purposes only. While we pay special attention to continually improving the estimates provided and the reliability of our data, you should at all times independently verify all data through publicly-available sources prior to depending on its veracity in any way. For more information, see disclaimer link.';

  const handleCloseDialog = () => {
    handleClose(false);
  };

  return (
    <Dialog
      open={open}
      scroll={'paper'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">Good Faith</DialogTitle>
      <DialogContent dividers={true}>
        <DialogContentText
          id="scroll-dialog-description"
          tabIndex={-1}
        >
          <span style={{ color: 'black' }}>
            {disclaimerText}
          </span>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleAcceptDisclaimer} color="primary">
          Agree
        </Button>
        <Button onClick={handleCloseDialog} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogGoodFaithDisclaimer;
