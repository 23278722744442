import React from 'react';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';

import Ticker from '../Panel/ticker';

const ExcludedTickersPanel = ({ philosophyStore: store }) => {
  const { excludedTickers, changeExcludedTickers } = store.philosophy;

  return <Ticker title='Excluded Tickers' data={excludedTickers} onChange={changeExcludedTickers} />;
};

export default compose(inject('philosophyStore'), observer)(ExcludedTickersPanel);
