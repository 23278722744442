export const isEmail = (email) => {
  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const isEmptyObject = (obj) => {
  return Object.keys(obj).length > 0;
}

export const checkSharedKeyEnabledValue = (value) => {
  return value !== 0 && value !== 2;
}

export const getAliasFromEmail = (email) => {
  return email.indexOf('@') !== -1 ? email.split('@')[0] : '';
};

export const TYPE_IMPORT = 'import';
export const TYPE_OWNER = 'owner';
export const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const PASSWORD_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[.!@#$%^&*]).{8,}$/;
export const RECAPTCHA_KEY = "6LdHBOQbAAAAAGnFknbfcoBQoVi-5F9CuZ8-XbC8";
