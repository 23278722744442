import React from 'react';
import Header from '@/containers/layout/Header';
import { Link } from 'react-router-dom';

const StripePaymentSuccess = () => {
  return (
    <>
      <Header />
      <div style={{ padding: '2rem' }}>
        <h3>Your registration is successful.  Welcome to MarketClue!  Please proceed to login</h3>
        <div>
          <Link to='/login'>
            Login
          </Link>
        </div>
      </div>
    </>
  );
};

export default StripePaymentSuccess;
