import React from "react";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";
import { Row, Col, Form, Popover, Overlay } from "react-bootstrap";
import ReactPlayer from "react-player";
import { BsInfoCircle, BsFillXSquareFill } from "react-icons/bs";
import styles from "./styles.module.scss";
import APIService from '@/api';
import TooltipItem from '@/components/TooltipItem';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const numbers = Array.from({ length: 10 }, (_, k) => k + 1);
const percents = [100, 90, 80, 70, 60, 0];

const Criteria = ({ data, index, handleSelectRatioClick, philosophyStore }) => {
  const [show, setShow] = React.useState(false);
  const [target, setTarget] = React.useState(null);
  const [tooltips, setTooltips] = React.useState([]);
  const [showTooltip, setShowTooltip] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [messageSnackbar, setMessageSnackbar] = React.useState('');
  const [severitySnackbar, setSeveritySnackbar] = React.useState('success');
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [playerWidth, setPlayerWidth] = React.useState(19);
  const [playerHeight, setPlayerHeight] = React.useState(11.25);

  const getNumbersForSelect = () => {
    return numbers.map((i) => (
      <option key={i} value={i}>
        {i}
      </option>
    ));
  };

  const getDataInfoForSelect = (dataType) => {
    return data[dataType].map((item) => (
      <option key={item.id} value={item.id}>
        {item.description}
      </option>
    ));
  };

  React.useEffect(() => {
    const getTooltipData = async () => {
      if (tooltips.length > 0) return;

      let tooltipData = await philosophyStore.rootStore.generalStore.getTooltipData();
      if (tooltipData && tooltipData[0]) {
        setTooltips(tooltipData);
      }
    };

    // Check if the user has the showTooltip option enable
    if (philosophyStore && philosophyStore.showTooltip) {
      setShowTooltip(true);
      getTooltipData();
    }
  }, [philosophyStore, tooltips]);

  const getTooltipItem = (component) => {
    const found = tooltips.filter(item => item.uITopicName === component);
    return found.length > 0 ? found[0] : null;
  };

  const handleClick = (event) => {
    if (!show) {
      handleOnClose();
    }

    setShow(!show);
    setTarget(event.target);
  };

  const handleChangeNumberValue = async (e) => {
    const { name, value } = e.target;
    data.update(name, value);
  };

  const handleChangeValue = async (e) => {
    handleSelectRatioClick();
    const { name, value } = e.target;
    data.update(name, Number(value));

    // Check if the value being change is not one of the observed: financialRatio, formulaType, numberOfYear, noDataOption
    // Not call the API for filling the philosophy criteria combinations
    const observedValues = ['financialRatio', 'formulaType', 'numberOfYear', 'noDataOption'];
    if (!observedValues.some(item => item === name)) return;

    // Get default PhilosophyCriteriaCombinationPredefined
    try {
      const combinations = await APIService.getPhilosophyCriteriaCombinationPredefined(data.financialRatio, data.formulaType, data.numberOfYear, data.noDataOption);
      if (combinations && combinations[0]) {
        const { DefaultValue0, DefaultValue60, DefaultValue70, DefaultValue80, DefaultValue90, DefaultValue100 } = combinations[0];
        data.percent0Value = DefaultValue0;
        data.percent60Value = DefaultValue60;
        data.percent70Value = DefaultValue70;
        data.percent80Value = DefaultValue80;
        data.percent90Value = DefaultValue90;
        data.percent100Value = DefaultValue100;
      }
    } catch (e) {
      setOpenSnackbar(true);
      setMessageSnackbar(e);
      setSeveritySnackbar('error');
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleRemove = () => {
    data.philosophy.removeCriteriaByIndex(index);
  };

  const hideTooltips = async () => {
    await APIService.setTooltipsPreferenceConfig(false);
    philosophyStore.changeTooltip(false);
    setShowTooltip(false);
  };

  const ratio =
    philosophyStore.financialRatios.find(
      (item) => data.financialRatio === item.id
    ) || {};

  const setPlayerSize = (size) => {
    setPlayerWidth(playerWidth + size);
    setPlayerHeight(playerHeight + size);
  };
  
  const onStartHandle = async () => {
    setIsPlaying(true);
    setPlayerSize(35);
  };

  const handleOnClose = () => {
    setPlayerWidth(19);
    setPlayerHeight(11.25);
    setIsPlaying(false);
  };

  const handleRangeFocus = (event) => {
    event.target.select();
  };

  return (
    <Row className={styles.criteria}>
      <Col sm={5}>
        <div className={styles.field}>
          <TooltipItem styles={{ 'cursor': 'help' }} label={'Financial Ratio'} item={showTooltip ? getTooltipItem('Philosophy.FinancialRatio') : null} hideTooltips={hideTooltips} />
          <Form.Control
            as="select"
            name="financialRatio"
            value={data.financialRatio}
            onChange={handleChangeValue}
            required
            className={styles.financialRatio}
          >
		  	    <option value='' disabled>Select a ratio</option>
            {philosophyStore.financialRatios.map((item) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </Form.Control>
          {ratio.description && (
            <>
              <BsInfoCircle onClick={handleClick} className={styles.infoIcon} />
              <Overlay
                show={show}
                target={target}
                placement="right"
                rootClose
                onHide={() => setShow(false)}
              >
                <Popover id="help-popover">
                  <Popover.Content>
                    <div className="d-flex align-items-center">
                      {!isPlaying && <div style={{ width: "240px" }}>{ratio.description}</div>}
                      {ratio.videoLink && (
                        <div>
                          <ReactPlayer
                            url={ratio.videoLink}
                            onStart={onStartHandle}
                            controls={true}
                            width={`${playerWidth}rem`}
                            height={`${playerHeight}rem`}
                          />
                        </div>
                      )}
                    </div>
                  </Popover.Content>
                </Popover>
              </Overlay>
            </>
          )}
        </div>
        <div className={styles.field}>
          <TooltipItem styles={{ 'cursor': 'help' }} label={'Formula'} item={showTooltip ? getTooltipItem('Philosophy.FormulaType') : null} hideTooltips={hideTooltips} />
          <Form.Control
            as="select"
            name="formulaType"
            value={data.formulaType}
            onChange={handleChangeValue}
            style={{ width: "180px" }}
          >
            {getDataInfoForSelect('formulaTypes')}
          </Form.Control>
        </div>
        <div className={styles.field}>
          <TooltipItem styles={{ 'cursor': 'help' }} label={'NumberOfYears'} item={showTooltip ? getTooltipItem('Philosophy.NumberOfYears') : null} hideTooltips={hideTooltips} />
          <Form.Control
            as="select"
            name="numberOfYear"
            value={data.numberOfYear}
            onChange={handleChangeValue}
            style={{ width: "90px" }}
          >
            {getNumbersForSelect()}
          </Form.Control>
        </div>
        <div className={styles.field}>
          <label />
          <Form.Control
            as="select"
            name="noDataOption"
            value={data.noDataOption}
            onChange={handleChangeValue}
            style={{ width: "120px" }}
          >
            {getDataInfoForSelect('noDataOptions')}
          </Form.Control>
          <TooltipItem styles={{ 'cursor': 'help' }} label={'if there is no data available'} classNames={'ml-1'} item={showTooltip ? getTooltipItem('Philosophy.NoDataOption') : null} hideTooltips={hideTooltips} />
        </div>
      </Col>
      <Col sm={4} className={styles.valueDescription}>
        <TooltipItem label={ratio.valueDescription} classNames={'ml-1'} styles={{ 'textAlign': 'justify', 'cursor': 'help' }} item={showTooltip ? getTooltipItem('Philosophy.RatioDescription') : null} hideTooltips={hideTooltips} />
      </Col>
      <Col sm={3} className="d-flex align-items-start">
        <div className={styles.weight}>
          <TooltipItem styles={{ 'cursor': 'help' }} label={'Weight'} item={showTooltip ? getTooltipItem('Philosophy.Weight') : null} hideTooltips={hideTooltips} />
          <Form.Control
            as="select"
            name="weigh"
            value={data.weigh}
            onChange={handleChangeValue}
            style={{ width: "90px" }}
          >
            {getNumbersForSelect()}
          </Form.Control>
        </div>
        <div>
          {percents.map((percent, index) => (
            <div key={index} className={styles.persent}>
              <TooltipItem styles={{ 'cursor': 'help' }} label={`${percent}%`} item={showTooltip ? getTooltipItem('Philosophy.Percent') : null} hideTooltips={hideTooltips} />
              <Form.Control
                type="number"
                name={`percent${percent}Value`}
                value={data[`percent${percent}Value`]}
                onChange={handleChangeNumberValue}
                onFocus={handleRangeFocus}
              />
            </div>
          ))}
        </div>
      </Col>
      <BsFillXSquareFill className={styles.closeIcon} onClick={handleRemove} title='Delete criterion' />

      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={severitySnackbar}>
          {messageSnackbar}
        </Alert>
      </Snackbar>
    </Row>
  );
};

export default compose(inject("philosophyStore"), observer)(Criteria);
