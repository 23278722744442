import React from 'react';
import { AutoSizer, List } from 'react-virtualized';
import { BiSearch } from 'react-icons/bi';
import { IoMdClose } from 'react-icons/io';
import CheckboxWithBtn from '@/components/Checkbox/CheckboxWithBtn';
import styles from './styles.module.scss';
import { GoPlus } from "react-icons/go";
import Tooltip from '@material-ui/core/Tooltip';
import { Modal, Button } from 'react-bootstrap';

const Ticker = ({ title, data, onChange }) => {
  // The tooltip will appear after two seconds over the element
  const toolTipDelay = 1000;
  const [showModal, showHideModal] = React.useState(false);
  const [filterText, setFilterText] = React.useState('');
  const str = filterText.toLowerCase();
  const filteredData = str
    ? data.filter((item) => item.name.toLowerCase().includes(str))
    : data;
  const selectedCount = data.filter((item) => item.checked).length;
  const tooltipText = { fontSize: '12px' };

  const handleCheckAll = () => {
    showHideModal(false);
    onChange(data.map((item) => item.cik));
  };

  const handleOneCheck = (cik) => {
    onChange([cik]);
  };

  const rowRenderer = ({ key, index, style }) => {
    const { name, companyName, reason, cik, reasonTwo } = filteredData[index];
    const getCompanyName = (itemCompanyName) => {
      return itemCompanyName.length > 25 ? itemCompanyName.substring(0, 25) + '...' : itemCompanyName;
    }
    return (
      <div key={key} className={styles.item} style={style}>
        <GoPlus onClick={() => handleOneCheck(cik)} className={styles.plusIcon} />
        <Tooltip
          title={<><div style={tooltipText}>{`Ticker ${reason}`}</div>{reasonTwo && <div style={tooltipText}>{`Ticker ${reasonTwo}`}</div>}</>}
          placement="bottom"
          enterDelay={toolTipDelay}
          arrow>
          <div className={styles.tooltipItem}>
            <span>{name}</span>
            <span>{getCompanyName(companyName)}</span>
          </div>
        </Tooltip>
      </div>
    );
  };

  const addAllExcludedTickers = () => {
    showHideModal(true);
  }

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <CheckboxWithBtn
          color='#f0e5d6'
          label={`${title}`}
          checked={selectedCount !== 0}
          middle={selectedCount !== data.length}
          onChange={handleCheckAll}
          openModal={addAllExcludedTickers}
        />
      </div>
      <div className={styles.searchBar}>
        <input
          placeholder='Type to search'
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
        />
        <BiSearch className='search' />
        {filterText && (
          <IoMdClose className='clear' onClick={() => setFilterText('')} />
        )}
      </div>
      <div className={styles.list}>
        <AutoSizer>
          {({ width, height }) => (
            <List
              width={width}
              height={height}
              rowHeight={38}
              rowCount={filteredData.length}
              rowRenderer={rowRenderer}
            />
          )}
        </AutoSizer>
      </div>

      <Modal show={showModal} onHide={() => showHideModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure that you would like add all the tickers back to the watch list? Doing so will remove all screening options that you previously made.</Modal.Body>
        <Modal.Footer>
          <Button variant='danger' onClick={handleCheckAll}>
            Yes
          </Button>
          <Button variant='secondary' onClick={() => showHideModal(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Ticker;
