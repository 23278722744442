import React from 'react';
import styles from './styles.module.scss';
import MsgList from './MsgList';
import Input from './Input';

const Notes = () => {
  return (
    <div className={styles.wrapper}>
      <MsgList />
      <Input />
    </div>
  );
};
export default Notes;
