import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";

import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';

import { FaSortUp } from 'react-icons/fa';
import { FaSortDown } from 'react-icons/fa';

import Tooltip from '@material-ui/core/Tooltip';

const FINAL_GRADE = 'Final Grade';
const tooltipText = { fontSize: '12px' };

const CriteriaList = ({ criterion, finalGrade, stockStore: store }) => {
  const [filteredBy, setFilteredBy] = useState(FINAL_GRADE);
  const [filteredByOrder, setFilteredByOrder] = useState('DESC');

  useEffect(() => {
    const changeInitialValues = () => {
      if (store.filteredByOrder && store.filteredByOrder !== '' && store.filteredByOrder !== filteredByOrder) {
        setFilteredByOrder(store.filteredByOrder);
      }
      if (store.filteredBy && store.filteredBy !== '' && store.filteredBy !== filteredBy) {
        setFilteredBy(store.filteredBy);
      }
    };

    changeInitialValues();
  }, [filteredBy, filteredByOrder, store]);

  const handleFinancialRatioClick = (financialRatioName, financialRatioId = -1) => {
    // Check what's need to be changed
    // The filter key is being changed
    if (financialRatioName && financialRatioName !== '' && financialRatioName !== filteredBy) {
      setFilteredBy(financialRatioName);

      store.setFilteredBy(financialRatioName);
      // If the sorting field is being changed start 'DESC'
      store.setFilteredByOrder('DESC');
    } else {
      // The filter order is being changed
      const order = filteredByOrder === 'ASC' ? 'DESC' : 'ASC';
      setFilteredByOrder(order);

      store.setFilteredByOrder(order);
    }
    
    store.getStocks(financialRatioId);
  };

  const getSortByIcon = () => {
    return filteredByOrder === 'ASC' ? <FaSortUp /> : <FaSortDown />;
  };

  return (
    <div className={styles.wrapper}>
      {criterion.map((item, index) => (
        <div key={index} className={styles.criteria}>
          <Tooltip
            className={styles.tooltip}
            title={<div style={tooltipText}>{item.value}</div>}
            arrow
            placement="bottom"
          >
            <div className={styles.name} onClick={() => handleFinancialRatioClick(item.financialRatioName, item.financialRatioId)}>{item.financialRatioName}</div>
          </Tooltip>
          <span className={styles.sortingIconSpan}>{filteredBy && filteredBy === item.financialRatioName && getSortByIcon()}</span>
          <div className={styles.value}>
            <span>{item.grade}</span>
            &times;
            <span>{item.weight}</span>
          </div>
        </div>
      ))}
      <div className={styles.criteriafinalgrade}>
        <div className={styles.namefinalgrade} onClick={() => handleFinancialRatioClick(FINAL_GRADE)}>{FINAL_GRADE}</div>
        <span className={styles.sortingIconSpan}>{filteredBy && filteredBy === FINAL_GRADE && getSortByIcon()}</span>
        <div className={styles.valuefinalgrade}>
          <span>{parseFloat(finalGrade).toFixed(2)}%</span>
        </div>
      </div>
    </div>
  );
};

export default compose(inject('stockStore'), observer)(CriteriaList);
