import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import APIService from '@/api';
import { useHistory } from "react-router-dom";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";

const UPGRADE_MESSAGE = "Upgrade to access this feature.";
const POLICY_VIOLATION_MESSAGE = "You have been block due to violation of our company policies.";
const INSUFFICIENT_SPACE_TITLE = "Insufficient Space";
const INSUFFICIENT_SPACE_MESSAGE = "You have maxed out your criteria limit. Please upgrade your account and try again.";

const DialogUpdateFeature = ({ sharedKeyEnabledValue, openDialogUpgrade, handleCloseDialogUpgrade, insufficientSpace, authStore }) => {
  const [message, setMessage] = useState('');
  const history = useHistory();

  React.useEffect(() => {
    const setDialogMessage = () => {
      if (sharedKeyEnabledValue === 0) {
        setMessage(UPGRADE_MESSAGE);
      } else if (sharedKeyEnabledValue === 2) {
        setMessage(POLICY_VIOLATION_MESSAGE);
      }
    };
    setDialogMessage();
  }, [sharedKeyEnabledValue]);

  const showUpgradeButton = () => {
    return message === UPGRADE_MESSAGE;
  };

  const handleStripeCustomer = (email) => {
    APIService.createCustomerStripePortalSession(email).then(response => {
      if (response.redirect) {
        window.location = response.response;
      }
    }).catch(error => {
      console.error(error);
    });
  };

  const handleUpgradePlanClick = () => {
    // Check current planId
    if (authStore.planId > 1) {
      handleStripeCustomer(authStore.email);
      return;
    }

    history.push('/pick-plan', {currentPlan: authStore.planId});
  };

  return (
    <Dialog
      open={openDialogUpgrade}
      onClose={handleCloseDialogUpgrade}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {
        !insufficientSpace && <DialogTitle id="alert-dialog-title">{message}</DialogTitle>
      }
      {
        insufficientSpace &&
        <>
          <DialogTitle id="alert-dialog-title">{INSUFFICIENT_SPACE_TITLE}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <span style={{ color: 'black' }}>
              {INSUFFICIENT_SPACE_MESSAGE}
              </span>
            </DialogContentText>
          </DialogContent>
        </>
      }
      <DialogActions>
        {
          showUpgradeButton() &&
          <Button onClick={handleUpgradePlanClick} color="primary">
            Upgrade
          </Button>
        }
        <Button onClick={handleCloseDialogUpgrade} color="secondary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

// Specifies the default values for props:
DialogUpdateFeature.defaultProps = {
  sharedKeyEnabledValue: 0,
  insufficientSpace: false
};

// export default DialogUpdateFeature;
export default compose(inject('authStore'), observer)(DialogUpdateFeature);
