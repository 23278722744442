import { action, observable } from 'mobx';
import APIService from '@/api';

class GeneralStore {
  @observable loading = false;
  @observable.shallow message;

  @observable userPreferences;
  @observable tooltipData;

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @action showMessage = (message) => {
    this.message = message;
  };

  @action closeMessage = () => {
    this.message = null;
  };

  @action showLoading = (loading) => {
    this.loading = loading;
  };

  saveData = (key, value) => {
    localStorage.setItem(`${this.rootStore.authStore.id}_${key}`, value);
  };

  loadData = (key, defaultData) => {
    return (
      localStorage.getItem(`${this.rootStore.authStore.id}_${key}`) ||
      defaultData
    );
  };

  getUserPreference = async () => {
    if (this.userPreferences != null) return this.userPreferences;
    this.userPreferences = await APIService.getUserPreferenceConfig();
    return this.userPreferences;
  }

  getTooltipData = async () => {
    if (this.tooltipData && this.tooltipData.length > 0) return this.tooltipData;
    // If no data returned return an empty array for avoiding UI errors
    this.tooltipData = await APIService.getTooltipsData('en') ?? [];
    return this.tooltipData;
  };
}

export default GeneralStore;
