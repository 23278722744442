import React, { useState, useEffect } from "react";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Link, useHistory } from "react-router-dom";
import ToolTip from "react-portal-tooltip";
import DialogSignInErrorUserPlan from "@/components/DialogSignInErrorUserPlan";
import VideoBackground from "@/images/LoginBackground.mp4";
import Button from "@material-ui/core//Button";

import "./styles.scss";

import { BiInfoCircle } from "react-icons/bi";
import { EMAIL_REGEX } from "@/utils";

const SignIn = ({ location, authStore }) => {
  const history = useHistory();
  const [staySign, setStaySign] = useState(false);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [elementFocused, setElementFocused] = useState({ email: false, password: false });
  const [showLoginSubheader, setShowLoginSubheader] = useState(false);
  const [openDialogError, setOpenDialogError] = useState(false);
  const [dialogErrorMessage, setDialogErrorMessage] = useState('');

  useEffect(() => {
    const getSessionId = () => {
      const { search } = location;
      if (search) {
        if (search.indexOf('session_id=') !== -1) {
          setShowLoginSubheader(true);
        }
      }
    };

    getSessionId();
  }, [location]);

  const style = {
    style: {
      background: "white",
      padding: 20,
      transition: "none",
    },
    arrowStyle: {
      borderColor: "rgba(0, 0, 0, 0.4)",
      color: "white",
    },
  };

  const handleStayChange = (event) => {
    setStaySign(!staySign);
  };

  const showTooltip = (e) => {
    setTooltipVisible(true);
  };

  const hideTooltip = (e) => {
    setTooltipVisible(false);
  };

  const checkApiError = (apiError) => {
    if (typeof apiError === 'string' && apiError.indexOf('Please select a plan and complete the process') !== -1) {
      setDialogErrorMessage(apiError);
      setOpenDialogError(true);
    } else {
      const message = apiError.message ? apiError.message : apiError;
      setError(message);
    }
  } ;

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      await authStore.login(email, password);
      history.push("/app");
    } catch (apiError) {
      // We need to check if the error message is related to subscription needed
      checkApiError(apiError);
    }
  };

  const handleOnBlur = (inputType) => {
    const elementFocusedTemp = elementFocused;
    elementFocusedTemp[`${inputType}`] = true;
    setElementFocused(elementFocusedTemp);
  };

  const isEmailValid = (email) => {
    return EMAIL_REGEX.test(email);
  };

  const isPasswordValid = (passwd) => {
    return passwd !== '';
  };

  const validateInput = (inputType) => {
    switch (inputType) {
      case 'email':
        return elementFocused.email && !isEmailValid(email);
      case 'password':
        return elementFocused.password && !isPasswordValid(password);
      default:
        return false;
    }
  };

  const registerDisabled = () => {
    return !isEmailValid(email) || !isPasswordValid(password);
  };

  const getSignBtnClassName = () => {
    return registerDisabled() ? "sign-btn sign-btn-disabled" : "sign-btn sign-btn-enabled";
  };

  const forgotDisabled = () => {
    return !isEmailValid(email);
  }

  const getForgotBtnClassName = () => {
    return forgotDisabled() ? "sign-btn-disabled" : "sign-btn-enabled";
  };

  const handleForgotPassword = async () => {
    try {
      const response = await authStore.forgotPassword(email);

      setDialogErrorMessage(response.message);
      setOpenDialogError(true);
    } catch (error) {
      setDialogErrorMessage(error);
      setOpenDialogError(true);
    }
  };

  const handleCloseDialogError = () => {
    setOpenDialogError(false);
    // Check if this Dialog is being displayed using forgot password
    if (!password) return;
    // Redirect the user to pick-plan page, pass the user email to use it as placeholder for not creating the user again
    history.push('/pick-plan', {email});
  };

  return (
    <div className="login-container">
      <div className="container">
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <video
            autoPlay
            muted
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              objectFit: "cover",
              zIndex: "-1"
            }}>
              <source src={VideoBackground} type='video/mp4' />
          </video>
        </div>

        {
          showLoginSubheader && <h5>Your registration is complete. Please proceed to login.</h5>
        }

        <form className="login-form" onSubmit={handleLogin}>
          <TextField
            className="input-field"
            required
            label="Email"
            placeholder="Email"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            InputLabelProps={{ required: false }}
            error={validateInput('email')}
            helperText={validateInput('email') ? "Email is required and should match the requirements" : ""}
            onBlur={() => handleOnBlur('email')}
          />
          <TextField
            className="input-field"
            required
            type="password"
            label="Password"
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputLabelProps={{ required: false }}
            error={validateInput('password')}
            helperText={validateInput('password') ? "Password is required" : ""}
            onBlur={() => handleOnBlur('password')}
          />

          {error.length > 0 && <div className="error-text">{error}</div>}

          <div className="stay-sign">
            <FormControlLabel
              className="sign-checkbox"
              control={
                <Checkbox checked={staySign} onChange={handleStayChange} />
              }
              label="Stay signed in to this app"
            />
            <BiInfoCircle
              id="stay-tooltip"
              size="1.5rem"
              color="#1E90FF"
              onMouseEnter={showTooltip}
              onMouseLeave={hideTooltip}
            />
            <ToolTip
              parent="#stay-tooltip"
              position="bottom"
              arrow="left"
              active={tooltipVisible}
              style={style}
            >
              <div style={{ width: "25rem" }}>
                <p>Why Stay Sign in?</p>
                <p>
                  With this option selected, you'll stay signed in to your
                  account on this device until you sign out. You should not use
                  this option on public or shared devices
                </p>
                <p>
                  For your security, you may be asked to enter your password
                  before accessing certain information.
                </p>
              </div>
            </ToolTip>
          </div>

          <button className={getSignBtnClassName()} type="submit" disabled={registerDisabled()}>
            Sign In
          </button>
        </form>

        <div className="sign-options">
          <div className="more-option">
            <div className="options">
              Don't have an account? <Link to="/pick-plan">Create One</Link>
            </div>
            <div className="options">
              <Button className={getForgotBtnClassName()} variant="outlined" disabled={forgotDisabled()} onClick={handleForgotPassword}>Forgot password?</Button>
            </div>
          </div>
        </div>
      </div>

      <DialogSignInErrorUserPlan open={openDialogError} handleClose={handleCloseDialogError} message={dialogErrorMessage} />
    </div>
  );
};

export default compose(inject("authStore"), observer)(SignIn);
