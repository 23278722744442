import React from 'react';

const InfoNotAvailable = () => {
  return (
    <div className='px-4'>
      <p>
        This feature is not available due to a violation of this application's terms of agreement.
        Please contact customer support for assistance.
      </p>
    </div>
  )
}

export default InfoNotAvailable;
