import React, { useState } from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import DialogFooterElement from "@/components/DialogFooterElement";
import DialogFooterContentTerms from "@/components/DialogFooterContentTerms";
import DialogFooterContentPrivacy from "@/components/DialogFooterContentPrivacy";
import DialogFooterContentLicence from "@/components/DialogFooterContentLicence";
import DialogFooterContentDisclaimer from "@/components/DialogFooterContentDisclaimer";

const Footer = ({ handleElementClick }) => {
  const [openDialogFooter, setOpenDialogFooter] = useState(false);
  const [dialogText, setDialogText] = useState('');
  const [dialogContentText, setDialogContentText] = useState(null);
  const elements = [
    { index: 0, text: 'Terms and Conditions of Use' },
    { index: 1, text: 'Privacy Policy' },
    { index: 2, text: 'License Agreement'},
    { index: 3, text: 'Disclaimer'}
  ];

  const onElementClick = (element) => {
    setDialogText(element.text);
    setDialogContentText(getDialogContentText(element.index));
    setOpenDialogFooter(true);
  };

  const getDialogContentText = (index) => {
    switch (index) {
      case 0:
        return <DialogFooterContentTerms />;
      case 1:
        return <DialogFooterContentPrivacy />;
      case 2:
        return <DialogFooterContentLicence />;
      case 3:
        return <DialogFooterContentDisclaimer />;
      default:
        return <></>;
    }
    
  };

  return (
    <div style={{ backgroundColor: '#343a40' }} onClick={handleElementClick}>
      <Toolbar style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {
          elements.map(item => <label key={item.index} style={{ color: 'white', marginRight: '10px', fontSize: '12px', cursor: 'pointer' }} onClick={() => onElementClick(item)}>{item.text}</label>)
        }
      </Toolbar>
      <DialogFooterElement open={openDialogFooter} handleClose={setOpenDialogFooter} dialogText={dialogText} dialogContentText={dialogContentText} />
    </div>
  )
}

export default Footer;
