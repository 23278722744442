import React from 'react';
import Typography from '@material-ui/core/Typography';

const DialogFooterContentTerms = () => {
  return (
    <>
      <Typography variant="body2" align='justify' paragraph={true}>Your use of the marketclue.com web site and the applications, services, information and other materials available on and through the web site (such applications, services, information, other materials, and the web site itself shall be referred to collectively as the "Site") is subject to the terms and conditions set forth herein, as well as any other notices, disclaimers, or restrictions posted on the Site (collectively, the "Terms of Use").</Typography>
      <Typography variant="body2" align='justify' paragraph={true}>PLEASE READ THESE TERMS OF USE CAREFULLY. BY USING THE SITE, YOU AGREE TO ADHERE TO AND BE BOUND BY THESE TERMS OF USE, AS THESE TERMS OF USE MAY BE MODIFIED FROM TIME TO TIME IN THE SOLE DISCRETION OF MARKET CLUE CORP. IF YOU DO NOT AGREE TO BE LEGALLY BOUND BY THESE TERMS OF USE, YOU ARE NOT PERMITTED TO USE THE SITE.</Typography>
      <Typography variant="body2" align='justify' paragraph={true}>Each time you enter in a login ID and password on the Site, (1) you represent that you are the user assigned to use the login ID and password that is accessing the Site and (2) you agree to be bound by the Terms of Use. Use of the Site is restricted to Market Clue Corp. and its authorized users. Unauthorized use of the Site including, but not limited to, unauthorized entry into the Site, misuse of passwords, or misuse of any information within the Site is strictly prohibited.</Typography>
      <Typography variant="subtitle1" component="div" align='justify' paragraph={true}>INTELLECTUAL PROPERTY</Typography>
      <Typography variant="body2" align='justify' paragraph={true}>You acknowledge and agree that, Market Clue Corp. and/or its affiliates and/or its licensors shall own all worldwide right, title and interest in and to the Site and any applications, services, text, graphics, multimedia content, or other information, data, content or material available on or through the Site and pages within that domain, and all related code provided through the Site, any modifications, updates, upgrades, copies, derivative works, augmentations or customizations of the foregoing (collectively, "Materials"), including all worldwide intellectual property rights to the same, including without limitation, all United States, Canadian and worldwide patents, patent applications, copyrights, trademarks, trade secrets, rights of publicity and privacy and other proprietary rights. All rights not expressly granted to you herein are expressly reserved to Market Clue Corp. and its affiliates and licensors.</Typography>
      <Typography variant="body2" align='justify' paragraph={true}>Material from the Site may not be copied, reproduced, distributed or modified. Modification of the Materials or use of the Materials for any purpose other than that expressly permitted herein or otherwise on the Site is a violation of copyright and other proprietary rights and the use of any such Material on any other web site or computer environment is strictly prohibited.</Typography>
      <Typography variant="body2" align='justify' paragraph={true}>You may submit feedback, suggestions or comments ("Feedback") that will universally enhance the Site. Market Clue Corp. may, in its sole discretion, decide to incorporate some or all of this Feedback into the Site. Notwithstanding anything to the contrary herein, to the extent any portion of the Site, or any versions thereof or enhancements thereto are not deemed owned by Market Clue Corp., you hereby assign all of your right, title and interest in the Site or any such Feedback or enhancements to Market Clue Corp. You will execute such documents as may be deemed reasonably necessary to accomplish the objectives of this Section. You grant Market Clue Corp. a worldwide, perpetual, nonexclusive, sublicensable, royalty-free license to use, reproduce, distribute, transmit, disclose, display, modify and create derivative works of any content, data, information or other materials you submit and/or receive through the Site; provided, that, Market Clue Corp. shall do so in accordance with applicable law and/or the applicable agreement between the party that authorized you to use the Site and Market Clue Corp., if applicable.</Typography>
      <Typography variant="subtitle1" component="div" align='justify' paragraph={true}>TRADEMARKS</Typography>
      <Typography variant="body2" align='justify' paragraph={true}>"Market Clue" and the Market Clue Corp. logos as well as other related marks which may appear on the Site ("Marks"), are the service marks and trademarks of Market Clue Corp. All other trademarks, service marks and logos used on this Site, with or without attribution, are the trademarks, service marks or logos of their respective owners.</Typography>
      <Typography variant="subtitle1" component="div" align='justify' paragraph={true}>THIRD PARTY CONTENT; THIRD PARTY SITES</Typography>
      <Typography variant="body2" align='justify' paragraph={true}>Market Clue Corp. is a distributor and not a publisher of information supplied to the Site by you, other users or third parties. Any opinions, advice, statements, services, contracts, offers, or other information that is part of the Materials on this Site that is expressed or made available by third parties, including Financing Sources, aftermarket product companies, credit bureau reporting agencies and/or Dealers or any other user of the Site, are those of the respective authors or distributors and not of Market Clue Corp. MARKET CLUE CORP. ASSUMES NO RESPONSIBILITY AND MAKES NO REPRESENTATIONS, WARRANTIES, RECOMMENDATIONS, ENDORSEMENTS OR APPROVALS WITH REGARD TO SUCH THIRD PARTY INFORMATION. It is your responsibility to evaluate and confirm the information, opinions, advice or other Material available through the Site, whether posted or provided by third parties or by Market Clue Corp.</Typography>
      <Typography variant="body2" align='justify' paragraph={true}>The Site may contain hyperlinks to other web sites that are not operated by Market Clue Corp.. Market Clue Corp. does not control these web sites and is not responsible for their contents, nor should the existence of such links be construed as an endorsement of the material appearing on such sites or as implying an association between Market Clue Corp. and their operators. Such hyperlinks are provided for your reference only.</Typography>
      <Typography variant="subtitle1" component="div" align='justify' paragraph={true}>USER REPRESENTATIONS, WARRANTIES AND COVENANTS</Typography>
      <Typography variant="subtitle1" component="div" align='justify' paragraph={true}>You represent, warrant and covenant to Market Clue Corp. that:</Typography>
      <Typography variant="body2" align='justify' component="div">
        <ol>
          <li>You will comply at all times with all applicable federal, state, provincial and local laws, rules and regulations in your use of the Site and/or your use or disclosure of any data, information or materials you submit to or receive through the Site;</li>
          <li>You will not use the Site for any purpose which is improper, unlawful, abusive, harassing, libelous, defamatory, obscene or threatening, or which violates the Terms of Use;</li>
          <li>You will not, nor will you permit any other person, corporation or entity, without the prior written consent of Market Clue Corp. to:</li>
          <ol>
            <li>copy, duplicate or grant permission to the Site or any part thereof;</li>
            <li>create, attempt to create, or grant permission to the source program and/or object program or code associated with any software component of the Site;</li>
            <li>assign, sublicense, rent, timeshare, loan, lease or otherwise transfer the Site or directly or indirectly permit any third party to use the Site;</li>
            <li>decompile or disassemble any software or other component of the Site or any report or document generated therefrom;</li>
            <li>engage in any reverse engineering;</li>
            <li>modify, alter or delete any of the copyright notices embedded in or affixed to the copies of any components of the Site;</li>
            <li>export any application provided hereunder or any portion of the Site, or access the Site from outside the United States or Canada; or</li>
            <li>use Market Clue Corp.'s name, URL, trademarks, server, or other materials in connection with, or to transmit, any "spam." For the purposes of this agreement, "spam" has the meaning generally understood among Internet users.</li>
          </ol>
          <li>Any transmission of data from your computer equipment or system will be free from</li>
          <ol>
            <li>intentionally injurious instructions (e.g. "viruses") that are designed to modify, damage, delete or disable the Site or any applications thereon;</li>
            <li>any hidden passwords that permit unauthorized access to the data or the Site, or</li>
            <li>any embedded code that could trigger, shut down or disable the Site;</li>
          </ol>
          <li>You have obtained all necessary licenses, releases and consents to grant the rights set forth herein;</li>
          <li>You shall have all regulatory approvals, authorizations, licenses, permits, and other permissions, consents and authorities whatsoever needed to use the Site and perform your obligations hereunder; and</li>
          <li>You will comply with the security measures set forth below under the heading "SECURITY".</li>
        </ol>
      </Typography>
      <Typography variant="subtitle1" component="div" align='justify' paragraph={true}>AGGREGATION USER AGREEMENT</Typography>
      <Typography variant="body2" align='justify' paragraph={true}>Proprietary Rights. You are permitted to use content delivered to you through the service only on the service. You may not copy, reproduce, distribute, or create derivative works from this content. Further, you agree not to reverse engineer or reverse compile any of the service technology, including but not limited to, any Java applets associated with the service.</Typography>
      <Typography variant="body2" align='justify' paragraph={true}>Content You Provide And Is Derived by Use of the Service. You are licensing to Developer and its service providers (“Service Provider”) any information, data, passwords, materials or other content (collectively, “Content”) you provide through or to the service. Developer and Service Provider may use, modify, display, distribute and create new material using such Content to provide the service to you. Developer and Service Provider may also use, sell, license, reproduce, distribute and disclose aggregate, non-personally identifiable information that is derived through your use of the service. By submitting Content, you automatically agree, or promise that the owner of such Content has expressly agreed that, without any particular time limit, and without the payment of any fees, Developer and Service Provider may use the Content for the purposes set out above. As between Developer and Service Provider, Developer owns your confidential account information.</Typography>
      <Typography variant="body2" align='justify' paragraph={true}>Third Party Accounts. By using the service, you authorize Developer and Service Provider to access third party sites designated by you, on your behalf, to retrieve information requested by you, and to register for accounts requested by you. For all purposes hereof, you hereby grant Developer and Service Provider a limited power of attorney, and you hereby appoint Developer and Service Provider as your true and lawful attorney-in-fact and agent, with full power of substitution and resubstitution, for you and in your name, place and stead, in any and all capacities, to access third party internet sites, servers or documents, retrieve information, and use your information, all as described above, with the full power and authority to do and perform each and every act and thing requisite and necessary to be done in connection with such activities, as fully to all intents and purposes as you might or could do in person. YOU ACKNOWLEDGE AND AGREE THAT WHEN DEVELOPER OR SERVICE PROVIDER ACCESSES AND RETRIEVES INFORMATION FROM THIRD PARTY SITES, DEVELOPER AND SERVICE PROVIDER ARE ACTING AS YOUR AGENT, AND NOT THE AGENT OR ON BEHALF OF THE THIRD PARTY. You agree that third party account providers will be entitled to rely on the foregoing authorization, agency and power of attorney granted by you. You understand and agree that the service is not endorsed or sponsored by any third party account providers accessible through the service.</Typography>
      <Typography variant="subtitle1" component="div" align='justify' paragraph={true}>DISCLAIMER OF WARRANTIES. YOU EXPRESSLY UNDERSTAND AND AGREE THAT:</Typography>
      <Typography variant="body2" align='justify' paragraph={true}>YOUR USE OF THE SERVICE AND ALL INFORMATION, PRODUCTS AND OTHER CONTENT (INCLUDING THAT OF THIRD PARTIES) INCLUDED IN OR ACCESSIBLE FROM THE SERVICE IS AT YOUR SOLE RISK. THE SERVICE IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. DEVELOPER AND SERVICE PROVIDER EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND AS TO THE SERVICE AND ALL INFORMATION, PRODUCTS AND OTHER CONTENT (INCLUDING THAT OF THIRD PARTIES) INCLUDED IN OR ACCESSIBLE FROM THE SERVICE, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NONINFRINGEMENT.</Typography>
      <Typography variant="body2" align='justify' paragraph={true}>DEVELOPER AND SERVICE PROVIDER MAKE NO WARRANTY THAT (i) THE SERVICE WILL MEET YOUR REQUIREMENTS, (ii) THE SERVICE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, (iii) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICE WILL BE ACCURATE OR RELIABLE, (iv) THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH THE SERVICE WILL MEET YOUR EXPECTATIONS, OR (V) ANY ERRORS IN THE TECHNOLOGY WILL BE CORRECTED.</Typography>
      <Typography variant="body2" align='justify' paragraph={true}>ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICE IS DONE AT YOUR OWN DISCRETION AND RISK AND YOU ARE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM DEVELOPER OR SERVICE PROVIDER THROUGH OR FROM THE SERVICE WILL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THESE TERMS.</Typography>
      <Typography variant="body2" align='justify' paragraph={true}>LIMITATION OF LIABILITY. YOU AGREE THAT NEITHER DEVELOPER OR SERVICE PROVIDER NOR ANY OF THEIR AFFILIATES, ACCOUNT PROVIDERS OR ANY OF THEIR AFFILIATES WILL BE LIABLE FOR ANY HARMS, WHICH LAWYERS AND COURTS OFTEN CALL DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES, EVEN IF DEVELOPER OR SERVICE PROVIDER HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, RESULTING FROM: (i) THE USE OR THE INABILITY TO USE THE SERVICE; (ii) THE COST OF GETTING SUBSTITUTE GOODS AND SERVICES, (iii) ANY PRODUCTS, DATA, INFORMATION OR SERVICES PURCHASED OR OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO, THROUGH OR FROM THE SERVICE; (iv) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; (v) STATEMENTS OR CONDUCT OF ANYONE ON THE SERVICE; (vi) THE USE, INABILITY TO USE, UNAUTHORIZED USE, PERFORMANCE OR NON-PERFORMANCE OF ANY THIRD PARTY ACCOUNT PROVIDER SITE, EVEN IF THE PROVIDER HAS BEEN ADVISED PREVIOUSLY OF THE POSSIBILITY OF SUCH DAMAGES; OR (vii) ANY OTHER MATTER RELATING TO THE SERVICE.</Typography>
      <Typography variant="body2" align='justify' paragraph={true}>IN ADDITION, MARKET CLUE CORP. DOES NOT WARRANT THAT THE FUNCTIONS ON THE SITE WILL BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS IN OR ON THE SITE WILL BE CORRECTED, OR THAT THE SITE OR THE SERVER THAT MAKES THE SITE AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. MARKET CLUE CORP. DOES NOT WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE SITE OR RESULTS OF THE USE OF THE SITE, SPECIFIC SERVICES OR APPLICATIONS PROVIDED THROUGH THE SITE, OR ANY MATERIALS OR INFORMATION ON THE SITE, IN TERMS OF THEIR CORRECTNESS, ACCURACY, RELIABILITY, LEGAL COMPLIANCE OR OTHERWISE. YOU (AND NOT MARKET CLUE CORP.) ASSUME THE ENTIRE RISK OF ANY RELIANCE ON THESE MATERIALS. UNDER NO CIRCUMSTANCES WILL MARKET CLUE CORP. BE LIABLE IN ANY WAY FOR ANY MATERIALS, INCLUDING, BUT NOT LIMITED TO, FOR ANY ERRORS OR OMISSIONS IN ANY MATERIALS OR INFORMATION, OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY MATERIALS, INFORMATION OR RESULTS POSTED, EMAILED OR OTHERWISE RECEIVED OR TRANSMITTED VIA THE SITE.</Typography>
      <Typography variant="subtitle1" component="div" align='justify' paragraph={true}>NO RENDERING OF LEGAL ADVICE</Typography>
      <Typography variant="body2" align='justify' paragraph={true}>IN NO WAY DOES ANY DATA, INFORMATION, CONTENT OR MATERIAL PROVIDED THROUGH THE SITE (INCLUDING WITHOUT LIMITATION, ANY CONTRACTS, DISCLAIMERS AND MENUS) CONSTITUTE FINANCIAL ADVICE. MARKET CLUE CORP. IS NOT ENGAGED IN PROVIDING FINANCIAL CONSULTING SERVICES. ACCESS TO, TRANSMISSION OR RECEIPT OF OR RELIANCE UPON MATERIALS, DATA, CONTENT OR INFORMATION FROM THE SITE DOES NOT CREATE AND IS NOT INTENDED TO CREATE A FINANCIAL ADVISOR-CLIENT RELATIONSHIP BETWEEN YOU OR ANY OTHER PERSON AND MARKET CLUE CORP.. AS FINANCIAL ADVICE MUST BE TAILORED TO THE SPECIFIC CIRCUMSTANCES OF EACH INDIVIDUAL, NOTHING PROVIDED HEREIN SHOULD BE USED AS A SUBSTITUTE FOR THE ADVICE OF COMPETENT, LICENSED FINANCIAL COUNSEL.</Typography>
      <Typography variant="subtitle1" component="div" align='justify' paragraph={true}>LIMITATION OF LIABILITY</Typography>
      <Typography variant="body2" align='justify' paragraph={true}>UNDER NO CIRCUMSTANCES SHALL MARKET CLUE CORP., ITS AFFILIATES OR ITS SUBSIDIARIES, OR THE RESPECTIVE OFFICERS, DIRECTORS, AGENTS, EMPLOYEES, SPONSORS, AND OTHER PARTNERS OF EACH BE RESPONSIBLE OR LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY, PUNITIVE OR OTHER DAMAGES, WHETHER BASED IN CONTRACT, TORT OR OTHERWISE, ARISING OUT OF OR RELATING IN ANY WAY TO THE SITE, THE SERVICES AVAILABLE ON THE SITE, THE SOFTWARE AVAILABLE ON THE SITE, INFORMATION CONTAINED WITHIN THE SITE, OR RELIANCE ON ANY INFORMATION, MATERIALS OR RESULTS PROVIDED TO OR RECEIVED THROUGH THE SITE, EVEN IF MARKET CLUE CORP. HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. MARKET CLUE CORP.'S LIABILITY, IF ANY, RESULTING FROM YOUR USE OF THE SITE IS GOVERNED BY THE APPLICABLE AGREEMENT BETWEEN THE PARTY THAT AUTHORIZED YOU TO USE THE SITE AND MARKET CLUE CORP., IF ANY. ANY LIABILITY THAT MARKET CLUE CORP. MAY HAVE PURSUANT TO SUCH AGREEMENT WOULD BE TO THE PARTY THAT AUTHORIZED YOU TO USE THE SITE AND NOT YOU PERSONALLY. YOUR SOLE REMEDY FOR DISSATISFACTION WITH THE SITE AND/OR SITE-RELATED SERVICES, APPLICATIONS, MATERIALS OR INFORMATION IS TO STOP USING THE SITE AND/OR THOSE SERVICES, APPLICATIONS, MATERIALS OR INFORMATION.</Typography>
      <Typography variant="subtitle1" component="div" align='justify' paragraph={true}>INDEMNITY</Typography>
      <Typography variant="body2" align='justify' paragraph={true}>You agree to indemnify, defend and hold Market Clue Corp. and its subsidiaries, affiliates, and the respective officers, directors, agents, partners, sponsors, employees and independent contractors of each harmless from any claim or demand, including reasonable attorneys' fees, made by any third party due to or arising out of content, data, materials or information you submit, post to or transmit to or through the Site, your use of the Site or reliance upon any Materials provided through the Site, your connection to the Site, your violation of the Terms of Use, your use or disclosure of any content, data, materials or information received through the Site, your negligence, omissions or misconduct, your violation of any rights of another party, or your participation in or conducting of any transaction (or failure to conduct or complete a transaction) through the Site. This provision shall survive the expiration or termination of the Terms of Use and the applicable access agreement, lender agreement or other agreement between the party that authorized you to use the Site and Market Clue Corp., if any.</Typography>
      <Typography variant="body2" align='justify' paragraph={true}>You agree to protect and fully compensate Developer and Service Provider and their affiliates from any and all third party claims, liability, damages, expenses and costs (including, but not limited to, reasonable attorneys' fees) caused by or arising from your use of the service, your violation of these terms or your infringement, or infringement by any other user of your account, of any intellectual property or other right of anyone. In the event of a security breach of Developer’s systems, Developer will protect and fully compensate you for any direct costs relating to such security breach, subject to the limitations of Developer’s insurance.</Typography>
      <Typography variant="body2" align='justify' paragraph={true}>You agree that Service Provider is a third party beneficiary of the above provisions, with all rights to enforce such provisions as if Service Provider were a party to this agreement.</Typography>
      <Typography variant="subtitle1" component="div" align='justify' paragraph={true}>CONFIDENTIAL INFORMATION</Typography>
      <Typography variant="body2" align='justify' paragraph={true}>"Confidential Information" shall mean the nonpublic and/or proprietary information revealed by Market Clue Corp., including without limitation all forms and types of financial, business, scientific, technical, economic or engineering information of Market Clue Corp., and including without limitation, the applications provided through the Site. You agree to keep all Confidential Information acquired from Market Clue Corp., whether in connection with your use of the Site or otherwise, confidential. You shall not use the Confidential Information except as expressly provided herein, and you shall not disclose the Confidential Information without express written permission from Market Clue Corp.. All Confidential Information shall remain the property of Market Clue Corp. and shall not in any manner be deemed licensed or transferred to you. You shall return or destroy all Confidential Information on Market Clue Corp. request, upon the termination of the Terms of Use, upon termination of your rights to use the Site, or upon termination of the access agreement or lender agreement under which you are authorized to use the Site.</Typography>
      <Typography variant="body2" align='justify' paragraph={true}>You shall comply with all privacy and data protection laws, rules and regulations which are or which may in the future be applicable to your use of the Site or any applications, data or information provided on or through the Site. Without limiting the generality of the foregoing sentence, you agree that you will not use or disclose to any other party any nonpublic personal information which you receive in connection with the Terms of Use or through the Site, except as expressly permitted in the Terms of Use or by applicable law.</Typography>
      <Typography variant="body2" align='justify' paragraph={true}>You acknowledge and agree that: (a) irreparable injury will result to Market Clue Corp. in the event of a breach by you of this obligation of confidentiality, (b) Market Clue Corp.'s remedy at law for such breach is inadequate and (c) Market Clue Corp., in addition to any money damages for any such breach, shall be entitled to temporary and permanent injunctive relief without the necessity of proving damages, and that Market Clue Corp. shall not be required to post bond as a condition of such relief. This provision shall survive the expiration or termination of the Terms of Use and the applicable access agreement, lender agreement or other agreement between Market Clue Corp. and the party that authorized you to use the Site.</Typography>
      <Typography variant="subtitle1" component="div" align='justify' paragraph={true}>SECURITY</Typography>
      <Typography variant="body2" align='justify' paragraph={true}>USE OF THE SITE IS RESTRICTED TO MARKET CLUE CORP. AND ITS AUTHORIZED USERS. UNAUTHORIZED USE OF THE SITE INCLUDING, BUT NOT LIMITED TO, UNAUTHORIZED ENTRY INTO THE SITE, MISUSE OF PASSWORDS, OR MISUSE OF ANY INFORMATION WITHIN THE SITE IS STRICTLY PROHIBITED. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, YOU HEREBY ACKNOWLEDGE AND AGREE THAT IT IS A VIOLATION OF THE TERMS OF USE TO LOG ONTO THE SITE WITH A LOGIN ID AND PASSWORD THAT WAS NOT ASSIGNED TO YOU PERSONALLY. YOU AGREE TO KEEP YOUR LOGIN ID CONFIDENTIAL AND TO IMMEDIATELY NOTIFY MARKET CLUE CORP. IF YOU LEARN THAT THE SECURITY OF YOUR LOGIN ID AND/OR PASSWORD HAVE BEEN COMPROMISED AND/OR IF YOU SUSPECT THAT ANYONE OTHER THAN YOURSELF HAS ACCESSED THE SITE WITH YOUR LOGIN ID AND PASSWORD.</Typography>
      <Typography variant="body2" align='justify' paragraph={true}>You agree not to use any functions on the Site which you are not authorized to use.</Typography>
      <Typography variant="subtitle1" component="div" align='justify' paragraph={true}>MODIFICATION OR TERMINATION OF THE SITE</Typography>
      <Typography variant="body2" align='justify' paragraph={true}>Market Clue Corp. reserves the right to withdraw, suspend or discontinue at any time and from time to time the Terms of Use, any Materials available on the Site and any functionality or features in or on the Site, including the cessation of all activities associated with the Site, with or without notice. Market Clue Corp. reserves the right to change or modify the Terms of Use at any time. Any change or modification made by Market Clue Corp. will be effective immediately upon posting on the Site and your continued use of the Site means that you have agreed to accept any changes or modifications made by Market Clue Corp. Market Clue Corp. recommends that you revisit this web page from time to time. Not all Materials are available in all geographic areas. Market Clue Corp. may, from time to time, introduce new Materials to the Site or modify or delete existing Materials. By using new Materials, such as new services, you agree to be bound by the rules concerning these Materials.</Typography>
      <Typography variant="subtitle1" component="div" align='justify' paragraph={true}>GOVERNING LAW AND JURISDICTION</Typography>
      <Typography variant="body2" align='justify' paragraph={true}>The Terms of Use shall be governed by, construed and enforced in accordance with the laws of the State of Florida, as it is applied to agreements entered into and to be performed entirely within Nevada and without giving effect to any principles of conflicts of laws. You agree that any legal lawsuit or other action brought by Market Clue Corp., you or any third party to enforce this agreement, or in connection with any matters related to this Site, shall be subject only to the jurisdiction of the state or federal courts located in Miami-Dade County, Florida, and you hereby expressly waive any challenge to the jurisdiction or venue of such courts.</Typography>
    </>
  );
};

export default DialogFooterContentTerms;
